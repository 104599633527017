import { dummyData } from "../../../../../data/dummy-data"
import { userGroupsInputs } from "../../../../../data/user-search-inputs"
import UserSearchHandler from "../handle-user-search"

export function userGroups() {
  const step = document.querySelector(".system-settings-new-group-step__content")
  if (!step) return

  const editGroupUsersQty = document.querySelector(".js-system-settings-new-group-edit .user-groups-add-users")
  const newGroupUsersQty = document.querySelector(".js-system-settings-new-group .user-groups-add-users")

  init()

  window.addEventListener("resize", setEditUsersItemAbsoluteTop)

  // init
  function init() {
    setEditUsersItemAbsoluteTop()
    deleteUser()
    deleteAllUsers()

    userGroupsInputs.forEach((input) => {
      const { inputSelector, listPosition } = input

      new UserSearchHandler(inputSelector, listPosition, addUserToCompany)
    })

    displayUsersQty(editGroupUsersQty)
    displayUsersQty(newGroupUsersQty)
  }

  // update user's absolute element position
  function setEditUsersItemAbsoluteTop() {
    const editUsersCont = document.querySelectorAll(".user-groups-edit-users-block__list")

    if (!editUsersCont) return

    editUsersCont.forEach((cont) => {
      const editUsersItem = cont.querySelectorAll(".user-groups-edit-users-item__user-info")
      const countSelector = cont.parentNode.querySelector(".user-groups-edit-users-block__remove-all-btn span")

      countSelector.textContent = editUsersItem.length

      editUsersItem.forEach((item) => {
        const itemParent = item.parentNode
        const offsetTop = itemParent.offsetTop
        const parentHeight = itemParent.offsetHeight

        item.style.top = offsetTop + parentHeight + "px"
      })
    })
  }

  // ADD USER to company from the search list
  function addUserToCompany(user) {
    if (!user) return
    const form = user.closest(".js-system-settings-form")
    if (!form) return
    const usersList = form.querySelector(".user-groups-edit-users-block__list")

    const userData = getUserData(user)
    const userItem = createUserItem(userData)

    usersList.insertAdjacentHTML("beforeend", userItem)
    const usersListBlock = usersList.closest(".user-groups-edit-users-block")
    const removeAllBtn = usersListBlock.querySelector(".user-groups-edit-users-block__remove-all-btn")
    removeAllBtn.style.display = "block"

    notification(1, usersListBlock)
    displayUsersQty(usersListBlock)
    updateUserData(userData.email, user)
    deleteUser()
    setEditUsersItemAbsoluteTop()
  }

  function createUserItem(userData) {
    const { initials, name, rank, email, position, company } = userData

    const userItem = `<div class="user-groups-edit-users-item">
      <p class="user-groups-edit-users-item__name">
        ${name}
      </p>
      <button 
        class="user-groups-edit-users-item__remove-btn" 
        type="button">
        <svg class="icon icon-close-sidebar ">
          <use href="./icon/icons/icons.svg#close-sidebar"></use>
        </svg>
      </button>

      <div class="user-groups-edit-users-item__user-info">
        <div class="quick-invite-tabs-item quick-invite-tabs-item--alt">
          <div class="quick-invite-tabs-item-user">
            <div class="quick-invite-tabs-item-user__initials">
              ${initials}
            </div>
            <div>
              <div>
                <b>${name}</b>
                <span class="quick-invite-tabs-item-user__rank">${rank}</span>
              </div>

              <div class="quick-invite-tabs-item-user__email">${email}</div>
            </div>
          </div>

          <div class="quick-invite-tabs-item-company-info">
            <div>${position}</div>
            <div class="quick-invite-tabs-item-company-info__company">
              <img src="./svg/flags/us.svg" alt="img">
              ${company}
            </div>
          </div>
        </div>
      </div>
    </div>`

    return userItem
  }

  // UPDATE USER data after adding user to company
  function updateUserData(email, user) {
    const itemWrapper = user.closest(".user-groups-add-users__field")
    const searchInput = itemWrapper.querySelector(".system-settings-form__group-field__input")
    const resList = searchInput.nextElementSibling

    const index = dummyData.findIndex((item) => item.email === email)
    dummyData.splice(index, 1)

    resList.innerHTML = ""
    resList.style.display = "none"
    searchInput.value = ""
  }

  // DELETE USER on cross click
  function deleteUser() {
    const delButtons = document.querySelectorAll(".user-groups-edit-users-item__remove-btn")

    delButtons.forEach((button) => {
      button.removeEventListener("click", handleDeleteUser)
    })

    delButtons.forEach((button) => {
      button.addEventListener("click", handleDeleteUser)
    })

    function handleDeleteUser(event) {
      const button = event.currentTarget
      const btnItem = button.parentNode
      const itemsBlock = button.closest(".user-groups-edit-users-block")
      const countSelector = itemsBlock?.querySelector(".user-groups-edit-users-block__remove-all-btn > span")
      const removeAllBtn = itemsBlock?.querySelector(".user-groups-edit-users-block__remove-all-btn")

      const userData = getUserData(btnItem)
      dummyData.push(userData)

      btnItem.remove()
      setEditUsersItemAbsoluteTop()

      const itemsCount = itemsBlock?.querySelectorAll(".user-groups-edit-users-item").length

      if (itemsCount == 0) {
        removeAllBtn.style.display = "none"
      } else {
        if (countSelector) countSelector.textContent = itemsCount
      }

      notification(itemsCount, itemsBlock)
      displayUsersQty(itemsBlock)
    }
  }

  // DELETE ALL USERS from company
  function deleteAllUsers() {
    const buttons = document.querySelectorAll(".user-groups-edit-users-block__remove-all-btn")

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const itemsBlock = button.parentNode.parentNode
        const items = itemsBlock.querySelectorAll(".user-groups-edit-users-item")

        button.style.display = "none"

        items.forEach((item) => {
          item.remove()
        })

        notification(0, itemsBlock)
        displayUsersQty(itemsBlock)
        setEditUsersItemAbsoluteTop()
      })
    })
  }

  // get user data from HTML
  function getUserData(selector) {
    const userData = {
      id: dummyData.length + 1,
      initials: selector.querySelector(".quick-invite-tabs-item-user__initials").textContent.trim(),
      name: selector.querySelector("b").textContent,
      rank: selector.querySelector(".quick-invite-tabs-item-user__rank").textContent,
      email: selector.querySelector(".quick-invite-tabs-item-user__email").textContent.trim(),
      position: selector.querySelector(".quick-invite-tabs-item-company-info div").textContent,
      company: selector.querySelector(".quick-invite-tabs-item-company-info__company").textContent.trim(),
    }

    return userData
  }

  function displayUsersQty(block) {
    if (!block) return

    const form = block.closest(".js-system-settings-form")
    const users = form.querySelectorAll(".user-groups-edit-users .user-groups-edit-users-item")
    if (!users) return

    const qtySelectorNum = form.querySelectorAll(".system-settings-new-group-step__selection-qty span")[0]
    const qtySelectorText = form.querySelectorAll(".system-settings-new-group-step__selection-qty span")[1]

    qtySelectorNum.textContent = users.length

    if (users.length === 1) {
      qtySelectorText.textContent = "user"
    } else {
      qtySelectorText.textContent = "users"
    }
  }

  // show notification if users list is empty
  function notification(qty, selector) {
    if (qty == 0) {
      const notify = document.createElement("p")
      notify.classList.add("user-groups-edit-users-notify")
      notify.textContent = "No users added"
      selector.append(notify)
    } else {
      const notification = selector?.querySelector(".user-groups-edit-users-notify")

      if (notification) {
        notification.remove()
      }
    }
  }
}
