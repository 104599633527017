export class sidenavLinks {
  constructor() {
    /** @type { HTMLElement } */
    this.$sidenavLinks = document.querySelectorAll(".sidenav .js-sidenavnav-link")

    this.init()
  }

  init() {
    this.setActiveLink()
  }

  setActiveLink = () => {
    
    this.$sidenavLinks && this.$sidenavLinks.forEach((link) => {
      if(window.location.pathname.includes(link.pathname)){
        link.classList.add("active")
      }else if(window.location.pathname === '/' && link.pathname === '/index.html'){
        link.classList.add("active")
      }
      else if(window.location.pathname === '/b/travlio/' && link.pathname === '/b/travlio/index.html'){
        link.classList.add("active")
      }
    })
  }
}