import { slideDown, slideUp } from "../utils/slideIn"

// container: js-toggle-container
// trigger: js-toggle-trigger
// content: js-toggle-content

export class ContentToggler {
  constructor(container) {
    this.container = container
    this.trigger = this.container.querySelector(".js-toggle-trigger")
    this.content = this.container.querySelector(".js-toggle-content")
    this.animationDuration = 500
    this.isVisible = false

    this.init()
  }

  init = () => {
    this.trigger.addEventListener("click", () => this.toggleVisibility())
  }

  show() {
    this.isVisible = true
    this.trigger.classList.add("_active")
    this.content.classList.toggle("_visible")
    slideDown(this.content, this.animationDuration)
  }

  hide() {
    this.isVisible = false
    this.trigger.classList.remove("_active")
    this.content.classList.toggle("_visible")
    slideUp(this.content, this.animationDuration)
  }

  toggleVisibility() {
    this.disableButton()

    if (this.isVisible) {
      this.hide()
    } else {
      this.show()
    }
  }

  disableButton = () => {
    this.trigger.disabled = true
    setTimeout(() => {
      this.trigger.disabled = false
    }, this.animationDuration)
  }
}
