import {
  createDropdownData,
  createCheckboxData,
  createCardInputData,
  createDefaultCheckboxData,
} from "./_data-creators"

export function setInputData(input, context) {
  if (input.dataset.type === "card") {
    const { card_list, cardOutput } = context.formState[input.dataset.type]
    const outputInput = cardOutput.querySelector(".js-system-settings-input")
    const sanitizedValue = input.value.replace(/\D/g, "").slice(0, 16)

    const formattedValue = sanitizedValue.replace(/(\d{4})(?=\d)/g, "$1-")

    input.value = formattedValue
    const firstDigit = formattedValue.charAt(0)
    if (firstDigit === "4") {
      card_list.forEach((cardItem) =>
        cardItem.classList.contains("visa") ? cardItem.classList.add("active") : cardItem.classList.remove("active"),
      )
      outputInput.value = "visa"
    } else if (firstDigit === "5") {
      card_list.forEach((cardItem) =>
        cardItem.classList.contains("mastercard")
          ? cardItem.classList.add("active")
          : cardItem.classList.remove("active"),
      )
      outputInput.value = "mastercard"
    } else if (firstDigit === "3") {
      const secondDigit = formattedValue.charAt(1)
      if (secondDigit === "4" || secondDigit === "7") {
        card_list.forEach((cardItem) =>
          cardItem.classList.contains("american-express")
            ? cardItem.classList.add("active")
            : cardItem.classList.remove("active"),
        )
        outputInput.value = "american-express"
      } else {
        card_list.forEach((cardItem) =>
          cardItem.classList.contains("jcb") ? cardItem.classList.add("active") : cardItem.classList.remove("active"),
        )
        outputInput.value = "jcb"
      }
    } else {
      card_list.forEach((cardItem) => cardItem.classList.remove("active"))
      outputInput.value = ""
    }
  } else if (input.dataset.type === "date") {
    const sanitizedValue = input.value.replace(/\D/g, "").slice(0, 4)
    const formatedValue = sanitizedValue.replace(/^(\d{2})(\d{2})$/, function (match, month, year) {
      // Validate month and year
      if (parseInt(month, 10) > 12 || parseInt(year, 10) > 30) {
        return "" // Invalid date, return empty string
      } else {
        return month + " / " + year // Valid date, return formatted value
      }
    })
    input.value = formatedValue
    context.formState[input.name].value = input.value
  } else if (input.dataset.type === "secure_code") {
    const sanitizedValue = input.value.replace(/\D/g, "").slice(0, 3)
    input.value = sanitizedValue
    context.formState[input.name].value = input.value
  } else {
    context.formState[input.name].value = input.value
  }
}

export function setDropdownsData(context) {
  context.dropdowns.forEach((dropdown) => {
    // if(dropdown.dataset.noinclude) return
    const selectedItem = context.formState[dropdown.dataset.type]?.selectedItemNode
    context.formState[dropdown.dataset.type] = { ...createDropdownData(dropdown, selectedItem) }
  })
}

export function setCheckboxGroupData(checkboxGroup, context) {
  const { group } = checkboxGroup.dataset
  const checkboxes = checkboxGroup.querySelectorAll(".js-system-settings-form__group-checkbox")
  context.formState[group] = {}
  context.formState[group].type = "checkboxes"
  context.formState[group].rateInput = checkboxGroup.querySelector(".js-system-settings__limit-input")

  checkboxes.forEach((checkbox) => {
    const { name } = checkbox
    context.formState[group][name] = createCheckboxData(checkbox)
  })
}

export function setCardData(input, output, context) {
  const { type } = input.dataset
  context.formState[type] = { ...createCardInputData(input, output) }
}

export function setCheckboxesData(checkbox, context) {
  context.formState[checkbox.name] = { ...createDefaultCheckboxData(checkbox) }
}
