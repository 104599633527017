import { defaultTabsOptions } from "./tabs-options"

export class TabSelector {
  /**
   * @param { defaultTabsOptions } options
   */
  constructor(options) {
    this.options = { ...defaultTabsOptions, ...options }

    this.$container =
      this.options.container instanceof HTMLElement
        ? this.options.container
        : document.querySelector(this.options.container)

    if (!this.$container) {
      return console.warn("Can not initialize tabs with out container", this)
    }

    this.isLocked = false

    this.duration = this.options.duration / 2

    this.activeIndex = this.options.activeIndex

    this.triggers = this.$container.querySelectorAll(this.options.itemTriggerSelector)

    // this.items = this.$container.querySelectorAll(this.options.itemContentSelector)

    this.currentItemIndex = null
    this.currentTrigger = null
    this.currentItem = null

    this.#init()
  }

  #init() {
    this.setNewTabByIndex(this.activeIndex)

    this.triggers.forEach(($button, $index) => {
      console.log("Adding event", $button)
      $button.addEventListener("click", (e) => this.setNewTabByIndex($index, e))
    })
  }

  #changeTab(index) {
    const filterTo = this.triggers[index].textContent.trim();

    document.querySelectorAll('.trips__content .table-tr').forEach(($row) => {
      if ($row.querySelector('.table__label')) {
        if ($row.querySelector('.table__label').textContent.trim() != filterTo) {
          $row.style.display = 'none';
        } else {
          $row.style.display = '';
        }
      }
    })

    this.changeActiveButton()
  }
  setNewTabByIndex(index, e) {
    if (e) {
      e.preventDefault()
    }

    this.currentItemIndex = index

    this.#changeTab(this.currentItemIndex)

    return 0
  }

  changeActiveButton() {
    // const currentTabID = this.currentItem.id?.toLowerCase()

    this.triggers.forEach(($button, $index) => {
      // const tabTargetID = $button.dataset.tabId?.toLowerCase()

      if ($index == this.currentItemIndex) {
        this.currentTrigger = $button
        this.currentTrigger.classList.add(this.options.activeClass)
        this.moveIndicator(this.currentTrigger)
      } else {
        $button.classList.remove(this.options.activeClass)
      }
    })
  }

  moveIndicator(newTab) {
    const container = this.$container.querySelector(".tabs-triggers")
    const newTabWidth = newTab.offsetWidth / container.offsetWidth

    container.style.setProperty("--_left", newTab.offsetLeft + "px")
    container.style.setProperty("--_width", newTabWidth)
  }
}
