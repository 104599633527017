import { loaderModal } from "../loader-modal"

export const searchbarValidation = (searchbarFormConatiner) => {
  const searhbarForm = searchbarFormConatiner
  if (!searhbarForm) return

  const searchbarRequireFields = searhbarForm.querySelectorAll(".searchbar-input-field.required")

  const setValidStatus = (field) => {
    const fieldParent = field.closest(".searchbar-input-fieldparent")
    if (!fieldParent) return
    if (!field.value) {
      fieldParent.classList.add("invalid")
      const dropdownTravalers = fieldParent.parentNode.querySelector(".js-travalers-settings-dropdown")
      if (dropdownTravalers) dropdownTravalers.classList.add("invalid-search")
    } else {
      fieldParent.classList.remove("invalid")
    }
  }

  const removeInvalid = (field) => {
    const fieldParent = field.closest(".searchbar-input-fieldparent")
    if (!fieldParent) return
    fieldParent.classList.remove("invalid")
  }

  searchbarRequireFields &&
    searchbarRequireFields.forEach((requireField) => {
      requireField.oninput = () => removeInvalid(requireField)
    })

  searhbarForm.addEventListener("submit", (e) => {
    e.preventDefault()
    searchbarRequireFields &&
      searchbarRequireFields.forEach((requireField) => {
        setValidStatus(requireField)
      })

    let errorLength = searhbarForm.querySelectorAll(".searchbar-input-fieldparent.invalid").length
    if (errorLength) {
      // if not valid
      console.warn("Validation searchbar error!")
    } else {
      // if valid
      const searchbarEditPopup = searhbarForm.closest(".js-edit-search-popup.active")
      const header = document.querySelector(".header")
      if (searchbarEditPopup && searchbarEditPopup.hasAttribute("data-needlock"))
        searchbarEditPopup.classList.remove("active")
      if (header) header.classList.remove("upper-full")

      // loaderSearchModal.showLoader()
      // loaderSearchModal.init()
      let dataForm = new FormData(searhbarForm)
      console.log("dataForm", dataForm)
      let title = document.querySelector(".loader-search-modal .loader-modal-title")
      console.log("title", title)
      let destination = document.querySelector("#hotel_destination")
      title.innerHTML = destination.value
      const isAjaxForm = searhbarForm.dataset.isajaxform
      /*
      fetch(`${SITEURL}hotels/get-search-link`, {
        method: 'POST',
        body: dataForm
      })
        .then(function(response) {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then(function(data) {
          console.log(data)
          setTimeout(()=>{ // timeout here need to show modal (for regular use it`s not necessary)
            loaderSearchModal.hideLoader()
          },2000)
          window.location.href = data.url;

        })
      */
      if (typeof searchHotel === "function") {
        if (isAjaxForm) {
          searchHotel(1, dataForm)
        } else {
          searchHotel(1, dataForm, false)
        }        
      }

      // Set up location into Catalog Hotels Heading
      let hotels_catalog_heading_title = document.querySelector(".hotels-catalog-heading-content__right-title")
      hotels_catalog_heading_title.innerHTML = document.querySelector("#hotel_destination").value

      // Set another data to subheading
      let startdate = document.querySelector(".datepicker-input-startdate").value
      let enddate = document.querySelector(".datepicker-input-enddate").value

      let rooms = document.querySelector(".js-travalers__room__input").value
      let rooms_str = "Room"
      if (Number(rooms) > 1) rooms_str = "Rooms"

      let travelers = document.querySelector(".js-travalers-allcount__input").value
      let travelers_str = "Traveler"
      if (Number(rooms) > 1) {
        travelers_str = "Travelers"
      }

      let hotels_catalog_heading_subtitle = document.querySelector(".hotels-catalog-heading-content__right-subtitle")
      hotels_catalog_heading_subtitle.innerHTML =
        startdate + " - " + enddate + " | " + rooms + " " + rooms_str + " | " + travelers + " " + travelers_str

      // let boby = document.querySelector("body")
      // boby.classList.add("minimize_sidenav")

      /* setTimeout(()=>{ // timeout here need to show modal (for regular use it`s not necessary)
        loaderSearchModal.hideLoader()
      },5000) */
    }
  })
}
