import { scrollbarLock, scrollbarUnlock } from "../utils/scrollbar-locker"
export class loaderModal {
  constructor(loaderContainer) {
    this.loader = document.querySelector(loaderContainer)
    if(!this.loader) return

    this.closeLoaderModalBtns = this.loader.querySelectorAll(".js-loader-modal-close-btn")

    this.init()
  }

  showLoader = () => {
    this.loader.classList.add("_show")
    scrollbarLock()
  }

  hideLoader = () => {
    this.loader.classList.remove("_show")
    setTimeout(()=>{
      scrollbarUnlock()
    },250)
  }

  init() {
    this.closeLoaderModalBtns && this.closeLoaderModalBtns.forEach(closeLoaderBtn => {
      closeLoaderBtn.addEventListener("click", this.hideLoader)
    })
  }
}

export const loaderSearchModal = new loaderModal(".js-loader-search-modal")