export class AvailableRoomToggle {
  constructor(roomContainer) {
    this.roomsContainer = roomContainer
    if(!this.roomsContainer) return
    
    this.toggler = this.roomsContainer.querySelector(".available-rooms-item-show-btn")
    
    if(!this.toggler && !this.hiddenRooms) return

    this.isOpen = false

    this.init()
  }

  toggleRoom(rooms) {
    if(this.isOpen){
      this.isOpen = false
      rooms.forEach((room, index) => {
        if(index > 0) room.classList.remove("visible-room")
      })
      this.toggler.classList.remove("active")
      const roomCount = this.toggler.querySelector(".room")
      const roomPrice = this.toggler.querySelector(".price") 
      if(roomCount) roomCount.innerHTML = this.toggler.getAttribute("data-more-text-count")
      if(roomPrice) roomPrice.innerHTML = this.toggler.getAttribute("data-more-text-price")
    }else{
      this.isOpen = true
      rooms.forEach((room, index) => {
        if(index > 0) room.classList.add("visible-room")
      })
      this.toggler.classList.add("active")
    }
  }

  init() {
    this.toggler.addEventListener("click", () => {
      const rooms = this.roomsContainer.querySelectorAll(".available-rooms-item-hidesection")
      this.toggleRoom(rooms)
    })
  }
}