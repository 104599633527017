const notificationsDropdownFunction = () => {
  let notificationsMenu = document.querySelector(".header-user-panel__user-menu-wrapper .notifications-menu")
  if(notificationsMenu) {
    // clear all event
    let notificationsBtns = notificationsMenu.querySelector(".js-notifications-menu__clear-all-trigger")
    let optionsToRemove = notificationsMenu.querySelectorAll(".notifications-menu__row__item")
    let notificationsEmpty = notificationsMenu.querySelector(".notifications-menu__row__empty")
    let notificationsFooter = notificationsMenu.querySelector(".notifications-menu__footer")
    if(notificationsBtns){
      notificationsBtns.onclick = () => {
        optionsToRemove.forEach(option => {
          option.remove()
        })
        notificationsEmpty.classList.add('visible')
        notificationsFooter.remove()
        notificationsBtns.setAttribute("disabled",'');
        buttonRead.setAttribute("disabled", '');
      }
    }
    // clear all event end

    // clear one
    let notificationsOptions = notificationsMenu.querySelectorAll(".notifications-menu__row__item")
    if(notificationsOptions) {
      notificationsOptions.forEach(searchOption => {
        searchOption.onclick = function (e) {
          if(e.target.closest(".notifications-menu__row__item__remove")){
            e.preventDefault()
            setTimeout(() => searchOption.remove(),200)
          }
        }
      })
    }
    // clear one end

    // Mark all read

    let buttonRead = notificationsMenu.querySelector(".js-notifications-menu__read-all-trigger")
    if(buttonRead) {
      buttonRead.onclick = () => {
        optionsToRemove.forEach(option => {
          option.classList.remove('new')
        })
      }
    }
  }
}
export {
  notificationsDropdownFunction
}