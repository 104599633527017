//import { dummyData } from "../../../../data/dummy-data"

export class UserSearchHandler {
  constructor(inputSelector, resultsPosition, onSearchItemClick, context) {
    this.context = context
    this.searchInput = document.querySelector(inputSelector)
    this.resultsPosition = resultsPosition
    this.onSearchItemClick = onSearchItemClick
    this.searchResults = this.createSearchResults()

    if (!this.searchInput) return

    this.init()
  }

  init() {
    this.searchInput.addEventListener("input", this.handleSearchQuery.bind(this))
    document.addEventListener("click", this.handleClickOutside.bind(this))
  }

  handleSearchQuery() {
    const searchValue = this.searchInput.value.trim()
    this.searchInput.nextElementSibling.style.display = "block"

    if (this.context) {
      const isInputEmpty = searchValue === ""
      let isAllFieldsFilled = true

      this.context.form.classList.toggle("_disabled", isInputEmpty)

      this.context.requiredInputs.forEach((input) => {
        if (input.value.trim() === "") {
          isAllFieldsFilled = false
          return
        }
      })

      this.context.submitButton.disabled = isInputEmpty || !isAllFieldsFilled
    }

    if (searchValue !== "") {
      fetch(`${SITEURL}account/get-user-list`)
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        // Loop through each key-value pair in the response
        console.log(data);
        var users = data.data.users;   
        
        const filteredData = this.filterData(searchValue, users)
        this.renderResults(filteredData)
      })
      .catch(error => console.error('Error:', error));

      
    } else {
      this.clearSearchResults()
    }
  }

  filterData(searchQuery, users) {
    const searchTerm = searchQuery.toLowerCase()
    const filteredResults = users.filter((item) => {
      const { first_name, last_name, email, department, group_name, country, office_title } = item
      const lowerCaseName = first_name.toLowerCase()
      const lowerCaseNameLast = last_name.toLowerCase()
      const lowerCaseEmail = email.toLowerCase()
      const lowerCaseRank = department?.toLowerCase() ?? '';

      return (
        lowerCaseName.includes(searchTerm) || lowerCaseNameLast.includes(searchTerm)  || lowerCaseEmail.includes(searchTerm) || lowerCaseRank.includes(searchTerm)
      )
    })

    return filteredResults
  }

  renderResults(results) {
    this.searchResults.innerHTML = ""

    results.forEach((result) => {
      const userItem = this.createUserItem(result)

      this.searchResults.appendChild(userItem)

      if (this.onSearchItemClick) {
        userItem.addEventListener("click", () => {
          this.onSearchItemClick(userItem)
          this.clearSearchResults()
        })
      }
    })
  }

  createSearchResults() {
    const searchInputContainer = this.searchInput?.parentNode

    if (!searchInputContainer) return

    const searchResultsEl = document.createElement("ul")
    searchResultsEl.classList.add("system-settings-users-search-results", this.resultsPosition)
    searchInputContainer.appendChild(searchResultsEl)

    return searchResultsEl
  }

  createUserItem(user) {
    const userItem = document.createElement("li")
    userItem.classList.add("user-groups-add-users-list__item")
    userItem.setAttribute("data-value", user.first_name + ' ' + user.last_name)
    userItem.setAttribute("data-id", user.id)

    const userContent = `<div class="quick-invite-tabs-item-user" data-id="${user.id}">
        <div class="quick-invite-tabs-item-user__initials">
          <img class="user-avatar" src="${SITEURL}user/profile-avatar/${user.id}?width=30&height=30">
        </div>
        <div>
          <div>
            <b>${user.first_name} ${user.last_name}</b>
            <span class="quick-invite-tabs-item-user__rank">${user.group_name}</span>
          </div>

          <div class="quick-invite-tabs-item-user__email">${user.email}</div>
        </div>
      </div>

      <div class="quick-invite-tabs-item-company-info">
        <div class="quick-invite-tabs-item-user__position">${user.department ?? ''}</div>
        <div class="quick-invite-tabs-item-company-info__company">
          <img src="/assets/thames/default/svg/flags/us.svg" alt="img">
          ${user.office_title ?? ''}
        </div>
      </div>`

    userItem.innerHTML = userContent

    return userItem
  }

  clearSearchResults() {
    this.searchResults.innerHTML = ""
    this.searchResults.style.display = "none"
  }

  handleClickOutside(event) {
    if (!this.searchInput.contains(event.target)) {
      this.searchInput.nextElementSibling.style.display = "none"
    } else {
      this.searchInput.nextElementSibling.style.display = "block"
    }
  }
}

export default UserSearchHandler
