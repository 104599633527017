import { setAbsoluteElementTop } from "../../utils/helpers"

export class CheckoutRoomDetails {
  constructor(roomDetails) {
    this.roomDetails = roomDetails ? roomDetails : document.querySelector(".details-block")
    if (!this.roomDetails) return
    this.guestsWrap = this.roomDetails.querySelectorAll(".checkout-room-guests__wrap")
    this.roomGuestsBoxClass = ".checkout-room-guests__box"
    this.roomGuestPopupClass = ".system-settings-user-info"

    this.init()
  }

  init = () => {
    this.handleGuestsQuantity()
    setTimeout(() => {
      setAbsoluteElementTop(this.roomGuestsBoxClass, this.roomGuestPopupClass)
    }, 200)
  }

  handleGuestsQuantity = () => {
    this.guestsWrap.forEach((container) => {
      this.guestsLength = container.querySelectorAll(".checkout-room-guests__box-item").length
      this.quantutyEl = container.querySelector(".checkout-room-guests__heading-amount--current")

      this.quantutyEl.innerHTML = this.guestsLength
    })
  }
}
