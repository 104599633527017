export class CheckoutHeaderFilters {
  constructor(wrap) {
    this.checkboxes = wrap.querySelectorAll('.js-checkout-filter-checkbox')
    this.dropdowns = [...wrap.querySelectorAll('.js-checkout-filter-dropdown')].map(this.formDropdownData)
    this.#init()
  }

  formDropdownData = (wrap) => {
    return {
      wrapper: wrap,
      trigger: wrap.querySelector('.js-checkout-filter-dropdown-trigger'),
      valueContainer: wrap.querySelector('.js-checkout-filter-dropdown-trigger-value'),
      input: wrap.querySelector('.js-checkout-filter-dropdown-input'),
      list: wrap.querySelector('.js-checkout-filter-dropdown-list')
    }
  }

  handleListClick = (event, trigger, valueContainer, input) => {
    const { value, text } = event.target.dataset
    trigger.classList.remove('active')
    valueContainer.innerText = text
    input.value = value
    event.currentTarget.classList.remove('active')
    event.currentTarget.querySelectorAll('li').forEach(item => item.classList.remove('selected'))
    event.target.classList.add('selected')
    this.callbackOptionHandle(value)
    if(event.target.hasAttribute("data-trip-id")){
      this.changeTripID(event.target.getAttribute("data-trip-id"))
    }
  }

  callbackOptionHandle = (value) => {
    console.log("callback value:", value)
  }

  changeTripID = (itemID) => {
    if(!itemID) return
    const tripIDElement = document.querySelector(".checkout .checkout-header__trip-id .value") 
    if(tripIDElement) tripIDElement.innerHTML = itemID
  }

  handleTriggerClick = (event, list) => {
    event.target.classList.toggle('active')
    list.classList.toggle('active')
  }

  initDropdown = ({ trigger, valueContainer, input, list }) => {
    trigger.addEventListener('click', (event) => this.handleTriggerClick(event, list))
    list.addEventListener('click', (event) => this.handleListClick(event, trigger, valueContainer, input))
    window.addEventListener('click', (event) => {
      const { target } = event
      if (!target.closest('.js-checkout-filter-dropdown')) {
        list.classList.remove('active')
        trigger.classList.remove('active')
      }
    })
  }

  initCheckbox = (checkbox) => {
    checkbox.addEventListener('input', (event) => {
      this.checkboxes.forEach(checkbox => checkbox.checked = false)
      event.target.checked = true
      const isAddToExistingTrip = event.target.dataset.type === 'checkout_add_to_existing_trip'
      this.dropdowns.forEach(dropdown => {
        dropdown.wrapper.classList[isAddToExistingTrip ? 'add' : 'remove']('active')
      }
      )
    })
  }

  #init() {
    this.dropdowns && this.dropdowns.length && this.dropdowns.forEach(this.initDropdown)
    this.checkboxes && this.checkboxes.length && this.checkboxes.forEach(this.initCheckbox)
  }
}