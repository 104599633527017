import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";

export const gallerySlider = () => {
  // Methods
  const sliderCountHandle = (instance) => {
    const counter = document.querySelector('.modal-hotel-gallery .slider-container-large .slider-container-large__counter');

    if (counter) {
      counter.innerHTML = `${instance.activeIndex + 1}/${instance.slides.length}`;
    }
  };

  const thumbsActiveSlide = (instance) => {
    thumbsSlider.slideTo(instance.activeIndex);

    thumbsSlider.slides.forEach((slide, index) => {
      if (index !== instance.activeIndex) {
        slide.classList.remove('border');
      }
      if (index === instance.activeIndex) {
        slide.classList.add('border');
      }
    });
  };

  // Init
  const thumbsSlider = new Swiper('.modal-hotel-gallery .slider-container-thumbs', {
    spaceBetween: 20,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    modules: [Thumbs],
  });

  const largeSlider = new Swiper('.modal-hotel-gallery .slider-container-large', {
    spaceBetween: 20,
    modules: [Navigation, Thumbs],
    navigation: {
      prevEl: '.modal-hotel-gallery .slider-container-large .slider-container-large__btn.prev',
      nextEl: '.modal-hotel-gallery .slider-container-large .slider-container-large__btn.next'
    },
    thumbs: {
      swiper: thumbsSlider
    },
    on: {
      init: (instance) => {
        // console.log(instance);
        sliderCountHandle(instance);
        thumbsActiveSlide(instance);
      },
      slideChange: (instance) => {
        sliderCountHandle(instance);
        thumbsActiveSlide(instance);
      }
    }
  });
};