import { Toast } from "../../../components/toast"

export class ActionsDropdown {
  constructor() {
    this.container = document.querySelector(".user-management__container")
    if (!this.container) return
    this.modal = document.querySelector(".modal-transfer-users")
    this.table = this.container.querySelector(".table__wrapper")
    this.selectAllCheckbox = this.table.querySelector(".js-select-all-checkbox")
    this.actionsDropdownBtn = this.container.querySelector(".user-management-select--actions button")
    this.actionButtons = document.querySelectorAll(".js-action-button")
    this.modalBackdrop = this.modal.previousElementSibling
    this.dropdown = this.modal.querySelectorAll(".js-select")
    this.firstDropdownInput = this.modal.querySelectorAll(".js-select-input")[0]
    this.disabledDropdownBtn = this.modal.querySelector(".modal-transfer-users__select--alt button")
    this.disabledDropdownInput = this.modal.querySelector(".modal-transfer-users__select--alt .js-select-input")
    this.submitBtn = this.modal.querySelector(".modal-transfer-users__confirm-btn")
    this.closeTriggers = this.modal.querySelectorAll(".js-modal-close-trigger")

    this.init()
  }

  init = () => {
    const observerConfig = { attributes: true }

    const inputMutationObserver = (inputElement, targetBtn) => {
      return new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "value") {
            targetBtn.disabled = !inputElement.value.trim()
          }
        })
      })
    }

    const firstDropdownInputObserver = inputMutationObserver(this.firstDropdownInput, this.disabledDropdownBtn)
    const secondDropdownInputObserver = inputMutationObserver(this.disabledDropdownInput, this.submitBtn)

    firstDropdownInputObserver.observe(this.firstDropdownInput, observerConfig)
    secondDropdownInputObserver.observe(this.disabledDropdownInput, observerConfig)

    this.modalBackdrop.addEventListener("click", () =>
      this.setDropdownsToDefault(firstDropdownInputObserver, secondDropdownInputObserver, observerConfig),
    )
    this.closeTriggers.forEach((trigger) => {
      trigger.addEventListener("click", () =>
        this.setDropdownsToDefault(firstDropdownInputObserver, secondDropdownInputObserver, observerConfig),
      )
    })

    this.handleActionsDisabled()
    this.handleActionsClick()
  }

  setDropdownsToDefault = (firstObserver, secondObserver, observerConfig) => {
    this.dropdown.forEach((dropdown) => {
      const input = dropdown.querySelector(".js-select-input")
      const trigger = dropdown.querySelector(".js-select-trigger")
      const triggerValue = trigger.querySelector(".js-select-trigger-value")
      const selectedOption = dropdown.querySelector("._selected")

      input.value = ""
      triggerValue.innerHTML = trigger.getAttribute("aria-label")
      dropdown.classList.remove("_chosen")
      selectedOption?.classList.remove("_selected")
    })

    this.disabledDropdownBtn.disabled = true
    this.submitBtn.disabled = true

    firstObserver.observe(this.firstDropdownInput, observerConfig)
    secondObserver.observe(this.disabledDropdownInput, observerConfig)
  }

  handleActionsDisabled = () => {
    const checkboxes = document.querySelectorAll(".js-checkbox")

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        const checkedCheckboxes = document.querySelectorAll(".js-select-all-item:checked")

        if (checkedCheckboxes.length > 0) {
          this.actionsDropdownBtn.disabled = false
        } else {
          this.actionsDropdownBtn.disabled = true
        }
      })
    })
  }

  handleActionsClick = () => {
    const showToast = (button) => {
      new Toast({
        position: ".header__content",
        text: button.getAttribute("data-success-message"),
        type: "success",
      })
    }

    this.actionButtons.forEach((button) => {
      const actionType = button.dataset?.actionType

      button.addEventListener("click", () => {
        const selectedCheckboxes = document.querySelectorAll(".js-select-all-item:checked")

        let ids = [];
              selectedCheckboxes.forEach((checkbox) => {
                  const parentItem = checkbox.closest(".table-tr");
                  if (parentItem) {
                      ids.push(parentItem.getAttribute('data-id'));
                  }
              });
        let toastMessage = '';

        let formData = new FormData();
        formData.append('ids', JSON.stringify(ids));

        const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
        const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
        const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');


        switch (actionType) {
          case "deactivate":
            
            toastMessage = `Deactivated ${selectedCheckboxes.length} ${selectedCheckboxes.length > 1 ? "users" : "user"
              }!`
            button.setAttribute("data-success-message", toastMessage)

            console.log(ids);

            fetch(`${SITEURL}user-management/deactivate-users`, {
              method: "POST",
              headers: {
                'X-CSRF-TOKEN': csrfTokenValue
              },
              body: formData
            })
              .then(response => response.json()) // Parse the JSON response
              .then(data => {
                console.log(data);

              })
              .catch(error => console.error('Error:', error));

            break
          case "reactivate":
            toastMessage = `Reactivated ${selectedCheckboxes.length} ${
              selectedCheckboxes.length > 1 ? "users" : "user"
            }!`
            button.setAttribute("data-success-message", toastMessage)
            
            fetch(`${SITEURL}user-management/reactivate-users`, {
              method: "POST",
              headers: {
                'X-CSRF-TOKEN': csrfTokenValue
              },
              body: formData
            })
              .then(response => response.json()) // Parse the JSON response
              .then(data => {
                console.log(data);

              })
              .catch(error => console.error('Error:', error));

            //console.log(ids);
            break
          case "delete":
              toastMessage = `Deleted ${selectedCheckboxes.length} ${
                selectedCheckboxes.length > 1 ? "users" : "user"
              }!`
              button.setAttribute("data-success-message", toastMessage)

              //console.log(ids);

              fetch(`${SITEURL}user-management/delete-users`, {
                method: "POST",
                headers: {
                  'X-CSRF-TOKEN': csrfTokenValue
                },
                body: formData
              })
                .then(response => response.json()) // Parse the JSON response
                .then(data => {
                  console.log(data);
                })
                .catch(error => console.error('Error:', error));

              selectedCheckboxes.forEach((checkbox) => {
                const parentItem = checkbox.closest(".table-tr")
                parentItem.remove()
              })
              this.selectAllCheckbox.checked = false

              const remainingItems = document.querySelectorAll(".js-select-all-item")
              if (remainingItems.length === 0) {
                this.actionsDropdownBtn.disabled = true
                this.table.classList.add("_hidden")
              }
            break
          case "transfer":
            button.addEventListener("click", () => setTimeout(() => showToast(button), 300))
            break
          default:
            break
        }

          button.setAttribute("data-success-message", toastMessage)
          setTimeout(() => showToast(button), 300)

      })


    })
  }
}
