export function initFormState(input, context) {
  // if (input.dataset.type === "date") {
  // }
  context.formState[input.name] = {
    value: input.value,
    default_email: input.dataset.email || "",
    type: input.dataset.type || input.type,
    input,
    parent: input.closest(".system-settings-form__group-field"),
    validation: Boolean(input.dataset.validation) || null,
  }

  if(input.dataset.type === 'password' || input.dataset.type === 'confirm_password') {
    context.formState[input.name].togglePasswordBtn = input.nextElementSibling
  }
}

export function formPhoneInputData(input) {
  const parent = input.closest(".tel-wrap")
  const codeValue = parent.querySelector(".dial-code").innerText
  const countryValue = parent.querySelector(".tel-input-dropdown__list-item.active")
  return {
    parent: input.closest(".system-settings-form__group-field"),
    input,
    full_value: codeValue ? codeValue + input.value : input.value,
    value: input.value,
    codeValue,
    countryValue: countryValue && countryValue.hasAttribute("data-country_code") ? countryValue.getAttribute("data-country_code") : '',
    codeContainer: parent.querySelector(".dial-code"),
    type: input.type,
    validation: Boolean(input.dataset.validation) || null,
    flagContainer: parent.querySelector(".flag-container"),
    initialIconSrc: "./svg/phone.svg",
    codeSection: parent.querySelector(".code-section"),
  }
}

export function createDropdownData(dropdownWrap, selectedItemNode = null) {
  let initialValue
  // const dropdownValueContainer = dropdownWrap.querySelector(".js-system-settings__dropdown-value")
  const dropdownValueContainer = dropdownWrap.querySelector(".js-system-settings-dropdown-input")

  // initialValue = dropdownValueContainer.innerText
  initialValue = dropdownValueContainer.value

  const innerValue = initialValue === "Select" ? "" : initialValue
  return {
    trigger: dropdownWrap.querySelector(".js-system-settings__dropdown-trigger"),
    list: dropdownWrap.querySelector(".js-system-settings__dropdown-list"),
    valueContainer: dropdownWrap.querySelector(".js-system-settings__dropdown-value"),
    input: dropdownWrap.querySelector(".js-system-settings-dropdown-input"),
    input_dropdown: dropdownWrap.querySelector(".js-system-settings__input-dropdown"),
    dropdownWrap,
    selectedItemNode: selectedItemNode,
    value: innerValue,
    validation: Boolean(dropdownWrap.dataset.validation) || false,
    type: "dropdown",
    parent: dropdownWrap.closest(".system-settings-form__group-field"),
    searchInput: dropdownWrap.querySelector(".js-system-settings__search-input"),
    items: dropdownWrap.querySelectorAll(".js-system-settings__dropdown-list-item"),
  }
}

export function createCheckboxData(checkbox) {
  return {
    checkbox,
    value: checkbox.checked,
    type: "checkbox",
  }
}

export function createCardInputData(cardInput, cardOutput) {
  return {
    cardInput,
    cardOutput,
    card_list: cardOutput.querySelectorAll(".card-container"),
  }
}

export function createDefaultCheckboxData(checkbox) {
  return {
    checkbox,
    value: checkbox.checked,
    type: "checkbox",
    validation: Boolean(checkbox.dataset.validation) || false,
    parent: checkbox.nextElementSibling,
  }
}
