import { Toast } from "../../components/toast"

export class OptionsItemHandler {
  constructor(container) {
    this.container = container
    this.favoriteButtons = container.querySelectorAll(".js-favorite-btn")

    this.init()
  }

  init = () => {
    this.toggleFavorites()
  }

  toggleFavorites = () => {
    const toastAddConfig = {
      position: ".trips-sidebar__content",
      text: "Property removed from favourites",
      type: "success",
      icon: "favorites-remove",
    }
    const toastRemoveConfig = {
      position: ".trips-sidebar__content",
      text: "Property added to favourites",
      type: "success",
      icon: "favorites-add",
    }

    this.favoriteButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const isFavorite = this.container.classList.contains("_favorite")

        if (isFavorite) {
          this.container.classList.remove("_favorite")
          new Toast(toastAddConfig)
        } else {
          this.container.classList.add("_favorite")
          new Toast(toastRemoveConfig)
        }
      })
    })
  }
}
