
export class StepperSidebar {
  constructor() {
    const stepperItem = document.querySelectorAll(".auth-page__sidebar-stepper-itembox .auth-page__sidebar-stepper-item");
    document.querySelector(".form-field__input").onclick = function() {activeStep()};

    function activeStep() {
      stepperItem.forEach((item) => {
        if (item.classList.contains('step')) {
          item.classList.add("active-step");
        }
        console.log(stepperItem);
      })
    }
  }
}
