import { updateFormState, updateValidationState } from "./_state-actions"
import { setCheckboxGroupData } from "./_setters"
import { redirectBySubmit } from "./_redirect"
import { checkViewportAndScroll, checkFirstErrorToScroll, setErrors, isFormValid } from "./_validation-actions"
import { IntegrationForms } from "./../../integration-forms"
import { Modal } from "./../../../../components/modals/modal"
import { defaultModalOptions } from "./../../../../components/modals/modal.default"

export const handleSubmit = (event, context) => {
  event.preventDefault()
  updateFormState(context)
  updateValidationState(context)
  isFormValid(context)
  // console.log(context);
  if (context.isValid) {
    context.checkboxesGropus &&
      context.checkboxesGropus.length &&
      context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))

    // fetch('localhost...', {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({ ...context.formState })
    // })
    // console.log("valid", context.formState)
    const dataForServer = {
      ...Object.keys(context.formState).reduce((obj, key) => {
        if (context.formState[key].type === "checkboxes") {
          console.log(key)
          context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))
          const checkKeys = Object.keys(context.formState[key])

          const checkboxesData = checkKeys.reduce((obj, checkKey) => {
            if (context.formState[key][checkKey].checkbox) {
              return { ...obj, [checkKey]: context.formState[key][checkKey].value }
            } else {
              return obj
            }
          }, {})
          console.log("checkboxesData", checkboxesData)
          return { ...obj, ...checkboxesData }
        } else {
          const updatedObj = {
            ...obj,
            [key]: context.formState[key].type === "tel"
              ? context.formState[key].full_value
              : context.formState[key].type === 'select_user'
                ? context.formState[key].input.dataset.id
                : context.formState[key]?.default_email?.length
                  ? context.formState[key].value +'@'+ context.formState[key].default_email
                  : context.formState[key].value,
          };
          
          if (context.formState[key].type === "tel") {
            // only if body not empty
            if(context.formState[key].value){
              updatedObj[key + "_tel_code"] = context.formState[key].codeValue;
              updatedObj[key + "_tel_body"] = context.formState[key].value;
              updatedObj[key + "_tel_country"] = context.formState[key].countryValue;
            } else {
              updatedObj[key + "_tel_code"] = '';
              updatedObj[key + "_tel_body"] = '';
              updatedObj[key + "_tel_country"] = '';
            }
          }
          
          return updatedObj;
        }
      }, {}),
    }

    console.log(context.formState)
    console.log("dataForServer", dataForServer)
    console.log("sumbited")

    const formData = new FormData();
    /*Object.keys(context.formState).forEach(key => {
      const value = context.formState[key].type === 'tel' ? context.formState[key].full_value : context.formState[key].value;
      formData.append(key, value);
    });*/

    // Iterate through all keys and values in dataForServer and add them to formData
    Object.entries(dataForServer).forEach(([key, value]) => {
      if (value instanceof Array) {
        // If the value is an array, add each element of the array under the same key name
        value.forEach(item => formData.append(key + '[]', item));
      } else if (typeof value === 'object' && value !== null) {
        // If the value is an object (not an array and not null), convert the object to JSON or handle it differently
        formData.append(key, JSON.stringify(value));
      } else {
        // For simple values, just add them to formData
        formData.append(key, value);
      }
    });

    // Find all hidden fields in the form
    const hiddenInputs = context.form.querySelectorAll('input[type="hidden"]');

    // Add each hidden field to formData
    hiddenInputs.forEach(input => {
      formData.append(input.name, input.value);
    });

    console.log('formData', formData)


    const postUrl = context.form.getAttribute("data-url"); // Get the url
    const dataFor = context.form.getAttribute("data-for"); // Get the url
    
    fetch(postUrl, {
      method: "POST",
      headers: {
        'X-CSRF-TOKEN': context.csrf_token
      },
      body: formData
    })
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        // Loop through each key-value pair in the response
        console.log(data);

        if (data.success) {
          //redirectBySubmit(context)
          console.log('dataFor', dataFor);
          const _IntegrationForms  = new IntegrationForms(); 

          if(dataFor=="company-save"){
            const company = data.company;
            if(company.complete_status){
              console.log('complete_status update', company.complete_status);
              const company_line = document.querySelector(`#system-settings-company-setup-step-item-${company.id}`);
              company_line.classList.add('_completed');

              const offices_setup_btn = document.querySelector(`#system-settings-offices-setup-btn`);
              //console.log('offices_setup_btn', offices_setup_btn);
              offices_setup_btn.classList.remove('disable-step');
            }
          }

          if(dataFor=="office-save"){
            let office = data.office;

            if(data.new){
              console.log('new office added');
             
              let offices_container = document.querySelector(`.system-settings-offices-setup-step__content .system-settings-list`); 
              offices_container.insertAdjacentHTML('beforeend', _IntegrationForms.createOfficeItem(office, SITEURL));
              if(office.is_default==1){
                let bnt_container =  document.querySelector('#system-settings-btn-quick-invite');
                // console.log('bnt_container', bnt_container);
                bnt_container.setAttribute("data-id", office.id);

                const payment_setup_btn = document.querySelector(`#system-settings-payment-methods-btn`);
                payment_setup_btn.classList.remove('disable-step');

                const empty_list = document.querySelector(`#offices-notification-settings`);
                if(empty_list){
                  empty_list.remove();  
                }            
              }
              
            }
            else {
              let office_row = document.querySelector(`#syslem-offices-list-id_${office.id}`); 
              office_row.querySelector('.js-system-settings-item-title').innerHTML = office.office_title;
            }

          }

          if(dataFor=="department-save"){
            let department = data.department;
            
            let departments_list = document.querySelector(`#quick_invite_users_departments_list`);
            
              // Створення нового елементу li
              let listItem = document.createElement('li');
              listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item department-list-id_'+department.id;
              listItem.setAttribute('data-value', department.name);
              listItem.setAttribute('data-id', department.id);
            
              // Додавання вмісту до li
              listItem.innerHTML = `
                            <span class="input-dropdown__list-item__value">
                              ${department.name}
                            </span>
                            <span class="input-dropdown__list-item__value js-list-item__value-text">
                            ${department.description}
                            </span>
              `;
            
              // Додавання створеного елементу до списку
              departments_list.appendChild(listItem);

              // Clear form
              const form = document.querySelector('#new-department-form');
              // Знаходження всіх текстових input полів у формі, крім прихованих
              const inputs = form.querySelectorAll('input[type="text"]');
              // Очищення кожного поля
              inputs.forEach(input => {
                input.value = '';
              });

              // add to list
              let edit_departments_list = document.querySelector(`#edit-department-list`);
                
              let editListItem = document.createElement('div');
              editListItem.className = 'system-settings-department-list-item js-system-settings-filter-item new department-list-id_'+department.id;
              editListItem.innerHTML = _IntegrationForms.createDepertamentItem(department, true);
              //edit_departments_list.appendChild(editListItem);
              // Додавання елементу в початок списку
              if (edit_departments_list.firstChild) {
                edit_departments_list.insertBefore(editListItem, edit_departments_list.firstChild);
              } else {
                // Якщо список порожній, просто додаємо елемент
                edit_departments_list.appendChild(editListItem);
              }
                

              let department_users_total = document.querySelector(`#edit-department-users-total`);
              if (department_users_total) {
                  let currentTotal = parseInt(department_users_total.textContent, 10);
                  let newTotal = currentTotal + 1;

                  department_users_total.textContent = newTotal;
              }
              new Modal({ ...defaultModalOptions, container: "#modal-delete-department" })
          }

          

          if(dataFor=="policy-save"){
            // Save Policy and load approval rules
            let policy = data.policy;
            const approval_form = document.querySelector('#system-settings-approval-conditions');
           
            _IntegrationForms.clearUsersInputs(['#primary_approver_user_id', '#secondary_approver_user_id']);
            //console.log(policy);
            // Перевірка і видалення елемента, якщо його значення "0"
            if (policy.secondary_approver_user_id === "0") {
              delete policy.secondary_approver_user_id;
            }
            _IntegrationForms.insertInputData(policy, 'system-settings-approval-rules')

            if(data.new==true){
              approval_form.setAttribute('data-toast', policy.name +' policy added!');
              approval_form.setAttribute('data-new', true);
            } else {
              approval_form.setAttribute('data-toast', policy.name +' policy updated!');
              approval_form.removeAttribute('data-new');
              _IntegrationForms.setUsersInput('#primary_approver_user_id', data.approvers.primary_approver);
              if(data.approvers.secondary_approver !== null){
                //console.log('secondary_approver_user_id', secondary_approver_user_id);
                _IntegrationForms.setUsersInput('#secondary_approver_user_id', data.approvers.secondary_approver);
              }

              _IntegrationForms.insertLastEditDate('system-settings-approval-rules', '.approval-rules-last-update span', data.policy.updated_at);
            
            }

            let approval_rule_for_name = document.querySelector('#system-settings-approval-rule-for-name'); 
            approval_rule_for_name.innerHTML = policy.name;

            let default_approval_rule_text = document.querySelector('#default_approval_rule_text'); 
            let office_approval_rule_text = document.querySelector('#office_approval_rule_text'); 

            document.querySelector('#office_approval_rule_name').innerHTML = data.policy.office_title;
               
            if(policy.name!=="Standard policy"){
              default_approval_rule_text.classList.add('d-none');
              office_approval_rule_text.classList.remove('d-none');
            } else {
              default_approval_rule_text.classList.remove('d-none');
              office_approval_rule_text.classList.add('d-none');
            }

            let approval_policy_id = document.querySelector('#approval_policy_id'); 
            approval_policy_id.value = policy.policy_id;

          }


          if(dataFor=="approval-save"){
            let policy = data.policy;
            const approval_form = document.querySelector('#system-settings-approval-conditions');
            let approval_new = approval_form.getAttribute('data-new');
            console.log('new polocy added');
            console.log('approval_new', approval_new);
            if(approval_new){
              let policies_container = document.querySelector(`.system-settings-policies-setup-step__policies .policy-list-${policy.level}`); 
              policies_container.insertAdjacentHTML('beforeend', _IntegrationForms.createPolicyItem(policy, SITEURL));
            }

            // update counter
            let itemPolicyCounter = document.querySelector(".system-settings-policies-setup-step__content .js-system-settings-setup-header-counter .value")
            let itemPolicyes = document.querySelectorAll(".system-settings-policies-setup-step__content .system-settings-policies-setup-step-item.js-system-settings-filter-item")
            if(itemPolicyCounter) itemPolicyCounter.innerHTML = itemPolicyes.length

          }



          if(dataFor=="card-save"){
            
            const card = data.card;
            console.log('card saved');

            const cards_list = document.querySelector('#system-settings-cards-list');
            cards_list.classList.remove('d-none');            

            if(data.new){
              console.log('new added');
             
              if(card.level=="company"){
                let company_wide_cards_container = document.querySelector(`#company_wide_cards_form`); 
                company_wide_cards_container.insertAdjacentHTML('beforeend', _IntegrationForms.createPaymentItem(card, SITEURL));
              }

              if(card.level=="office"){
                let company_wide_cards_container = document.querySelector(`#company_wide_cards_form`); 
                company_wide_cards_container.insertAdjacentHTML('beforeend', _IntegrationForms.createPaymentItem(card, SITEURL));
              }
              
              
              const empty_list = document.querySelector(`#payment-methods-step__notification`);
              if(empty_list){
                empty_list.remove();  
              }            
            }
            
            

            // update counter
            let itemCardsCounter = document.querySelector(".system-settings-payment-methods-step__content .js-system-settings-setup-header-counter .value")
            let itemCards = document.querySelectorAll(".system-settings-payment-methods-step__content .js-system-settings-filter-item")
            if(itemCardsCounter) itemCardsCounter.innerHTML = itemCards.length

            // remove notifications
            const empny_cards_list = document.querySelector(`#payment-methods-step__notification`);
            empny_cards_list.remove();
          }

          
          if(dataFor=="user-update"){

            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user);
            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user_single);
            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.travel_settings);
            const preview_user_avatar = document.querySelector('.user-settings-preview-step #preview_user_avatar');
            preview_user_avatar.setAttribute('src', '/user/profile-avatar/'+data.user.id+'?width=52&height=52');
            _IntegrationForms.insertPreviewDataId('.user-settings-preview-step', data.user.id)
            _IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_booking_reminders', data.user.email_booking_reminders)
            _IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_system_updates', data.user.email_system_updates)

          }


          if(dataFor=="user-update-preferences"){

            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user);
            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user_single);
            _IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.travel_settings);
            
            _IntegrationForms.insertPreviewDataId('.user-settings-preview-step', data.user.id)
            _IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_booking_reminders', data.user.email_booking_reminders)
            _IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_system_updates', data.user.email_system_updates)

          }

          

         
          redirectBySubmit(context)
        } else {
          console.log('error 1');
          context.showResponceAjaxError(data);

        }
      })
      .catch(error => {
        console.error('Error:', error);
        //context.showResponceAjaxError(error);
      }); // Log any errors to the console




    // console.log(context.formState)
    //console.log("submited dataForServer", dataForServer)
   // redirectBySubmit(context)
  } else {
    setErrors(context)
    context.validationInfoNode && checkViewportAndScroll(context.validationInfoNode, context.overflowWrapper)
    context.isScrollToError && checkFirstErrorToScroll(context, context.overflowWrapper)
    context.checkboxesGropus &&
      context.checkboxesGropus.length &&
      context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))
  }
}