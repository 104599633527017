export function setErrors(context) {
  Object.keys(context.validationFieldsState).forEach((key) => {
    const { type, parent, value, input } = context.formState[key]
    const isInputDisabled = input?.disabled
    const isDropdownDisabled = parent.querySelector(".dropdown-trigger")?.disabled
    const cardPattern = /^\d{4}-\d{4}-\d{4}-\d{2,4}$/
    const datePattern = /^\d{2}\s*\/\s*\d{2,}$/
    const cvvPattern = /^\d{3}$/

    if (!isInputDisabled && !isDropdownDisabled) {
      const isValueFill =
        (type === "email" && value.length && value.includes("@")) ||
        (type === "text" && value.length) ||
        (type === "dropdown" && input.value.length) ||
        (type === "password" && value.length) ||
        (type === "confirm_password" && value.length) ||
        (type === "card" && value.match(cardPattern) !== null) ||
        (type === "date" && value.match(datePattern) !== null) ||
        (type === "checkbox" && value) ||
        (type === "secure_code" && value.match(cvvPattern) !== null) ||
        (type === "select_user" && input.dataset.id) ||
        (value.length && context.formState[key].codeValue)
      parent.classList[isValueFill ? "remove" : "add"]("invalid")

      if(type === "password" && parent.classList.contains("js-system-settings-rules-password-field")){ // password rules validation errors
        const validField = value.length >= 8 && /[A-Z]/.test(value) && /\d/.test(value)
        parent.classList[validField ? "remove" : "add"]("invalid")
      }

      if(type === "confirm_password"){ // password confirm rules validation errors
        const parentPasswordInput = context.form.querySelector(".js-system-settings-parent-password input")
        if(!parentPasswordInput) return
        const validField = parentPasswordInput.value == value && value.length > 0
        parent.classList[validField ? "remove" : "add"]("invalid")
      }
    }
  })
}

export function removeError(input, context) {
  const { type, value } = input
  if (type !== "tel") {
    if (type === "email") {
      if (value.length && value.includes("@")) {
        input.closest(".system-settings-form__group-field").classList.remove("invalid")
      }
    } else if (type === "card") {
      const cardNumberPattern = /^\d{4}-\d{4}-\d{4}-\d{2,4}$/
      if (value.match(cardNumberPattern)) {
        input.closest(".system-settings-form__group-field").classList.remove("invalid")
      }
    } else if (type === "date") {
      const datePattern = /^\d{2}\s*\/\s*\d{2,}$/
      if (value.match(datePattern)) {
        input.closest(".system-settings-form__group-field").classList.remove("invalid")
      }
    } else if (type === "secure_code") {
      const cvvPattern = /^\d{3}$/
      if (value.match(cvvPattern)) {
        input.closest(".system-settings-form__group-field").classList.remove("invalid")
      }
    } else {
      if (value.length) {
        input.closest(".system-settings-form__group-field").classList.remove("invalid")
      }
    }
  } else {
    if (value.length && context.formState[input.name].codeValue.length) {
      input.closest(".system-settings-form__group-field").classList.remove("invalid")
    }
  }
}

export function checkFirstErrorToScroll(context, wrapper) {
  const parentRectTop = wrapper.getBoundingClientRect().top

  const scrollTop = Object.keys(context.formState)
    .filter((key) => context.formState[key]?.validation && context.formState[key].parent.classList.contains("invalid"))
    .reduce((toTop, item) => {
      const top = context.formState[item].parent.getBoundingClientRect().top
      return Math.min(top, toTop)
    }, 0)

  wrapper.scrollTo({
    // top: Math.abs(scrollTop + parentRectTop + 30),
    top: scrollTop + parentRectTop + 68 + 30,
    behavior: "smooth",
  })
}

export function checkViewportAndScroll(element, wrapper) {
  const top = element.getBoundingClientRect().top
  wrapper.scrollTo({
    top,
    behavior: "smooth",
  })
  element.classList.add("highlight")
  setTimeout(() => {
    element.classList.remove("highlight")
  }, 3000)
}

export function isFormValid(context) {
  context.isValid = context.validationState.every((item) => item)
  context.validationInfoNode ? context.validationInfoNode.classList[context.isValid ? "add" : "remove"]("valid") : null
  context.isValid && context.submitBtn && context.submitBtn.removeAttribute('disabled')
}
