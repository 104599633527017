export const dummyData = [
  {
    id: 1,
    initials: "AD",
    name: "Asari Dokumbo",
    rank: "Super administrator",
    email: "asari@medibrief.com",
    position: "C-level executives",
    company: "MediBrief Fort Lauderdale",
  },
  {
    id: 2,
    initials: "YA",
    name: "Yoni Ashurov",
    rank: "Administrator",
    email: "yoni@medibrief.com",
    position: "C-level executives",
    company: "MediBrief Fort Lauderdale",
  },
  {
    id: 3,
    initials: "IA",
    name: "Isaac Ashurov",
    rank: "Administrator",
    email: "isaac@medibrief.com",
    position: "C-level executives",
    company: "MediBrief Fort Lauderdale",
  },
  {
    id: 4,
    initials: "DT",
    name: "Danielle Terpko",
    rank: "Corporate traveler",
    email: "danielle@medibrief.com",
    position: "Marketing",
    company: "MediBrief Fort Lauderdale",
  },
  {
    id: 5,
    initials: "EA",
    name: "Eric Aubin",
    rank: "Finance manager",
    email: "eric@medibrief.com",
    position: "Finance",
    company: "MediBrief Fort Lauderdale",
  },
]
