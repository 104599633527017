export const approvalRulesInputs = [
  {
    inputSelector: "#primary_approver_user_id",
    listPosition: "left",
  },
  {
    inputSelector: "#secondary_approver_user_id",
    listPosition: "right",
  },
  {
    inputSelector: "#approval_rules_primary_approver_editing_default",
    listPosition: "left",
  },
  {
    inputSelector: "#approval_rules_secondary_approver_editing_default",
    listPosition: "right",
  },
  {
    inputSelector: "#approval_rules_primary_approver_editing",
    listPosition: "left",
  },
  {
    inputSelector: "#approval_rules_secondary_approver_editing",
    listPosition: "right",
  },
]

export const addCreditCardInputs = [
  {
    inputSelector: "#primary_card_owner",
    listPosition: "left",
  },
  {
    inputSelector: "#secondary_assigned_owner",
    listPosition: "right",
  },
  {
    inputSelector: "#edit_card_primary_card_owner",
    listPosition: "left",
  },
  {
    inputSelector: "#edit_card_secondary_card_owner",
    listPosition: "right",
  },
]

export const userGroupsInputs = [
  {
    inputSelector: "#searchUser_editing",
    listPosition: "full-width",
  },
  {
    inputSelector: "#searchUser",
    listPosition: "full-width",
  },
]
