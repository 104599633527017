import { formEntireFormData } from "./helpers/helpers"
import jQuery from "jquery"
import { getTelInputData } from "./../../../tel-input/tel-input"

export default class CompanyInformationFormValidation {
  constructor(form) {
    this.form = form
    this.selects = form.querySelectorAll('.js-select')
    this.telInputs = form.querySelectorAll('.js-company-input')
    this.nameInputField = form.querySelector('.js-field')
    this.btn = form.querySelector('.js-company-btn')
    this.isEnableBtn = false

    // state
    this.formState = {} 

    this.#init()

    this.isFullForm = false
  }

  initTelInput = (input) => {
    input.addEventListener('input', this.handleTelInput)
  }

  handleTextInput = (event) => {
    const { value } = event.target
    this.formState.inputs["company_name"].value = value
    this.checkForFillForm()
  }

  handleSelect = (event) => {
    this.formState.selects[event.target.name].value = event.target.value
    this.checkForFillForm()
  }

  handleTelInput = (event) => {
    const { value, name: type } = event.target
    const code = this.formState.telInputs[type].dialCode
    this.formState.telInputs[type].value = code + value
    this.checkForFillForm()
  }

  handleDialCodeClick = (event) => {


    const {nodeName} = event.target
    const isLiClicked = nodeName === 'LI'
    const { dial_code } = isLiClicked ? event.target.dataset : event.target.closest('li').dataset
    const {name: inputName} = event.currentTarget.parentNode.previousElementSibling
    this.formState.telInputs[inputName].dialCode = dial_code


    // this.formState.telInputs[type].dialCode = code
    // this.formState.telInputs[type].value = code + this.formState.telInputs[type].input.value

    this.checkForFillForm()
  }

  checkForFillForm = () => {
    const selectsKeys = Object.keys(this.formState.selects)
    const telInputsKeys = Object.keys(this.formState.telInputs)
    const isInputNotEmpty = this.formState.inputs['company_name'].value !== '' 
    const isSelectsNotEmpty = selectsKeys.reduce((arr, key) => {
      if(!this.formState.selects[key].value.length) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    const isTelInpuntsNotEmpty = telInputsKeys.reduce((arr, key) => {
      if(!this.formState.telInputs[key].input.value.length || !this.formState.telInputs[key].dialCode.length) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    const phoneFormat = /^\d{10,},?\d*$/;

    const isTelInputsValid = telInputsKeys.reduce((arr, key) => {
      if(!phoneFormat.test(this.formState.telInputs[key].input.value)) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    this.isEnableBtn = isInputNotEmpty && isSelectsNotEmpty && isTelInpuntsNotEmpty && isTelInputsValid
    this.btn.disabled = !this.isEnableBtn

  }

  handleSubmit = (event) => {
    event.preventDefault()
    
    const postData = this.getFormDataForPost();
    console.log(postData);
    
    const $ = jQuery;
    const self = this;
   
    $.ajax({
      url: this.action,
      method: this.method,
      data: postData,
      dataType:'json',
      headers: {
        'X-CSRF-TOKEN': this.csrf_token
      },
      success: function(response) {
        console.log('response');
        console.log(response);
        if(response.redirectUrl){
          window.location.href = response.redirectUrl;
        } 
        // add notifications
      },
      error: function(jqXHR) {
        console.log('ERR');
        console.log(jqXHR);
        
        self.showResponceAjaxError(jqXHR.responseJSON)
      }
    });

/*
    fetch(this.action, {
      method: this.method,
      mode: 'same-origin',
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': this.csrf_token
      },
      body: JSON.stringify(postData)
    })
    .then(response => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
    })
    .catch(error => {
      console.error('Error:', error);
     // this.showResponceAjaxError(error.responseJSON);
    });*/

    /*
    if (this.form.hasAttribute('data-href')) {
      const ftpDir = '/b/travlio/'
      setTimeout(() => {
        if(window.location.origin.includes("localhost")){
          window.location.href = `/${this.form.getAttribute('data-href')}`
        }else{
          window.location.href = `${ftpDir}${this.form.getAttribute('data-href')}`
        }
      }, 500)
    }*/

  }

  

  #init() {
    
    if (this.telInputs.length) {
      this.telInputs.forEach(this.initTelInput)
    }

    this.action = this.form.getAttribute("data-href")
    this.method = this.form.getAttribute("method") || "GET"
    this.addCsrfToken()
    
    this.formState = {...formEntireFormData(this.selects, this.nameInputField, this.telInputs)}
    Object.keys(this.formState.telInputs).forEach(key => {
      this.formState.telInputs[key].countryList.addEventListener('click', this.handleDialCodeClick)
    })
    this.formState.inputs["company_name"].input.addEventListener('input', this.handleTextInput)

    const selectInputs = Object.keys(this.formState.selects).map(key => {
      return this.formState.selects[key].input
    })
    selectInputs.forEach(input => {
      input.addEventListener("change", (event) => {
        this.handleSelect(event)
      })
    })

    this.form.addEventListener('submit', this.handleSubmit)
  }

  addCsrfToken() {
    const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
    const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : document.querySelector('meta[name="csrf_token"]')
  
    this.csrf_token = csrfTokenValue;
  }


  getFormDataForPost() {
    let postData = {};
  
    const work_phone_number = getTelInputData("#work_phone_number")
    const mobile_phone_number = getTelInputData("#mobile_phone_number")

    //console.log(work_phone_number)
    //console.log(mobile_phone_number)


    postData = {
        company_name: this.formState.inputs.company_name.value,
        annual_booking_budget_estimate: this.formState.selects.annual_booking_budget_estimate.value,
        number_of_employees: this.formState.selects.number_of_employees.value,
        job_title: this.formState.selects.job_title.value,
        work_phone_number: work_phone_number.numberValue,
        work_phone_number_dialcode: work_phone_number.codeValue,
        work_phone_number_country: work_phone_number.countryValue,
        mobile_phone_number: mobile_phone_number.numberValue,
        mobile_phone_dialcode: mobile_phone_number.codeValue,
        mobile_phone_country: mobile_phone_number.countryValue,
    };
    
    return postData;
  }

  // Error conteiner
  createErrorMessage = (fieldName, errorMessage) => {
    return `<div class="form-field _invalid js-errors">
              <span class="form-field__error js-field-error">${errorMessage}</span>
            </div>`;
  }

  showResponceAjaxError = (responseJSON) => {
    // clear old errors
    const errorElements = document.querySelectorAll('.js-errors');
    errorElements.forEach(element => {
      element.remove();
    });
    
    for (const fieldName in responseJSON.messages) {
      if (responseJSON.messages.hasOwnProperty(fieldName)) {
        const errorMessage = responseJSON.messages[fieldName];
        const msg = this.createErrorMessage(fieldName, errorMessage);
        document.querySelector(`[name="${fieldName}"]`).closest('.form-field').insertAdjacentHTML('beforeend', msg);
      }
    }
  }
}