import { Toast } from "../../components/toast"
import { scrollbarLock, scrollbarUnlock } from "../../utils/scrollbar-locker"
import { SystemSettingsDefaultOffice } from "./modules/default-office"
import { SystemSettingsDynamicText } from "./modules/dinamic-change-text"
import { SystemSettingsPolicyItemRemove } from "./modules/remove-item-policy"
import { SystemSettingsSearchList } from "./modules/search-system-list"
import  { UserDataForQI } from "./load-users-for-quick-invite"
import  { IntegrationForms } from "./integration-forms"
import { handleSearch } from "./form-validation/helpers/_handlers"
import { setTelInputData } from  "../../components/tel-input/tel-input"
import { setInputValue } from  "../setInputValue"
import { GetAddress } from "./modules/offices-setup/office-address"
import { Modal } from "./../../components/modals/modal"
import { defaultModalOptions } from "./../../components/modals/modal.default"

export class SystemSettings {
  constructor() {
    this.container = document.querySelector(".js-system-settings")
    if (!this.container) return

    this.defaultStartStep = "system-settings-preview"
    this.activeStep = this.defaultStartStep
    this.prevStep = this.defaultStartStep
    this.defaultBackHolder = "Back to"

    this.openSettingsTriggers = document.querySelectorAll(".js-trigger-info_sidebar")
    this.closeSettingsTriggers = document.querySelectorAll(".js-system-settings__close-btn")

    this.stepNextTriggers = document.querySelectorAll(".js-system-settings__step-trigger")
    this.stepBackTriggers = document.querySelectorAll(".js-system-settings__step-back-trigger")

    this.searchListInputs = this.container.querySelectorAll(".js-system-settings-list-search")
    this.officeSetupItems = this.container.querySelectorAll(".system-settings-offices-setup-step-item")
    this.dynamicTextInputs = this.container.querySelectorAll(".js-system-settings-dynamic-input input")
    this.modalRedirectBtns = document.querySelectorAll(".js-system-settings-modal-redirect-btn")

    this.IntegrationForms = new IntegrationForms();
    this.errorMsg = '';

    this.#init()
  }

  openSystemSettings = () => {
    // open settings event
    this.container.classList.add("active")
    scrollbarLock()
    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((trigger) => {
        trigger.classList.add("active")
      })
    const activeSidenavLink = document.querySelector(".js-sidenavnav-link.active")
    if (activeSidenavLink) activeSidenavLink.classList.add("disable-action")
  }

  closeSystemSettings = () => {
    // close settings event
    this.container.classList.remove("active")
    scrollbarUnlock()
    setTimeout(this.changeStep, 200)
    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((trigger) => {
        trigger.classList.remove("active")
      })
    const activeSidenavLink = document.querySelector(".js-sidenavnav-link.active")
    if (activeSidenavLink) activeSidenavLink.classList.remove("disable-action")
  }

  changeStep = (activeStepName = this.defaultStartStep) => {
    this.activeStep == this.defaultStartStep
      ? (this.prevStep = this.defaultStartStep)
      : (this.prevStep = this.activeStep)
    this.activeStep = activeStepName
    const prevStepDiv = document.querySelector(`.js-system-settings__content[data-step='${this.prevStep}'].active`)
    prevStepDiv && prevStepDiv.classList.remove("active")
    const activeStepDiv = document.querySelector(`.js-system-settings__content[data-step='${this.activeStep}']`)
    activeStepDiv && activeStepDiv.classList.add("active")
  }

  setStepBackBtn = (backHolder = this.defaultBackHolder) => {
    const stepBackBtn = document.querySelector(
      `.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-header__backbtn`,
    )
    if (stepBackBtn) {
      stepBackBtn.setAttribute("data-step", this.prevStep)
      const valueHolder = stepBackBtn.querySelector(".value")
      if (!valueHolder) valueHolder.innerHTML = backHolder
    }
  }

  setFormRedirect = (redirectLink) => {
    const stepForm = document.querySelector(
      `.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-form`,
    )
    if (stepForm) stepForm.setAttribute("data-redirect-step", redirectLink)
  }

  setNextStep = (activeStepName, backHolder, redirectLink = this.activeStep) => {
    // set active step
    const isActiveStep = document.querySelector(`.js-system-settings__content[data-step='${activeStepName}']`)
      ? true
      : false
    if (!isActiveStep) return
    this.changeStep(activeStepName)
    this.setStepBackBtn(backHolder)
    this.setFormRedirect(redirectLink)
  }


  stepNextTriggerClick = (stepNextTrigger) => {
    // change step next btn click event
    if (!stepNextTrigger.hasAttribute("data-step")) return

    if (stepNextTrigger.classList.contains("disable-step")) {
      if (stepNextTrigger.hasAttribute("data-toast"))
        this.showToast(stepNextTrigger.getAttribute("data-toast"), "error")
    } else {
      if (!this.container.classList.contains("active")) {
        scrollbarLock()
        this.container.classList.add("active")
      }
      if (stepNextTrigger.hasAttribute("data-back-holder")) {
        this.setNextStep(stepNextTrigger.getAttribute("data-step"), stepNextTrigger.getAttribute("data-back-holder"))
      } else this.setNextStep(stepNextTrigger.getAttribute("data-step"))
    }
    this.loadDataToForm(stepNextTrigger);
  }

  stepBackTriggerClick = (stepBackTrigger) => {
    // change step back btn click event
    if (!stepBackTrigger.hasAttribute("data-step")) return
    this.changeStep(stepBackTrigger.getAttribute("data-step"))
  }


  stepTriggerClick = (stepTrigger) => { // change step btn click event
    if(!stepTrigger.hasAttribute("data-step")) return
    const prevStep = this.activeStep // not for regular use 
    this.setActiveStep(stepTrigger.getAttribute("data-step"))
    if(stepTrigger.classList.contains("js-redirect-change-btn-department")){// not for regular use (need to cnange logic for optimization)
      const backBtn = document.querySelector(`.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-header__backbtn`)
      if(backBtn) backBtn.setAttribute("data-step", prevStep)
      const formStep = document.querySelector(`.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-form`) 
      if(formStep) formStep.setAttribute("data-redirect-step", prevStep)
    }
    // to do new logic for steper
    this.loadDataToForm(stepTrigger);
  }


  loadDataToForm = (e) => {
    console.log(e);
    console.log(e.classList);
    let data_step = e.getAttribute("data-step");
     // Check if the clicked element is the intended button
      if (e.classList.contains("load-data-btn")) {

        const getUrl = e.getAttribute("data-url"); // Get the url
        console.log('getUrl', getUrl);
        var getBaseUrl = e.getAttribute("data-base-url"); // Get base the url
        console.log('getUrl', getBaseUrl);
        const itemId = e.getAttribute("data-id"); // Get the unique ID of the item
        console.log('itemId', itemId);
        const dataFor = e.getAttribute("data-for"); 
        console.log('dataFor', dataFor);
        
        
        // Perform a fetch request to the server
        fetch(`${getUrl}/${itemId}`)
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
                // Loop through each key-value pair in the response
                console.log(data);
                if(dataFor=="company"){
                  
                  let complete_status = data.company['complete_status'];                  
                  let namePlaceholder = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .editCompanyName`);
                  let formElement = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .js-system-settings-form`);
                  // set company name
                  namePlaceholder.innerHTML = data.company['company_name'];
                  console.log(formElement);
                  formElement.setAttribute('data-toast', data.company['company_name']+' updated!');
                  /*
                  console.log('complete_status', complete_status);
                  
                  let statusBlock = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step__header-block__isValid`);

                  if(complete_status){      
                    // set Complete status                               
                     statusBlock.classList.add('valid');
                  } else {
                    statusBlock.classList.remove('valid');
                  } */
                  //console.log(data.company);
                  this.IntegrationForms.insertInputData(data.company, data_step);

                  setTelInputData("#company_edit_phone_number", data.company.phone_number_code, data.company.phone_number_country, data.company.phone_number)
                }

                if(dataFor=="offices_list"){
                    console.log('TODO: load list');
                }

                if(dataFor=="new-office"){
                  console.log('TODO: new-office');
                  data_step = 'system-settings-office-edit';
                  // Clear Form 
                  let form = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] form`);
                  console.log(form);
                  form.setAttribute('data-toast','New office created!');
                  this.IntegrationForms.clearForm(form);

                  // Set up header
                  let header = document.querySelector('#system-setting-new-company-header');
                  header.querySelector('span.value').innerHTML = data.company['company_name'];
                  // Hide Delete office Btn
                  let deletebtn = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`);
                  deletebtn.classList.add('d-none');
                  
                  const input_office_id = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='id']`);
                  input_office_id.value = 0;

                  const input_company_id = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='company_id']`);
                  input_company_id.value = data.company['id'];

                  let lock = document.querySelector('#office_email_domain_lock_for_edit');
                  lock.classList.add('d-none');
                  
                  let office_email_domain = document.querySelector('#system_settings_office_email_domain');
                  office_email_domain.removeAttribute('disabled')
                  
                }

                if(dataFor=="edit-office"){
                   // Set up header for edit
                  let header = document.querySelector('#system-setting-new-company-header');
                  header.querySelector('p').classList.remove('d-none');
                  header.querySelector('div').classList.add('d-none');
                  // show Delete office btn
                  let deletebtn = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`);
                  deletebtn.classList.add('d-none');

                  //TODO complete_status -add to model
                  let complete_status = data.office['complete_status'];                                    
                  let formElement = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .js-system-settings-form`);
                  
                  console.log(formElement);
                  formElement.setAttribute('data-toast', data.office['office_title']+' updated!');
                  
                  if(complete_status){      
                    // set Complete status             
                    let statusBlock = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step__header-block__isValid`);
                    statusBlock.classList.add('valid');
                  }

                  let is_default = data.office['is_default'];    
                  console.log('is_default', is_default); 
                  if(is_default==1){
                    document.querySelector('#is_default_office').classList.remove('d-none');
                    // Hide Delete office Btn
                    let deletebtn = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`);
                    deletebtn.classList.add('d-none');
                  
                  } else {
                    document.querySelector('#is_default_office').classList.add('d-none');
                    // Show Delete office Btn
                    let deletebtn = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`);
                    deletebtn.classList.remove('d-none');
                  
                  }

                  
                  document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`).setAttribute('data-id', data.office['id']);

                  //console.log(data.office);
                  this.IntegrationForms.insertInputData(data.office, data_step);

                  setTelInputData("#office_edit_phone_number", data.office.phone_number_code, data.office.phone_number_country, data.office.phone_number)

                  let lock = document.querySelector('#office_email_domain_lock_for_edit');
                  lock.classList.remove('d-none');

                  let office_email_domain = document.querySelector('#system_settings_office_email_domain');
                  office_email_domain.setAttribute('disabled', 'disabled')
                  
              }

              // Quick invite
              if(dataFor=="user-invite"){
                data = data.data;
                console.log(data.offices);
                console.log(data.departments);

                // Set office mail domain
                let office_domain = document.querySelector(`#quick_invite_company_email_user`);
                office_domain.setAttribute('data-email', data.current_office.email_domain);
                let mail_span = office_domain.closest('div').querySelector('.current-company-email__wrap');
                mail_span.innerHTML = '@'+data.current_office.email_domain;

                let offices_list = document.querySelector(`#quick_invite_users_offices_list`);
                
                // current_office
                let listItem = document.createElement('li');
                listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                listItem.setAttribute('data-value', data.current_office.office_title);
                listItem.setAttribute('data-id', data.current_office.id);
              
                // Додавання вмісту до li
                listItem.innerHTML = `
                              <span class="input-dropdown__list-item__value js-list-item__value-text">
                              ${data.current_office.office_title}
                              </span>
                              <span class="checked">
                                <svg class="icon icon-checkbox ">
                                  <use href="/assets/thames/default/icon/icons/icons.svg#checkbox" />
                                </svg>
                              </span>
                `;
                offices_list.appendChild(listItem);
                
                let new_department_btn = document.querySelector(`button.system-settings-quick-invite-step__new-department-btn`);
                new_department_btn.setAttribute('data-id', data.current_office.id);


                // Add rest offices
                data.offices.forEach(office => {
                  // Створення нового елементу li
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                  listItem.setAttribute('data-value', office.office_title);
                  listItem.setAttribute('data-id', office.id);
                
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                                <span class="input-dropdown__list-item__value js-list-item__value-text">
                                ${office.office_title}
                                </span>
                                <span class="checked">
                                  <svg class="icon icon-checkbox ">
                                    <use href="/assets/thames/default/icon/icons/icons.svg#checkbox" />
                                  </svg>
                                </span>
                  `;
                
                  // Додавання створеного елементу до списку
                  offices_list.appendChild(listItem);
                });

                // RE-init search in Office dropdown
                let searchInput = offices_list.closest('div').querySelector(".js-system-settings__search-input")
                let officeDropdown = offices_list.closest('div.js-system-settings__dropdown-wrap')
                let { type, currency, rating } = officeDropdown.dataset
                this.formState = {}
                this.formState[type] = {};
                this.formState[type].items = offices_list.querySelectorAll(".js-system-settings__dropdown-list-item")
                searchInput.addEventListener("input", (event) => handleSearch(event, type, this))

                const to_set = offices_list.querySelector(`.js-system-settings__dropdown-list-item[data-id="${data.current_office.id}"]`);
                console.log('to_set', to_set);
                to_set.click();

                

                // Departaments
                let departments_list = document.querySelector(`#quick_invite_users_departments_list`);
                data.departments.forEach(department => {
                  // Створення нового елементу li
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                  listItem.setAttribute('data-value', department.name);
                  listItem.setAttribute('data-id', department.id);
                
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                                <span class="input-dropdown__list-item__value">
                                  ${department.name}
                                </span>
                                <span class="input-dropdown__list-item__value js-list-item__value-text">
                                ${department.description}
                                </span>
                  `;
                
                  // Додавання створеного елементу до списку
                  departments_list.appendChild(listItem);
                });

                let UserData = new UserDataForQI();
                UserData.loadUserDataForQI(data.current_office.id);

              }


              // Create department
              if(dataFor=="new-department"){
                console.log(data.data.current_office);
                let placeholder = document.querySelector(`#new-department-office-name`);
                placeholder.innerHTML = data.data.current_office.office_title + " office";
                let input = document.querySelector(`#department_office_id`);
                input.value = data.data.current_office.id;
                input.setAttribute('value', data.data.current_office.id);
                
                let departments_list = document.querySelector(`#edit-department-list`);
                
                data.data.departments.forEach(department => {
                  let listItem = document.createElement('div');
                  listItem.className = 'system-settings-department-list-item js-system-settings-filter-item department-list-id_'+department.id;
                  listItem.innerHTML = this.IntegrationForms.createDepertamentItem(department);
                  departments_list.appendChild(listItem);
                  
                });

                new Modal({ ...defaultModalOptions, container: "#modal-delete-department" })

                let department_users_total = document.querySelector(`#edit-department-users-total`);
                department_users_total.innerHTML =  data.data.departments.length;

              }


               // Create new policy
              if(dataFor=="new-policy"){
                // Set company_id
                let input = document.querySelector(`#policy_company_id`);
                input.value = data.company_id;
                input.setAttribute('value', data.company_id);
                // clear policy_id
                let policy_id = document.querySelector(`#policy_id`);
                policy_id.value = '';
                policy_id.setAttribute('value', '');
               

                let form = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] form`);
                //form.setAttribute('data-toast','New office created!');
                // Clear all form
                this.IntegrationForms.clearForm(form);

                // Hide default block
                let default_policy = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step__header-block__default`);
                default_policy.classList.add('d-none');
                

                let policy_name = this.IntegrationForms.getInputElement('name', 'system-settings-policy-form')               
                policy_name.removeAttribute('disabled');

                let office_dropdown_input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] #office_id`);
                let office_dropdown = office_dropdown_input.closest('div.system-settings-form__group-field')
                office_dropdown.classList.add('hidden');

                let level = this.IntegrationForms.getInputElement('level', 'system-settings-policy-form')               
                level.removeAttribute('disabled');

                let to_hide = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .lock-for-edit`);
                to_hide.forEach(el => { 
                  console.log('el - show', el);
                  el.classList.add('d-none');
                } )

                let to_show = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .unlock-for-edit`);
                to_show.forEach(el => { 
                  console.log('el - hide', el);
                  el.classList.remove('d-none');
                } )

                
                let policy_offices_list = document.querySelector(`#policy_assignment_offices`);
                // clear
                policy_offices_list.innerHTML = '';
                // add new
                data.offices.forEach(office => {
                  console.log(office);
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                  listItem.setAttribute('data-value', office.office_title);
                  listItem.setAttribute('data-id', office.id);
              
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                      <span class="input-dropdown__list-item__value js-list-item__value-text">
                          ${office.office_title}
                      </span>
                      <span class="checked">
                          <svg class="icon icon-checkbox">
                              <use href="/assets/thames/default/icon/icons/icons.svg#checkbox"></use>
                          </svg>
                      </span>
                  `;
                
                  // Додавання створеного елементу до списку
                  policy_offices_list.appendChild(listItem);
                });

                let adaptive_rate_limit =  document.querySelector(`#adaptive_rate_limit`);
                let fixed_rate_limit =  document.querySelector(`#fixed_rate_limit`);
                //let no_rate_limit =  document.querySelector(`#no_rate_limit`);

                adaptive_rate_limit.removeAttribute('checked')
                fixed_rate_limit.removeAttribute('checked')
                //no_rate_limit.removeAttribute('checked')

                adaptive_rate_limit.setAttribute('checked', true)

                setInputValue("#star_rating_min", false, '3');
                setInputValue("#star_rating_max", false, '5');

                setInputValue("#days_required_before_booking", false, '0');
                
              }

              

              if(dataFor=="edit-policy"){
                let input = document.querySelector(`#policy_company_id`);
                input.value = data.company_id;
                input.setAttribute('value', data.company_id);
                let remove_btn = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`);
                remove_btn.setAttribute('data-id', data.policy.policy_id);

                let form = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] form`);
                // Clear all form
                this.IntegrationForms.clearForm(form);

                let office_dropdown_input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] #office_id`);
                let office_dropdown = office_dropdown_input.closest('div.system-settings-form__group-field')
                office_dropdown.classList.add('hidden');

                this.IntegrationForms.insertInputData(data.policy, data_step);

                let policy_name = this.IntegrationForms.getInputElement('name', 'system-settings-policy-form')               
                policy_name.setAttribute('disabled', true);

                let level = this.IntegrationForms.getInputElement('level', 'system-settings-policy-form')               
                level.setAttribute('disabled', true);

                
                let to_show = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .lock-for-edit`);
                to_show.forEach(el => { 
                  console.log('el - show', el);
                  el.classList.remove('d-none');
                } )

                let to_hide = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .unlock-for-edit`);
                to_hide.forEach(el => { 
                  console.log('el - hide', el);
                  el.classList.add('d-none');
                } )


                let default_policy = document.querySelector(`#policyedit-default-policy-checkbox`);
                let policy_assignment_level = document.querySelector(`#policy_assignment_level`);
                if(data.policy.is_default==1){
                  default_policy.classList.remove('d-none');
                  // hide remove button
                  remove_btn.classList.add('d-none');
                  policy_assignment_level.classList.add('d-none');
                } else {
                  default_policy.classList.add('d-none');
                  remove_btn.classList.remove('d-none');
                  policy_assignment_level.classList.remove('d-none');
                }

                this.IntegrationForms.insertLastEditDate(data_step, '.system-settings-form-group__title-date span', data.policy.updated_at);

                let adaptive_rate_limit =  document.querySelector(`#adaptive_rate_limit`);
                let fixed_rate_limit =  document.querySelector(`#fixed_rate_limit`);
                //let no_rate_limit =  document.querySelector(`#no_rate_limit`);

                adaptive_rate_limit.removeAttribute('checked')
                fixed_rate_limit.removeAttribute('checked')
               // no_rate_limit.removeAttribute('checked')

                if(data.policy.rate_type=="adaptive"){
                  adaptive_rate_limit.setAttribute('checked', true)
                }

                if(data.policy.rate_type=="fixed"){
                  fixed_rate_limit.setAttribute('checked', true)
                }
                /*
                if(data.policy.rate_type=="no_rate"){
                  no_rate_limit.setAttribute('checked', true)
                }*/

                
                if(data.policy.refund_requirement=="refundable_only"){
                  let refund_requirement =  document.querySelector(`#refund_requirement`);
                  refund_requirement.removeAttribute('checked')
                }
               
                
                let policy_offices_list = document.querySelector(`#policy_assignment_offices`);
                // clear
                policy_offices_list.innerHTML = '';
                // add new
                data.offices.forEach(office => {
                  //console.log(office);
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                  listItem.setAttribute('data-value', office.office_title);
                  listItem.setAttribute('data-id', office.id);
              
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                      <span class="input-dropdown__list-item__value js-list-item__value-text">
                          ${office.office_title}
                      </span>
                      <span class="checked">
                          <svg class="icon icon-checkbox">
                              <use href="/assets/thames/default/icon/icons/icons.svg#checkbox"></use>
                          </svg>
                      </span>
                  `;
                
                  // Додавання створеного елементу до списку
                  policy_offices_list.appendChild(listItem);
                });
              }


              
              if(dataFor=="preview-policy"){
                this.IntegrationForms.insertPreviewData(data.policy, data_step);
                this.IntegrationForms.insertSwitcherData(data_step, 'level', data.policy.level);
                this.IntegrationForms.insertSwitcherData(data_step, 'refund_requirement', data.policy.refund_requirement);
                this.IntegrationForms.insertSwitcherData(data_step, 'approval_conditions', data.policy.approval_conditions);
                this.IntegrationForms.insertSwitcherData(data_step, 'rate_type', data.policy.rate_type);
                this.IntegrationForms.insertLastEditDate(data_step, '.field-value-days_last_updated', data.policy.updated_at);
                this.IntegrationForms.insertDataId(data_step, data.policy.policy_id);
                this.IntegrationForms.setApproverPreviw('#policy-preview-primary-approver', data.primary_approver);
                this.IntegrationForms.setApproverPreviw('#policy-preview-secondary-approver', data.secondary_approver);

                let LastUpdaterFullName = `${data.last_updated_user[0].first_name} ${data.last_updated_user[0].last_name}`;
                let LastUpdater_container =  document.querySelector(`#policy-preview-last_updated_user`);
                LastUpdater_container.innerHTML = LastUpdaterFullName;

                // Show / hide office row
                /*let office_conteiner = document.querySelector(`.system-settings-policy-preview-step__block .field-value-office_title`).closest('li');
                office_conteiner.classList.add('d-none');
                if(data.policy.office_id>0){ 
                  office_conteiner.classList.remove('d-none');
                } */
                
              }

               // Create new card
               if(dataFor=="new-card"){
                
                let input = document.querySelector(`#card_company_id`);
                input.value = data.company_id;
                input.setAttribute('value', data.company_id);
                // clear card_id
                let card_id = document.querySelector(`#card_id`);
                card_id.value = '';
                card_id.setAttribute('value', '');
               

                let form = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] form`);
                // Clear all form
                this.IntegrationForms.clearForm(form);

               
                /*
                let office_dropdown_input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] #office_id`);
                let office_dropdown = office_dropdown_input.closest('div.system-settings-form__group-field')
                office_dropdown.classList.add('hidden');*/

              //  let level = this.IntegrationForms.getInputElement('level', 'system-settings-policy-form')               
               // level.removeAttribute('disabled');

                let to_hide = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .lock-for-edit`);
                to_hide.forEach(el => { 
                  console.log('el - show', el);
                  el.classList.add('d-none');
                } )

                let to_show = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .unlock-for-edit`);
                to_show.forEach(el => { 
                  console.log('el - hide', el);
                  el.classList.remove('d-none');
                } )

                
                let card_offices_list = document.querySelector(`#card_offices_list`);
                // clear
                card_offices_list.innerHTML = '';
                // add new
                data.offices.forEach(office => {
                  console.log(office);
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item js-office-dropdown-list-item';
                  listItem.setAttribute('data-value', office.office_title);
                  listItem.setAttribute('data-id', office.id);
                  listItem.setAttribute('data-item-type', 'office-item');
              
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                      <span class="input-dropdown__list-item__value js-list-item__value-text">
                          ${office.office_title}
                      </span>
                      <span class="checked">
                          <svg class="icon icon-checkbox">
                              <use href="/assets/thames/default/icon/icons/icons.svg#checkbox"></use>
                          </svg>
                      </span>
                  `;
               
                  // Додавання створеного елементу до списку
                  card_offices_list.appendChild(listItem);
                });
              }

              
  
              if(dataFor=="edit-card"){ 

                let input = document.querySelector(`#card_company_id`);
                input.value = data.company_id;
                input.setAttribute('value', data.company_id);
                // clear card_id
                let card_id = document.querySelector(`#card_id`);
                card_id.value = data.card.card_id;
                card_id.setAttribute('value', data.card.card_id);


                let form = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] form`);
                // Clear all form
                this.IntegrationForms.clearForm(form);

                this.IntegrationForms.insertInputData(data.card, data_step);

                this.IntegrationForms.clearUsersInputs(['#primary_card_owner', '#secondary_assigned_owner']);

                form.setAttribute('data-toast', 'Card updated!');
                //form.removeAttribute('data-new');
                this.IntegrationForms.setUsersInput('#primary_card_owner', data.card_ownes.primary_card_owner);
                this.IntegrationForms.setUsersInput('#secondary_assigned_owner', data.card_ownes.secondary_assigned_owner);

                this.IntegrationForms.setInputElement('card_number', '****-*****-****-'+data.card.last_four_digit, data_step);
                this.IntegrationForms.setCardType(data.card.payment_system);
                this.IntegrationForms.setInputElement('expiration_date', data.card.expiration_date_format, data_step);
                this.IntegrationForms.setInputElement('security_code', '***', data_step);

                this.IntegrationForms.setCheckBoxElement('email_charged', data.card.email_charged, data_step);
                this.IntegrationForms.setCheckBoxElement('email_expiration', data.card.email_expiration, data_step);
                this.IntegrationForms.setCheckBoxElement('card_primary_owner_approve', '1', data_step);



                let to_show = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .lock-for-edit`);
                to_show.forEach(el => { 
                  console.log('el - show', el);
                  el.classList.remove('d-none');
                } )

                let to_hide = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .unlock-for-edit`);
                to_hide.forEach(el => { 
                  console.log('el - hide', el);
                  el.classList.add('d-none');
                } )

                document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-office-remove`).setAttribute('data-id', data.card.card_id);


                let card_offices_list = document.querySelector(`#card_offices_list`);
                // clear
                card_offices_list.innerHTML = '';
                // add new
                data.offices.forEach(office => {
                  console.log(office);
                  let listItem = document.createElement('li');
                  listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item js-office-dropdown-list-item';
                  listItem.setAttribute('data-value', office.office_title);
                  listItem.setAttribute('data-id', office.id);
                  listItem.setAttribute('data-item-type', 'office-item');
              
                  // Додавання вмісту до li
                  listItem.innerHTML = `
                      <span class="input-dropdown__list-item__value js-list-item__value-text">
                          ${office.office_title}
                      </span>
                      <span class="checked">
                          <svg class="icon icon-checkbox">
                              <use href="/assets/thames/default/icon/icons/icons.svg#checkbox"></use>
                          </svg>
                      </span>
                  `;
               
                  // Додавання створеного елементу до списку
                  card_offices_list.appendChild(listItem);
                });
              }
              if (dataFor == "policy-manuals") {
                const container = document.getElementById('systemSettingsList__policyManuals');
                container.innerHTML = ''
                var listItem = data.data;
                console.log('listItem', listItem);
                document.getElementById('systemSettingsCount__policyManuals').textContent = listItem.length;

                listItem.forEach(item => {
                  this.addManual(item, getBaseUrl);
                });
                // var policy = new SystemSettingsPolicyItemRemove;
                // policy.init();
                var systemSettingsPolicyItemRemove = new SystemSettingsPolicyItemRemove();
                systemSettingsPolicyItemRemove.updateCountOfItems();
              }              
            })
            .catch(error => console.error('Error:', error)); // Log any errors to the console
       }
  }

  addManual = (itemData, getBaseUrl, isNew = false) => {
    const container = document.getElementById('systemSettingsList__policyManuals');
    const element = document.createElement('div');
    var className = 'system-settings-policy-step-item js-system-settings-filter-item';
    if (isNew) className += ' _new';
    element.className = className;
    element.innerHTML = `
        <div class="new-label">
            <span>New</span>
        </div>
        <div class="system-settings-policy-step-item__heading">
            <div class="system-settings-policy-step-item__icon">
                <svg class="icon icon-file">
                    <use href="/assets/thames/default/icon/icons/icons.svg#file"></use>
                </svg>
            </div>
            <h3 class="system-settings-item-title js-system-settings-item-title">
                ${itemData.title}
            </h3>
            <div class="system-settings-policy-step-item__buttons">
                <button 
                    class="system-settings-btn primary-link system-settings-policy-step-item__remove"
                    data-item-id="${itemData.id}"
                    data-url="${getBaseUrl}policy-manuals/delete-policy-manual"
                    type="button" 
                    aria-label="Delete button"
                >
                    Delete
                </button>
            </div>
        </div>
        <a href="${getBaseUrl}policy-manuals/download-policy-manual/${itemData.id}" target="_blank" class="system-settings-policy-step-item__setup-btn">
            Download
        </a>
      `;
    container.appendChild(element);
  };

  showToast = (toastMessage = "Default message", toastStatus = "success") => {
    new Toast({ position: ".system-settings-content-box", text: toastMessage, type: toastStatus })
  }

  redirectTargetAction = (
    redirectName = this.defaultStartStep,
    backHolder = this.defaultBackHolder,
    redirectToTarget = this.prevStep,
  ) => {
    this.setNextStep(redirectName, backHolder, redirectToTarget)
  }

  redirectAction = (redirectName = this.defaultStartStep, toastMessage) => {
    this.changeStep(redirectName)
    this.showToast(toastMessage)
  }

  outsideSettingsClick = (event) => {
    // outside close settings event
    if (event.target.classList.contains("system-settings")) this.closeSystemSettings()
  }


  initSystemSettings = () => {
    // initial values and functions
    this.changeStep()

    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((triggerSystem) => {
        triggerSystem.addEventListener("click", this.openSystemSettings)
      })
    this.closeSettingsTriggers &&
      this.closeSettingsTriggers.forEach((closeSystem) => {
        closeSystem.addEventListener("click", this.closeSystemSettings)
      })

    this.modalRedirectBtns &&
      this.modalRedirectBtns.forEach((modalBtn) => {
        modalBtn.addEventListener("click", () => {
          if(modalBtn.hasAttribute("data-url")) {
            this.processModal(modalBtn);
          }
          if (!modalBtn.hasAttribute("data-redirect-step")) return

          console.log('this.errorMsg', this.errorMsg);
          console.log('this.errorMsg.length', this.errorMsg.length);
          if(this.errorMsg.length>0) this.showToast(this.errorMsg, "error")
          else this.redirectAction(modalBtn.getAttribute("data-redirect-step"), modalBtn.getAttribute("data-toast"))
        })
      })
    this.stepNextTriggers &&
      this.stepNextTriggers.forEach((stepNextTrigger) => {
        stepNextTrigger.addEventListener("click", () => {
          this.stepNextTriggerClick(stepNextTrigger)
        })
      })
    this.stepBackTriggers &&
      this.stepBackTriggers.forEach((stepBackTrigger) => {
        stepBackTrigger.addEventListener("click", () => {
          this.stepBackTriggerClick(stepBackTrigger)
        })
      })

    this.searchListInputs &&
      this.searchListInputs.forEach((searchInput) => {
        new SystemSettingsSearchList(searchInput)
      })
    this.officeSetupItems &&
      this.officeSetupItems.forEach((officeItem) => {
        new SystemSettingsDefaultOffice(officeItem)
      })
    this.dynamicTextInputs &&
      this.dynamicTextInputs.forEach((dynamicInput) => {
        new SystemSettingsDynamicText(dynamicInput)
      })
    new SystemSettingsPolicyItemRemove()

      // NEW office events
      const offices_container = document.querySelector('.system-settings-offices-setup-step__content .system-settings-list');

      var self = this;
      const callback = function(mutationsList, observer) {
          for(let mutation of mutationsList) {
              if (mutation.type === 'childList') {
                  console.log('Offices container chanched');
                  
                  let offices_stepNextTriggers = document.querySelectorAll('.system-settings-offices-setup-step__content ._new .js-system-settings__step-trigger');
                  //console.log('offices_stepNextTriggers', offices_stepNextTriggers);
                  offices_stepNextTriggers && offices_stepNextTriggers.forEach(stepNextTrigger => {
                    stepNextTrigger.addEventListener("click", () => {self.stepNextTriggerClick(stepNextTrigger)})
                  })
  
                  let newOffice = offices_container.querySelector(".system-settings-offices-setup-step-item._new")
                  //console.log('newOffice', newOffice);
                  new SystemSettingsDefaultOffice(newOffice)
              }
          }
      };
  
      const observer = new MutationObserver(callback);
      observer.observe(offices_container, { childList: true });

    window.addEventListener("click", this.outsideSettingsClick)
  }



  processModal = (modalBtn) => {
     // Check if the clicked element is the intended button
      if (modalBtn.hasAttribute("data-url")) {

        const postUrl = modalBtn.getAttribute("data-url"); // Get the url
        console.log('postUrl', postUrl);
        const itemId = modalBtn.getAttribute("data-id"); // Get the unique ID of the item
        console.log('itemId', itemId);
        const dataFor = modalBtn.getAttribute("data-for"); 
        console.log('dataFor', dataFor);
        
        
        const formData = new FormData();
        formData.append('id', itemId);

        const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
        const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
        const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');

        const self = this;
            fetch(postUrl, {
              method: "POST",
              headers: {
                'X-CSRF-TOKEN': csrfTokenValue
              },
              body: formData
            })
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
                // Loop through each key-value pair in the response
                console.log(data);

                if(data.success==false){
                  if(data.msg){
                    this.errorMsg = data.msg;
                  } else {
                    this.errorMsg = 'Request modal error'
                  }
                  console.log(this.errorMsg);
                }
                if(dataFor=="office-delete-confirm"){
                  if(data.success){
                      let office_row = document.querySelector(`#syslem-offices-list-id_${itemId}`); 
                      office_row.remove();
                  }
                }

                if(dataFor=="policy-delete-confirm"){
                  if(data.success){
                      let policy_row = document.querySelector(`#syslem-policy-list-id_${itemId}`); 
                      console.log('policy_row', policy_row);
                      policy_row.remove();
                      const _SystemSettingsPolicyItemRemove = new SystemSettingsPolicyItemRemove();
                      _SystemSettingsPolicyItemRemove.updateCountOfItems();

                      let itemPolicyCounter = document.querySelector(".system-settings-policies-setup-step__content .js-system-settings-setup-header-counter .value")
                      let itemPolicyes = document.querySelectorAll(".system-settings-policies-setup-step__content .system-settings-policies-setup-step-item.js-system-settings-filter-item")
                      if(itemPolicyCounter) itemPolicyCounter.innerHTML = itemPolicyes.length
                  }
                }

                if(dataFor=="card-delete-confirm"){
                  if(data.success){
                      const cardRows = document.querySelectorAll(`.syslem-cards-list-id_${itemId}`); 
                      cardRows.forEach(card => {
                        card.remove();
                      });

                      // Hide empty Office-specific cards
                      const officesRows = document.querySelectorAll(`.system-settings-payment-methods-step__card-group-wrapper`); 
                      officesRows.forEach(officeRow => {
                        
                        const cardRows = officeRow.querySelectorAll(`.system-settings-payment-methods-step-item`); 
                        if(cardRows.length==0){
                          officeRow.classList.add('d-none');
                        }
                      });
                  }
                }


                if(dataFor=="department-delete-confirm"){
                  if(data.success){
                      const departmentRows = document.querySelectorAll(`.department-list-id_${itemId}`); 
                      departmentRows.forEach(department => {
                        department.remove();
                      });

                      let department_users_total = document.querySelector(`#edit-department-users-total`);
                      if (department_users_total) {
                          let currentTotal = parseInt(department_users_total.textContent, 10);
                          let newTotal = currentTotal - 1;
        
                          department_users_total.textContent = newTotal;
                      }
                  }
                }


            })
            .catch(error => console.error('Error:', error)); // Log any errors to the console
       }
  }

  #init() {
    this.initSystemSettings()
  }
}
window.addEventListener("load", () => {
  new GetAddress()
});


export const systemSettings = new SystemSettings()
