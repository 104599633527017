export class SystemSettingsSearchList {
  constructor(inputSearch) {
    this.searchListInput = inputSearch
    if (!this.searchListInput) return
    this.searchParent = this.searchListInput.closest(".system-settings__content-main")
    if (!this.searchParent) return
    this.filteredItems = this.searchParent.querySelectorAll(".system-settings-list .js-system-settings-filter-item")
    this.filterCounter = this.searchParent.querySelector(".js-system-settings-setup-header-counter .value")

    this.init()
  }

  filterItemsBySearch = (valueForSearch) => {
    if (!this.filteredItems.length) return

    if (valueForSearch.length < 1) {
      this.filteredItems.forEach((filterItem) => {
        filterItem.classList.remove("hidden")
        this.toggleNotificationVisibility(filterItem, false)
      })
    } else {
      this.filteredItems.forEach((filterItem) => {
        const itemFilterName = filterItem.querySelector(".js-system-settings-item-title").innerHTML.toLowerCase()

        if (!itemFilterName) return

        if (itemFilterName.indexOf(valueForSearch) < 0) {
          filterItem.classList.add("hidden")
          this.toggleNotificationVisibility(filterItem, true)
        } else {
          filterItem.classList.remove("hidden")
          this.toggleNotificationVisibility(filterItem, false)
        }
      })
    }

    let counter = 0
    this.filteredItems.forEach((item) => {
      if (!item.classList.contains("hidden")) counter = counter + 1
    })
    if (this.filterCounter) this.filterCounter.innerHTML = counter
  }

  toggleNotificationVisibility(item, isVisible) {
    if (!item) return

    const itemNotification = item.parentNode.parentNode.querySelector(".notification-empty-js")
    itemNotification && (itemNotification.style.display = isVisible ? "block" : "none")
  }

  init() {
    this.filterItemsBySearch('')
    this.searchListInput.addEventListener("input", (event) => {
      this.filterItemsBySearch(event.target.value.toLowerCase())
    })
  }
}
