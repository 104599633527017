export class DistanceSwitcher {
  constructor(checkbox) {
    this.checkbox = checkbox
    this.dropdowns = {
      [checkbox.dataset.false]: document.querySelector(`.js-single-dropdown-box[data-type="${checkbox.dataset.false}"]`),
      [checkbox.dataset.true]: document.querySelector(`.js-single-dropdown-box[data-type="${checkbox.dataset.true}"]`),
    }

    this.#init()
  }

  handleSwitch = (event) => {
    const { checked, dataset: { false: option_1, true: option_2 } } = event.target
    this.dropdowns[option_1].classList[checked ? 'add' : 'remove']('hidden')
    this.dropdowns[option_2].classList[checked ? 'remove' : 'add']('hidden')
  }

  #init() {
    this.checkbox.addEventListener('change', this.handleSwitch)
  }
} 