export function observeTypeBtn() {
  const container = document.querySelector(".system-settings-guest-users-step__content")
  if (!container) return
  const dropdownWrapper = container.querySelector(".guest-users-type-select__wrapper")
  const userTypeBtn = container.querySelector(".js-observe-select .dropdown__trigger")
  const placeholderBtn = container.querySelector(".js-placeholder-btn")
  const corporateGuestsForm = container.querySelector(".guest-users-corporate-guests-form")
  const personalGuestsForm = container.querySelector(".guest-users-personal-guests-form")

  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === "childList" || mutation.type === "subtree") {
        const currentText = userTypeBtn.textContent.trim()
        const isCorporateGuest = currentText === "Corporate guest"

        placeholderBtn.classList.add("_hidden")
        dropdownWrapper.classList.toggle("_style-mask", !isCorporateGuest)
        corporateGuestsForm.classList.toggle("_hidden", !isCorporateGuest)
        personalGuestsForm.classList.toggle("_hidden", isCorporateGuest)
      }
    })
  })

  observer.observe(userTypeBtn, { childList: true, subtree: true })
}
