export class SystemSettingsPolicyItemRemove {
  constructor() {
    this.itemPolicyes = document.querySelectorAll(".system-settings-policy-manuals-step__content .system-settings-policy-step-item")
   // if(!this.itemPolicyes) return
    this.itemPolicyCounter = document.querySelector(".system-settings-policy-manuals-step__content .js-system-settings-setup-header-counter .value")
    console.log('constructor SystemSettingsPolicyItemRemove');
    this.init()
  }

  updateCountOfItems = () => {
    this.itemPolicyes = document.querySelectorAll(".system-settings-policy-manuals-step__content .system-settings-policy-step-item")
    if(this.itemPolicyCounter) {
      this.itemPolicyCounter.innerHTML = this.itemPolicyes.length
    }
    console.log('this.itemPolicyes.length', this.itemPolicyes.length);
    if (this.itemPolicyes.length > 0) {
      document.getElementById('systemSettingsZeroResult__policyManuals').classList.add("d-none")
    } else {
      document.getElementById('systemSettingsZeroResult__policyManuals').classList.remove("d-none")
    }
  }

  init = () => {
    this.updateCountOfItems()
    this.itemPolicyes && this.itemPolicyes.forEach(policyItem => {
      const removeBtnPolicy = policyItem.querySelector(".system-settings-policy-step-item__remove")
      removeBtnPolicy && removeBtnPolicy.addEventListener("click", (e) => {
        e.preventDefault();

        var url = e.target.getAttribute("data-url");
        var data = JSON.stringify({
          policyManualId: e.target.getAttribute("data-item-id")
        });

        var xhr = new XMLHttpRequest();
        xhr.open("DELETE", url, true);
        /* xhr.setRequestHeader("Content-Type", "application/json"); */

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              policyItem.remove();
              this.updateCountOfItems();
              console.log('Policy manual id deleted');
            } else {
              console.log('Error of policy manual deleting');
            }
          }
        };

        var csrfTokenInput = document.querySelector('input[name="csrf_token"]');
        var csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
        var csrfToken = csrfTokenInput ? csrfTokenInput.value : csrfTokenMeta ? csrfTokenMeta.content : '';

        xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);

        xhr.send(data);
      })
    })
  }
}

