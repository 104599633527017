import { Toast } from "../../components/toast"
import { SystemSettings } from "../system-settings/main"
import { SystemSettingsPolicyItemRemove } from "../system-settings/modules/remove-item-policy"
export class uploadFileField {
  constructor(fileInput) {
    this.uploadInput = fileInput
    if(!this.uploadInput) return
    this.uploadInputParent = this.uploadInput.closest(".js-upload-file-wrapper")
    if(!this.uploadInputParent) return
    
    this.progressFill = this.uploadInputParent.querySelector(".js-upload-file-progressline-fill")
    this.progressCounter = this.uploadInputParent.querySelector(".js-upload-file-progressline-counter")
    this.filePreviewSection = this.uploadInputParent.querySelector(".js-upload-file-preview")
    this.fileLoadSection = this.uploadInputParent.querySelector(".js-upload-file-loadbox")
    this.fileNameLabel = this.uploadInputParent.querySelector(".js-upload-file-name")
    this.fileSizeLabel = this.uploadInputParent.querySelector(".js-upload-file-size")
    this.deleteFileBtn = this.uploadInputParent.querySelector(".js-upload-file-delete-btn")
    this.nameFieldSection = this.uploadInputParent.querySelector(".js-upload-file-name-form")
    this.nameFieldSectionInputs = this.uploadInputParent.querySelectorAll(".js-upload-file-name-form input")
    this.parentUploadForm = this.uploadInput.closest(".js-upload-file-form")
    this.validStatusForm = true

    this.xhr = new XMLHttpRequest()

    this.init()
  }

  // helpers ========================================
  showLoadSection = (defaultStatus = false) => {
    if(!this.filePreviewSection || !this.fileLoadSection) return
    if(!defaultStatus){
      this.filePreviewSection.classList.remove("hide")
      this.fileLoadSection.classList.add("hide")
    }else{
      this.filePreviewSection.classList.add("hide")
      this.fileLoadSection.classList.remove("hide")
    }
  }

  showNameField = (defaultStatus = false) => {
    if(!this.nameFieldSection) return
    if(!defaultStatus){
      this.nameFieldSection.classList.add("hide")
      this.nameFieldSectionInputs && this.nameFieldSectionInputs.forEach(inputItem=> {
        inputItem.value = ''
        const event = new Event("change")
        inputItem.dispatchEvent(event)
      })
    }else{
      this.nameFieldSection.classList.remove("hide")
    }
  }

  setProgressWidth = (progressPercent = 0) => {
    if(!this.progressFill || !this.progressCounter) return
    this.progressFill.style.width = progressPercent + '%'
    this.progressCounter.innerHTML = progressPercent + '%'
    if(progressPercent == 100){
      this.progressFill.classList.add("_loaded")
    } else this.progressFill.classList.remove("_loaded")
  }

  uploadWithDelay = () => {
    const startTime = Date.now();
    const minUploadTime = 2000; // minimum time in milliseconds

    this.xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        const elapsedTime = Date.now() - startTime;
        const artificialDelay = Math.max(minUploadTime - elapsedTime, 0);
        setTimeout(() => {
          this.setProgressWidth(percentComplete);
        }, artificialDelay);
      }
    });

    this.xhr.upload.addEventListener('loadend', () => {
      this.setProgressWidth(100);
      this.showNameField(true);
    });
  }

  setInfoLabels = (fileName = "Default name.pdf", fileSize = 0) => {
    if(this.fileNameLabel) this.fileNameLabel.innerHTML = fileName
    if(this.fileSizeLabel) this.fileSizeLabel.innerHTML = (fileSize / (1024 * 1024)).toFixed(2) + " MB"
  }

  showSizeError = () => {
    this.uploadInputParent.classList.add('error-size')
    setTimeout(()=>{
      this.uploadInputParent.classList.remove('error-size')
    }, 2000)
  }

  setValidStatus = (validStatus = true, inputField) => {
    if(!inputField) return
    validStatus ? inputField.classList.remove("error") : inputField.classList.add("error")  
  }

  validateFieldOnEmpty = (inputValue, inputField) => {
    if(inputValue.length  < 1){
      this.setValidStatus(false, inputField)
    }else {
      this.setValidStatus(true, inputField)
    }
  }

  showToast = (toastMessage = "Default message") => {
    new Toast({ position: ".system-settings-content-box", text: toastMessage, type: "success" })
  }

  removeValues = () => {
    this.showLoadSection()
    this.setInfoLabels()
    this.setProgressWidth()
    this.showNameField()
    this.uploadInput.value = ''
    this.nameFieldSectionInputs && this.nameFieldSectionInputs.forEach(elInput => {
      this.setValidStatus(true, elInput.parentNode)
    })
  }
  // helpers end ======================================

  // handlers =========================================
  loadEndFileHandle = () => {
    this.setProgressWidth(100)
    this.showNameField(true)
  }

  changeFileHandle = (event) => {
    const file = event.target.files[0];
    let maxSizeInBytes = 5 * 1024 * 1024;
    if(this.uploadInput.hasAttribute("data-max-size")){
      maxSizeInBytes = parseInt(this.uploadInput.getAttribute("data-max-size")) * 1024 * 1024;
    }
    if (file && file.size < maxSizeInBytes) {
      const formData = new FormData();
      let nameForRequest = 'file_name'
      if(this.uploadInput.hasAttribute("name")) nameForRequest = this.uploadInput.getAttribute("name")
      formData.append(nameForRequest, file);
      const fileSize = file.size
      const fileName = file.name

      // Change view handlers 
      this.setProgressWidth()
      this.setInfoLabels(fileName, fileSize)
      this.showLoadSection(true)
      // Change view handlers end

      this.xhr.onprogress = (e) => {
        const percent = Math.round(e.loaded / fileSize * 100);
        if (e.loaded <= fileSize) this.setProgressWidth(percent)
      }
      this.showNameField(true)
    }else if(file && file.size > maxSizeInBytes){
      this.removeValues()
      this.showSizeError()
    } else this.removeValues()
  }

  submitFileFormHandle = (e) => {
    e.preventDefault()
    let validStatus = true
    this.nameFieldSectionInputs && this.nameFieldSectionInputs.forEach(elForm => {
      if(elForm.value.length < 1){
        validStatus = false
        const parentOfInput = elForm.parentNode
        this.validateFieldOnEmpty(elForm.value, parentOfInput)
      }
    })

    if(validStatus){ // if valid form 
      const formData = new FormData(e.target);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }
      
      this.xhr.onload = () => {
        if (this.xhr.status === 200) {
          console.log('Upload successful');
          this.removeValues();
          if (e.target.hasAttribute("data-toast")) this.showToast(e.target.getAttribute("data-toast"));
          var systemSettings = new SystemSettings();
          systemSettings.addManual(JSON.parse(this.xhr.response).data, e.target.getAttribute("data-base-url"), true);
          var systemSettingsPolicyItemRemove = new SystemSettingsPolicyItemRemove();
          systemSettingsPolicyItemRemove.updateCountOfItems();
        } else {
          console.error('Upload failed');
        }
      };

      var urlForm = e.target.getAttribute("data-url");

      this.xhr.open('POST', urlForm, true);

      // Get CSRF token from meta tag or other source
      var csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      var csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      var csrfToken = csrfTokenInput ? csrfTokenInput.value : csrfTokenMeta ? csrfTokenMeta.content : '';

      // Set CSRF token header
      this.xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);

      this.uploadWithDelay();

      this.xhr.send(formData);
    }
  }
  // handlers end =====================================

  init() {
    this.uploadInput.addEventListener('change', (event) => { this.changeFileHandle(event) }) // change file event

    this.deleteFileBtn && this.deleteFileBtn.addEventListener("click", this.removeValues) // delete file event

    this.nameFieldSectionInputs && this.nameFieldSectionInputs.forEach(elInput => { // clear errors on change
      elInput.addEventListener("input", (e) => this.setValidStatus(true, e.target.parentNode))
    })

    this.parentUploadForm && this.parentUploadForm.addEventListener("submit", (e) => {this.submitFileFormHandle(e)}) // submit form event
    this.uploadInput.addEventListener("dragenter", () => {
      this.uploadInputParent.classList.add('focus');
    });
    this.uploadInput.addEventListener("dragleave", () => {
      this.uploadInputParent.classList.remove('focus');
    });
  }
}