import { Toast } from "../components/toast"

export class ActionBtn {
  constructor(actionBtnElement) {
    this.actionBtn = actionBtnElement
    if(!this.actionBtn) return
    this.init()
  }

  toggleActionHandle = () => {
    this.actionBtn.classList.toggle("active")
    const similarBtns = document.querySelectorAll(`.js-action-toggle-btn[data-type='${this.actionBtn.getAttribute("data-type")}']`)
    let additionalClass = ''
    if(this.actionBtn.classList.contains("active")){
      similarBtns && similarBtns.forEach(similarBtn => {
        similarBtn.classList.add("active")
      })
      if(this.actionBtn.hasAttribute("data-success-message")){
        if(this.actionBtn.classList.contains("favorite-btn")) additionalClass = 'success-favorite'
        new Toast({ 
          position: ".header .header__content", 
          text: this.actionBtn.getAttribute("data-success-message"), 
          type: "success", 
          class: additionalClass
        })
      }
    }else{
      similarBtns && similarBtns.forEach(similarBtn => {
        similarBtn.classList.remove("active")
      })
      if(this.actionBtn.hasAttribute("data-error-message")){
        if(this.actionBtn.classList.contains("favorite-btn")) additionalClass = 'error-favorite'
        new Toast({ 
          position: ".header .header__content", 
          text: this.actionBtn.getAttribute("data-error-message"), 
          type: "error",
          class: additionalClass
        })
      }
    }
  }

  init() {
    this.actionBtn.addEventListener("click", () => {
      this.toggleActionHandle()
      ActionBtnCallback(this.actionBtn)
    })
  }
}

export const ActionBtnCallback = (actionBtn) => {
  if(!actionBtn) return
  let hotelID = actionBtn.getAttribute("data-hotel-id")
  let actionStatus = true
  if(actionBtn.classList.contains("active")){
    actionStatus = true
  }else actionStatus = false

  console.log("hotel ID:", hotelID, "flagStatus:", actionStatus) // for test value
  return [hotelID, actionStatus]
}