import $ from "jquery"
import { Dropdown } from "./dropdown"
import { selectOptions } from "./select.options"
import { getInputValue } from "./../../modules/getInputValue"
import { TripsSidebar } from "../../modules/sidebars/trips-sidebar"

/**
 * @extends Dropdown
 */
export class Select extends Dropdown {
  /**
   * @param { selectOptions } options
   */
  constructor(options) {
    super({ ...selectOptions, ...options })

    const $triggerValue = this.$trigger.querySelector(this.options.triggerValueSelector)
    this.$triggerValue = $triggerValue ? $triggerValue : this.$trigger

    this.$input = this.$container.querySelector(this.options.inputSelector)
    this.options = this.$dropdown.querySelectorAll(this.options.dropdownOptionSelector)

    this.isFilterStatusInput =
      this.$input.closest(".user-management-filter-panel") || this.$input.closest(".trips-filter-form-section")

    this.#init()
  }

  #init() {
    this.options.forEach(($option) => {
      $option.addEventListener("click", this.optionClickHandle.bind(this))
    })
    if (this.isFilterStatusInput) {
      this.$input.addEventListener("change", this.onChangeFiltersAndStatus)
    }
  }

  onChangeFiltersAndStatus = () => {
    console.log("filter or status was changed")
    const userFilter = this.$input.closest(".user-management-filter-panel")
    const name = this.$input.name;
    console.log("filter name", name)
    const value = this.$input.value;
    console.log("filter value", value)

    const tripsFilter = this.$input.closest(".trips-page")
    console.log("tripsFilter", tripsFilter)

    if(userFilter){
       console.log('User List filter')
       const formData = new FormData();
       formData.append('status', getInputValue(`#user_management_users_statuses`));
       formData.append('role', getInputValue(`#user_management_role_dropdown`));
       formData.append('policy', getInputValue(`#user_management_policy_dropdown`));
       formData.append('approver', getInputValue(`#user_management_approver_dropdown`));
       formData.append('office', getInputValue(`#user_management_office_dropdown`));
       formData.append('department', getInputValue(`#user_management_department_dropdown`));
       //formData.append('group', getInputValue(`#user_management_group_dropdown`));
   

       console.log('formData', formData);

      
      function getInputValue(selector) {
        let element = document.querySelector(selector);
        return element ? element.value : '';
      }

      let queryParams = new URLSearchParams();

      for (let pair of formData.entries()) {
        queryParams.append(pair[0], pair[1]);
      }
      
      let fullUrl = `${SITEURL}user-management/get-filtered-users?${queryParams.toString()}`;

      window.location.href = fullUrl;

      //  const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      //  const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      //  const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');
 
      //  const self = this;
      //     fetch(`${SITEURL}user-management/get-filtered-users`, {
      //        method: "POST",
      //        headers: {
      //          'X-CSRF-TOKEN': csrfTokenValue
      //        },
      //        body: formData
      //      })
      //      .then(response => response.json()) // Parse the JSON response
      //      .then(data => {
      //          console.log(data);

      //         })
      //      .catch(error => console.error('Error:', error)); 

    }


    if(tripsFilter){
      console.log('User List filter')
      const formData = new FormData();
      formData.append('status', getInputValue(`#trips_filter_trip`));
      formData.append('shared', getInputValue(`#trips_shared_trips`));
      formData.append('my_trips', getInputValue(`#trips_show_my_trips`));
      formData.append('stay', getInputValue(`#trips_filter_stay`));
      formData.append('guest', getInputValue(`#trips_filter_guest`));
      formData.append('booker', getInputValue(`#trips_filter_booker`));
      formData.append('approver', getInputValue(`#trips_filter_approver`));
      formData.append('date', getInputValue(`#trips_filter_date`));
      

      console.log('formData', formData);

     
     function getInputValue(selector) {
       let element = document.querySelector(selector);
       return element ? element.value : '';
     }

     let queryParams = new URLSearchParams();

     for (let pair of formData.entries()) {
       queryParams.append(pair[0], pair[1]);
     }
     
     //let fullUrl = `${SITEURL}user-management/get-filtered-users?${queryParams.toString()}`;

     //window.location.href = fullUrl;

      const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');

      const self = this;
         fetch(`${SITEURL}trips/filter`, {
            method: "POST",
            headers: {
              'X-CSRF-TOKEN': csrfTokenValue
            },
            body: formData
          })
          .then(response => response.json()) // Parse the JSON response
          .then(data => {
              console.log(data);
              updateTrips(data.trips);
              updatePagination(data.pager, data.startRecord, data.endRecord, data.total);

             })
          .catch(error => console.error('Error:', error)); 

   }


  }

  optionClickHandle(event) {
    const { currentTarget: $clickedOption } = event
    const newValue = $clickedOption.dataset?.selectValue
    const filteredValue = $clickedOption.querySelector(".js-search-filter-value")
    const hoverOption = $clickedOption.closest(".dropdown__hover-option")
    const hoverOptionText = hoverOption?.childNodes[0].textContent.trim()

    if (!newValue) {
      return console.warn("Cant append new value because value is invalid", this)
    }

    if (this.$input) {
      if (this.$input.classList.contains("datepicker-input")) {
        $(this.$input).data("dateRangePicker").clear()
      } // datepicker values
      this.$input.value = hoverOption ? `${hoverOptionText} - ${newValue}` : newValue
      const changeEvent = new Event("change")
      this.$input.dispatchEvent(changeEvent)
    }

    this.options.forEach(($option) => {
      $option.parentNode.classList.remove("_selected")
    })
    $clickedOption.parentNode.classList.add("_selected")
    this.$container.classList.add("_chosen")

    if (filteredValue) {
      this.$triggerValue.innerHTML = filteredValue.innerHTML
    } else {
      this.$triggerValue.innerHTML = hoverOption
        ? `${hoverOptionText} - ${$clickedOption.innerHTML.trim()}`
        : $clickedOption.innerHTML.trim()
    }

    this.close()
  }


}




  // Функція для оновлення списку подорожей
  function updateTrips(trips) {
      const tripsContainer = document.getElementById('trips-container'); 
      tripsContainer.innerHTML = ''; // Очистити контейнер

      const totalPrices = {};
      const tripsGrouped = {};

      // Group trips by trip_id and calculate the total cost
      trips.forEach(trip => {
          if (!tripsGrouped[trip.trip_id]) {
              tripsGrouped[trip.trip_id] = [];
          }
          tripsGrouped[trip.trip_id].push(trip);
          if (!totalPrices[trip.trip_id]) {
              totalPrices[trip.trip_id] = 0;
          }
          totalPrices[trip.trip_id] += parseFloat(trip.public_price_user_currency);
      });

  
      for (const tripId in tripsGrouped) {
        const tripGroup = tripsGrouped[tripId];
        let tripHtml = `
            <div class="table-tr">
                <div class="table-tr__button js-trips-sidebar__trigger" data-id="${tripId}">
                    <div class="table-td__container">
                        <div class="table-td">
                            <div class="text-truncate">
                                ${capitalizeFirstLetter(tripGroup[0].trip_name)}
                            </div>
                            <div>
                                Booked ${formatDate(tripGroup[0].created_at)}
                            </div>
                        </div>
                        <div class="table-td">
        `;

        tripGroup.forEach(trip => {

            let statusStyle = trip.booking_status.toLowerCase().replace(' ', '-');
            if (statusStyle === 'failed' || statusStyle === 'canceled') statusStyle = 'cancelled';
            if (statusStyle === 'success') statusStyle = 'confirmed';
            if (trip.needed_approval === '1') statusStyle = 'review-required';

            tripHtml += `
                <div class="table-td__item location">
                    <picture class="location__image">
                        <img src="${trip.hotel_image}" alt="img" class="location__img" width="54" height="54" />
                    </picture>
                    <div class="table-td__item-wrapper">
                        <div class="table-td__stay-name">
                            <div class="info-popup center table-status table-status--${statusStyle}">
                                <div class="info-popup__text">
                                    <div class="info-popup__status info-popup__status--${statusStyle}">
                                        ${statusStyle === 'review-required' ? '<svg class="icon icon-exclamation-mark "><use href="/assets/thames/default/icon/icons/icons.svg#exclamation-mark" /></svg>Booking Review required' : `Booking ${capitalizeFirstLetter(trip.booking_status)}`}
                                    </div>
                                    ${trip.hotel_name}
                                </div>
                            </div>
                            <span class="text-truncate">${trip.hotel_name}</span>
                        </div>
                        <div class="table__secondary-text">
                            ${trip.hotel_address}
                        </div>
                    </div>
                </div>
            `;
        });

        tripHtml += `</div>
            <div class="table-td">`;

        tripGroup.forEach(trip => {
            tripHtml += `
                <div class="table-td__item">
                    ${formatDate(trip.check_in_date)} – ${formatDate(trip.check_out_date)}
                    <div class="table__secondary-text">${Math.round((new Date(trip.check_out_date) - new Date(trip.check_in_date)) / (1000 * 60 * 60 * 24))}-Night Stay</div>
                </div>
            `;
        });

        tripHtml += `</div>
            <div class="table-td">`;

        tripGroup.forEach(trip => {
            tripHtml += `
                <div class="table-td__item">
                    <div class="table-td__link-wrapper">
                        <a href="#${trip.user_id}">${trip.user_first_name} ${trip.user_last_name}</a>
                        <div class="quick-invite-tabs-item quick-invite-tabs-item--alt system-settings-user-info">
                            <div class="quick-invite-tabs-item-user">
                                <div class="quick-invite-tabs-item-user__initials">
                                    <img class="user-avatar" src="/user/profile-avatar/${trip.user_id}?width=44&amp;height=44">
                                </div>
                                <div>
                                    <div>
                                        <b>${trip.user_first_name} ${trip.user_last_name}</b>
                                        <span class="quick-invite-tabs-item-user__rank">${capitalizeFirstLetter(trip.group)}</span>
                                    </div>
                                    <div class="quick-invite-tabs-item-user__email">${trip.user_email}</div>
                                </div>
                            </div>
                            <div class="quick-invite-tabs-item-company-info">
                                <div>${trip.user_department}</div>
                                <div class="quick-invite-tabs-item-company-info__company">
                                    <img src="/assets/thames/default/svg/flags/us.svg" alt="img" />
                                    ${trip.user_office_title}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table__secondary-text">
                        + ${trip.number_of_children} Guests
                    </div>
                </div>
            `;
        });

        tripHtml += `</div>
            <div class="table-td">`;

        tripGroup.forEach(trip => {
            const currencySymbol = trip.user_currency_symbol;
            const publicPrice = numberWithCommas(trip.public_price_user_currency);
            tripHtml += `
                <div class="table-td__item">
                    ${currencySymbol}${publicPrice}
                    <div class="table__secondary-text">${trip.number_of_rooms} Rooms</div>
                </div>
            `;
        });

        tripHtml += `</div>
            <div class="table-td">
                <div class="table-td__item">
                    <div class="table-td__total">
                        ${tripGroup[0].user_currency_symbol}${numberWithCommas(totalPrices[tripId])}
                    </div>
                </div>
            </div>
            <div class="table-td">
                <div class="table-td__item">
                    <button type="button" class="js-trips-sidebar__trigger btn edit-btn">
                        <svg class="icon icon-edit ">
                            <use href="/assets/thames/default/icon/icons/icons.svg#edit" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    `;

        tripsContainer.insertAdjacentHTML('beforeend', tripHtml);
    }

    let _TripsSidebar = new TripsSidebar();
}

  function updatePagination(pager, startRecord, endRecord, total) {
      //console.log(pager);
      const paginationNav = document.getElementById('pagination-box');
      paginationNav.innerHTML = pager; // Insert pagination HTML

      // Add event listeners to pagination links
      const paginationLinks = paginationNav.querySelectorAll('a.pagination-page');
      paginationLinks.forEach(link => {
          link.addEventListener('click', function(e) {
              e.preventDefault();
              const page = new URL(this.href).searchParams.get('page');
              loadPage(page);
          });
      });

      if (endRecord) {
          const paginationInfo = `
              <div class="pagination-info">
                  Showing <span class="active-items">${startRecord} - ${endRecord}</span> of ${total}
              </div>
          `;
          paginationNav.insertAdjacentHTML('beforeend', paginationInfo);
      }
  }

  // Допоміжні функції
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  function getInputValueBySelector(selector) {
      let element = document.querySelector(selector);
      return element ? element.value : '';
  }


  function loadPage(page) {

    const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
    const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
    const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');
  
    const formData = new FormData();
    formData.append('status', getInputValueBySelector(`#trips_filter_trip`));
    formData.append('shared', getInputValueBySelector(`#trips_shared_trips`));
    formData.append('my_trips', getInputValueBySelector(`#trips_show_my_trips`));
    formData.append('stay', getInputValueBySelector(`#trips_filter_stay`));
    formData.append('guest', getInputValueBySelector(`#trips_filter_guest`));
    formData.append('booker', getInputValueBySelector(`#trips_filter_booker`));
    formData.append('approver', getInputValueBySelector(`#trips_filter_approver`));
    formData.append('date', getInputValueBySelector(`#trips_filter_date`));


      formData.set('page', page);

      fetch(`${SITEURL}trips/filter`, {
          method: "POST",
          headers: {
              'X-CSRF-TOKEN': csrfTokenValue
          },
          body: formData
      })
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
          console.log(data);
          updateTrips(data.trips);
          updatePagination(data.pager, data.startRecord, data.endRecord, data.total);
      })
      .catch(error => console.error('Error:', error));
  }