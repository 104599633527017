import Sortable from "sortablejs"
import { CheckoutRoomDetails } from "./checkout/room-details"

export function sortItemsByDragging() {
  const updateSortStateHandle = (sortBox) => {
    if(!sortBox || !sortBox.hasAttribute("id")) return
    const sortItems = sortBox.children
    if(!sortItems) return
    let itemsData = []
    let formData = new FormData();

    for (let index = 0; index < sortItems.length; index++) {
      const sortItem = sortItems[index];
      const itemData = {
        data_id: sortItem.getAttribute("data-id"),
        order: index + 1
      };
      itemsData.push(itemData);
    }

    formData.append('sort_form_id', sortBox.getAttribute("id"));
    formData.append('sort_order', JSON.stringify(itemsData));

    for (let entry of formData.entries()) { // not for regular use
      console.log(entry[0] + ": " + entry[1]);
    }

    fetch('https://jsonplaceholder.typicode.com/posts', {
      method: 'POST',
      body: formData
    })
      .then(function(response) {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then(function(data) {
        // console.log(data)
      })
      ['catch'](function(error) {
        console.warn(error);
    });
  } 

  const sortableElements = document.querySelectorAll(".js-sortable")
  if (!sortableElements) return

  sortableElements.forEach((element) => {
    const sortable = new Sortable(element, {
      animation: 150,
      handle: ".js-sortable-handle",
      ghostClass: "_dragging",
      onStart: function (evt) {
        const draggedItem = evt.item

        sortable.toArray().forEach((_, index) => {
          const itemElement = sortable.el.children[index]

          if (itemElement !== draggedItem) {
            itemElement.classList.add("_drag-over")
          }
        })
      },
      onEnd: function () {
        sortable.toArray().forEach((_, index) => {
          const itemElement = sortable.el.children[index]
          itemElement.classList.remove("_drag-over")
        })

        updateSortStateHandle(element)
      },
    })
  })

  const roomGuests = document.querySelectorAll(".checkout-room-guests__box")
  if (!roomGuests) return

  roomGuests.forEach((el) => {
    const sortable = new Sortable(el, {
      group: {
        name: "checkout-rooms-guests",
      },
      animation: 150,
      handle: ".checkout-room-guests__box-item__drag",
      onEnd: function (evt) {
        // old group selectors
        const oldGroup = evt.from
        const oldGroupWrapper = oldGroup.closest(".checkout-room-guests__wrap")
        const oldItemsQty = oldGroup.querySelectorAll(".checkout-room-guests__box-item").length
        const maxOldItemsQty = oldGroupWrapper.querySelector(".checkout-room-guests__heading-amount--max").textContent
        // new group selectors
        const newGroup = evt.item.closest(".checkout-room-guests__box")
        const newGroupWrapper = evt.item.closest(".checkout-room-guests__wrap")
        const newItemsQty = newGroup.querySelectorAll(".checkout-room-guests__box-item").length
        const maxNewItemsQty = newGroupWrapper.querySelector(".checkout-room-guests__heading-amount--max").textContent

        if (oldItemsQty > Number(maxOldItemsQty)) {
          oldGroupWrapper.classList.add("_invalid")
        } else {
          oldGroupWrapper.classList.remove("_invalid")
        }

        if (newItemsQty > Number(maxNewItemsQty)) {
          newGroupWrapper.classList.add("_invalid")
        } else {
          newGroupWrapper.classList.remove("_invalid")
        }

        new CheckoutRoomDetails()
        updateSortStateHandle(el)
      },
    })
  })
}

/* Uncomment and paste to Sortable options if you want items to be inserted only at the end/beginning  */
// onMove: function (evt) {
//   if (evt.dragged.classList.contains("_primary")) {
//     evt.to.prepend(evt.dragged)
//     return false
//   }
//   if (evt.to !== evt.from) {
//     evt.to.append(evt.dragged)
//     return false
//   }
// },
