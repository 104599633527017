import { Toast } from "../../../components/toast"

export class SystemSettingsDefaultOffice {
  constructor(itemOffice) {
    this.officeSetupItem = itemOffice
    if(!this.officeSetupItem) return
    this.setDefaultBtn = this.officeSetupItem.querySelector(".system-settings-set-btn")
    if(!this.setDefaultBtn) return

    this.init()
  }

  setDefaultOffice = () => {
    const parentOfficeList = this.officeSetupItem.closest(".system-settings-list")
    if(!parentOfficeList) return
    const activeOffice = parentOfficeList.querySelector(".system-settings-offices-setup-step-item._default")
    if(!activeOffice) return
    activeOffice.classList.remove("_default")
    this.officeSetupItem.classList.add("_default")
    if(this.officeSetupItem.hasAttribute("data-toast")){
      new Toast({ position: ".system-settings-content-box", text: this.officeSetupItem.getAttribute("data-toast"), type: "success" })
    }
    if(this.officeSetupItem.hasAttribute("data-id")){
      const formData = new FormData();
      const office_id = this.officeSetupItem.getAttribute("data-id");
      formData.append('office_id', office_id);

      const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');

      const postUrl = this.officeSetupItem.getAttribute("data-url"); // Get the url
      const self = this;
      fetch(postUrl, {
        method: "POST",
        headers: {
          'X-CSRF-TOKEN': csrfTokenValue
        },
        body: formData
      })
        .then(response => response.json()) // Parse the JSON response
        .then(data => {
          
          console.log(data);

          if (data.success) {
            //console.log('office_id', office_id);
            let bnt_container =  document.querySelector('#system-settings-btn-quick-invite');
           // console.log('bnt_container', bnt_container);
            bnt_container.setAttribute("data-id", office_id);
            
          } else {
            

          }
        })
        .catch(error => {
          console.error('Error:', error);
          // self.showResponceAjaxError(error);
        }); // Log any errors to the console


      console.log('sumbited')
    }
  }

  init() {
    this.setDefaultBtn.addEventListener('click', this.setDefaultOffice)
  }
}