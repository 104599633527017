export const setInputValue = (inputSelector, nodeStatus = false, value = '', id_value = '') => {
  let inputElement
  if(nodeStatus){
    inputElement = inputSelector
  }else inputElement = document.querySelector(inputSelector)

  if(!inputElement) return

  const isIgnoreChangeValue = inputElement.getAttribute("type") === 'radio' || inputElement.getAttribute("type") === 'checkbox' || inputElement.closest(".office-dropdown") 
  let inputValue
  if(!isIgnoreChangeValue){
    if(id_value){
      inputValue = id_value
    }else inputValue = value
    inputElement.value = inputValue
  }

  console.log('type', inputElement.getAttribute("type"));

  // events
  const eventInput = new Event("input")  
  const eventClick = new Event("click")
  const eventChange = new Event("change") 

  if(inputElement.classList.contains("js-select-input")){ // js select set action
    const selectParent = inputElement.closest(".js-select")
    if(!selectParent) return
    const option = selectParent.querySelector(`.js-select-option[aria-label='${value}']`)
    if(option) option.dispatchEvent(eventClick)
  }

  if(inputElement.classList.contains("js-single-dropdown-input")){ // js single select set action
    const selectParent = inputElement.closest(".js-single-dropdown-box")
    if(!selectParent) return
    const option = selectParent.querySelector(`.js-single-dropdown-list li[data-value='${value}']`)
    if(option) {
      option.addEventListener("click", ()=> {
        option.classList.add("timeline-li")
        setTimeout(() => option.classList.remove("timeline-li"), 100)
      })
      option.dispatchEvent(eventClick)
      option.closest(".js-single-dropdown-list").dispatchEvent(eventClick) 
    }
  }

  if(inputElement.closest(".js-system-settings__dropdown-wrap")){ // js dropdowns set action
    if(!inputElement.closest(".office-dropdown")){
      const dropdown = inputElement.closest(".js-system-settings__dropdown-wrap")
      const option = dropdown.querySelector(`.js-system-settings__dropdown-list-item[data-value='${value}']`)
      if(option) {
        option.addEventListener("click", ()=> {
          option.classList.add("timeline-li")
          setTimeout(() => option.classList.remove("timeline-li"), 100)
        })
        option.dispatchEvent(eventClick)
        option.closest(".js-system-settings__dropdown-list").dispatchEvent(eventClick) 
      }
    }else{
      const inputParent = inputElement.closest(".office-dropdown")
      if(!id_value) return
      const arrayValue = id_value.replace(/\s/g, '').split(',')
      arrayValue.forEach(str => {
        const optionOffice = inputParent.querySelector(`.js-office-dropdown-list-item[data-id='${str}']`)
        if(optionOffice){
          optionOffice.addEventListener("click", ()=> {
            optionOffice.classList.add("timeline-li")
          })
          optionOffice.dispatchEvent(eventClick)
        } 
      })
      inputParent.querySelector(".js-system-settings__dropdown-list").dispatchEvent(eventClick) 
      const timelineOptions = inputParent.querySelectorAll(".timeline-li")
      timelineOptions && timelineOptions.forEach(option => option.classList.remove("timeline-li"))
    }
  }

  if(inputElement.getAttribute("type") === 'radio' || inputElement.getAttribute("type") === 'checkbox'){ // radios and checkboxes set action
     //console.log('value', value);
     // radios and checkboxes set action
     // FIX for 0/1 and True/false values
     let booleanValue;
     if (value === 0 || value === '0' || value===false || value==='false') {
         booleanValue = false;
     } else if (value === 1 || value === '1' || value===true || value==='true') {
         booleanValue = true;
     } else {
         booleanValue = !!value;
     }
    inputElement.checked = booleanValue;
    inputElement.dispatchEvent(eventChange)
  }

  if(inputElement.getAttribute("data-type") === "select_user"){ // user select set action
    const parentSearchUser = inputElement.parentNode
    if(parentSearchUser){
      setTimeout(()=>{
        const optionUser = parentSearchUser.querySelector(`.user-groups-add-users-list__item[data-value='${inputValue}']`)
        if(optionUser) optionUser.dispatchEvent(eventClick)
      }, 100)
    }
  }
  
  inputElement.dispatchEvent(eventInput)
}

// ===> EXAMPLES FOR USING <===

// setInputValue("#inputExampleID", false, 'value_test') // default input
// setInputValue("#selectExampleID", false, 'EUR') // js select input
// setInputValue("#selectExampleID", false, 'EUR') // js single select 
// setInputValue("#dropselectExampleID", false, 'Albania') // js dropdown set 
// setInputValue("#checboxExampleID", false, 'true') // checbox or radio to true value 
// setInputValue("#checboxExampleID", false, '') // checbox or radio to false value 
// setInputValue("#userSearchExampleID", false, 'Asari Dokumbo') // user search input (need to put names in value, and js code will automatic set user id in input)
// setInputValue("#officesExampleID", false, '', 'id_office_4, id_office_1, id_office_2') // office select (need to add id_value of offices like in example)