import { GuestUsersFormsHandler } from "../modules/guest-users/guest-users-forms-handler"
import { CheckboxFormValidation } from "./checkbox-form-validation"
import { ExtendedFormValidation } from "./extended-form-validation"
import FormValidation from "./form-validation"

export default function initFormValidation(forms) {
  forms.forEach((form) => {
    const { type, checkboxes } = form.dataset
    if (type) {
      if (type.startsWith("guest-users")) {
        new GuestUsersFormsHandler(form)
      } else {
        new ExtendedFormValidation(form)
      }
    } else if (checkboxes === "true") {
      new CheckboxFormValidation(form)
    } else {
      new FormValidation(form)
    }
  })
}
