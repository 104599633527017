import {
  formPhoneInputData,
  handleDropdownBackdropClick,
  initFormState,
  initValidationFieldsState,
  setDropdownsData,
  clearFields,
  handleSubmit,
  initInput,
  initTelInput,
  initDropdown,
  setCardData,
  setCheckboxesData,
  handleCheckboxChange,
} from "./helpers/helpers.js"
import { Toast } from '../../../components/toast'

class FormValidation {
  constructor(form) {
    this.form = form
    this.inputs = form.querySelectorAll(".js-system-settings-input")
    this.telInputs = form.querySelectorAll('input[type="tel"]')
    this.checkboxes = form.querySelectorAll(".js-system-settings-form__group__default-checkbox")
    this.validationInfoNode = form
      .closest(".system-settings-form__wrap")
      ?.previousElementSibling.querySelector(".js-isvalid-form-info")
    this.overflowWrapper = form.closest(".js-system-settings__content-main")
    this.dropdowns = form.querySelectorAll(".js-system-settings__dropdown-wrap")
    this.cancelBtn = form.querySelector(".js-system-settings-form-cancel")
    this.isScrollToError = !this.validationInfoNode
    this.submitBtn = form.querySelector('.js-system-settings-form__submit')

    this.formState = {}
    this.validationFieldsState = {}

    if (this.form.dataset.card) {
      this.cardInput = form.querySelector('.js-system-settings-input[data-type="card"]')
      this.cardOutput = form.querySelector(".js-card-holder")
      setCardData(this.cardInput, this.cardOutput, this)
    }

    this.validationState = null
    this.isValid = false

    this.inputs && this.inputs.length && this.inputs.forEach((input) => initFormState(input, this))
    this.telInputs.length && this.telInputs.forEach((input) => (this.formState[input.name] = formPhoneInputData(input)))
    this.dropdowns.length && setDropdownsData(this)
    this.checkboxes.length && this.checkboxes.forEach((checkbox) => setCheckboxesData(checkbox, this))

    initValidationFieldsState(this)

    this.#init()
  }


  addCsrfToken() {
    const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
    const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
    const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');


    this.csrf_token = csrfTokenValue;
  }


  // Error conteiner
  createErrorMessage = (fieldName, errorMessage) => {
    return `<span class="system-settings-form__group-field__error js-resp-error">
                ${errorMessage}
              </span>`;
  }

  showResponceAjaxError = (error) => {
    // clear old errors
    const errorElements = this.form.querySelectorAll('.js-resp-error');
    errorElements.forEach(element => {
      element.remove();
    });

    console.log(error.messages);
    // show new
    for (const fieldName in error.messages) {
      if (error.messages.hasOwnProperty(fieldName)) {
        const errorMessage = error.messages[fieldName];
        console.log('errorMessage', errorMessage);
        const msg = this.createErrorMessage(fieldName, errorMessage);
        console.log('msg', msg);
        console.log('fieldName', fieldName);
        const field = this.form.querySelector(`[name="${fieldName}"]`);
        console.log('field', field);          
        if(field){
          field.closest('div').insertAdjacentHTML('beforeend', msg);
          field.closest('.system-settings-form__group-field').classList.add('invalid');
          field.closest('div').classList.add('invalid');
        } else {
          this.showToastError(errorMessage);
        }
      }
    }
  }

  isFormValid() {
    this.isValid = this.validationState.every(item => item)
    this.validationInfoNode ? this.validationInfoNode.classList[this.isValid ? 'add' : 'remove']('valid') : null

    this.inputs && this.inputs.length && this.inputs.forEach((input) => initFormState(input, this))
    this.telInputs.length && this.telInputs.forEach((input) => (this.formState[input.name] = formPhoneInputData(input)))
    this.dropdowns.length && setDropdownsData(this)
    this.checkboxes.length && this.checkboxes.forEach((checkbox) => setCheckboxesData(checkbox, this))

    initValidationFieldsState(this)

    this.#init()

  }


  #init() {
    if (this.inputs.length) {
      this.inputs.forEach((input) => initInput(input, this))
    }

    if (this.telInputs.length) {
      this.telInputs.forEach((input) => initTelInput(input, this))
    }

    if (this.dropdowns.length) {
      this.dropdowns.forEach((dropdown) => initDropdown(dropdown, this))

      window.addEventListener("click", (event) => {
        handleDropdownBackdropClick(this, event)
      })
    }

    if (this.checkboxes.length) {
      this.checkboxes.forEach((checkbox) =>
        checkbox.addEventListener("change", (event) => handleCheckboxChange(event, this)),
      )
    }

    this.form.addEventListener("submit", (event) => handleSubmit(event, this))
    this.cancelBtn && this.cancelBtn.addEventListener("click", () => clearFields(this))
    this.addCsrfToken();
  }

  showToastError = (toastMessage = "Default message", toastStatus = "error") => {
    new Toast({ position: ".system-settings-content-box", text: toastMessage, type: toastStatus })
  }

}

export default FormValidation
