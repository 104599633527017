import { Toast } from "../../components/toast"

export class ShareFormModal {
  constructor(form) {
    this.form = form
    this.searchInput = form.querySelector('.js-modal__content-share-form__input[data-type="search"]')
    this.searchList = form.querySelector('.js-search-input__dropdown-list')
    this.searchListItems = this.searchList.querySelectorAll('.js-search-input__dropdown-item')
    this.outputList = form.querySelector('.js-modal__content-share-form__field__users-list')
    this.outputItems = this.outputList.querySelectorAll('.js-added-user-item') || null
    this.noteInput = form.querySelector('.js-modal__content-share-form__textarea')
    this.modal = this.form.closest('.hotels-share-property')
    this.selectedUsers = []
    this.selectedUsersNodes = []
    this.#init()
  }

  updateOutputItems = () => {
    this.outputItems = this.outputList.querySelectorAll('.js-added-user-item')
  }

  handleSearch = (event) => {
    const { value } = event.target
    this.searchList.classList[value.length ? 'add' : 'remove']('match')
    this.searchListItems.forEach(item => {
      const { name, email } = item.dataset
      const isMatch = name.toLowerCase().includes(value.toLowerCase()) || email.toLowerCase().includes(value.toLowerCase())
      item.classList[isMatch ? 'remove' : 'add']('hidden')
    })
  }

  handleRemoveUser = (event) => {
    const parent = event.target.closest('.js-added-user-item')
    const { name: nameToShow, userId } = parent.dataset
    this.searchListItems.forEach(item => {
      const { name } = item.dataset
      name === nameToShow ? item.classList.remove('added') : null
    })
    this.selectedUsers = this.selectedUsers.filter(item => item !== userId)
    parent && parent.remove()
  }

  handleSearchListClick = (event) => {
    event.target.classList.add('added')
    this.searchList.classList.remove('match')
    this.searchInput.value = ''
    const { name, userId } = event.target.dataset
    const markup = `
      <span class="user-name">${name}</span>
      <button type="button" class="user-remove js-user-remove">  
        <svg class="icon icon-close">
          <use href="./icon/icons/icons.svg#close"></use>
        </svg>
      </button>
    `

    const newElem = document.createElement('li')
    newElem.classList.add('modal__content-share-form__field__added-user', 'js-added-user-item')
    newElem.setAttribute("data-name", name)
    newElem.setAttribute("data-user-id", userId)
    newElem.innerHTML = markup
    this.outputList.append(newElem)
    newElem.querySelector('.js-user-remove').addEventListener('click', this.handleRemoveUser)
    this.selectedUsers.push(userId)
    this.selectedUsersNodes.push(newElem)
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.selectedUsers.length) {
      const dataForServer = {
        'share_property_modal_selected_users': this.selectedUsers,
        'share_property_modal_message': this.noteInput.value
      }
      console.log(dataForServer);
      //fetch here

      // event.target.closest('.modal').querySelector('.js-modal-close-trigger').click()

      // if(this.selectedUsers.length > 1){
      //   new Toast({ 
      //     position: ".header .header__content", 
      //     text: `Property shared with ${this.selectedUsers.length} users!`, 
      //     type: "success",
      //   })
      // }else{
      //   new Toast({ 
      //     position: ".header .header__content", 
      //     text: `Property shared with ${this.selectedUsers.length} user!`, 
      //     type: "success",
      //   })
      // }
    }
    event.target.closest('.modal').querySelector('.js-modal-close-trigger').click()
    new Toast({ 
      position: ".header .header__content", 
      text: `Property shared with ${this.selectedUsers.length} users!`, 
      type: "success",
    })
  }

  #init() {
    this.searchInput.addEventListener('input', this.handleSearch)
    this.searchList.addEventListener('click', this.handleSearchListClick)
    this.form.addEventListener('submit', this.handleSubmit)
  }
}