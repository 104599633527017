export class HotelDetailRoomFiltration {
  constructor() {
    this.availableRoomsGroup = document.querySelectorAll(".available-rooms-item")
    this.availableRoomsFilters = document.querySelectorAll(".available-rooms-section-filters input.global-default-checkbox")
    if(!this.availableRoomsGroup || !this.availableRoomsFilters) return

    this.filterOptions = {
      "refundable": "",
      "meal_option": "",
      "bed_type": []
    }

    this.#init()
  }

  fullFilterOptions = () => { // fill options object handle
    this.filterOptions = {
      "refundable": "0",
      "meal_option": '',
      "bed_type": []
    }
    let checkedOptions = []
    this.availableRoomsFilters.forEach(el => {
      if(el.checked) checkedOptions.push(el)
    })

    if(checkedOptions.length){
      checkedOptions.forEach(option => {
        const optionType = option.classList[1]
        switch(optionType) {
          case 'refundable': {
            this.filterOptions.refundable = "1"
            break
          }
          case 'meal_option': {
            this.filterOptions.meal_option = option.value
            break
          }
          case 'bed_type': {
            this.filterOptions.bed_type.push(option.value)
            break
          }
          default:
            console.log("Sorry, we are out of array");
        }
      })
    }
  }

  filterRooms = () => { // filter rooms handle
    this.availableRoomsGroup.forEach(group => {

      // remove active event btn
      const moreRoomsBtn = group.querySelector(".available-rooms-item-show-btn")
      if(moreRoomsBtn && moreRoomsBtn.classList.contains("active")){
        let event = new Event("click")
        moreRoomsBtn.dispatchEvent(event)
      }

      // filter rooms by group
      const roomsByGroup = group.querySelectorAll(".available-rooms-item-hidesection")
      if(roomsByGroup.length < 1) return 
      let counterofErros = 0
      roomsByGroup.forEach(room => {
        let isRoomValid = true
        if(this.filterOptions.refundable == 1){ // refundable filtration
          if(room.getAttribute("data-refundable") !== this.filterOptions.refundable){
            isRoomValid = false
          }
        }

        if(this.filterOptions.meal_option){ // meal_option filtration
          if(room.getAttribute("data-meal-option") !== this.filterOptions.meal_option){
            isRoomValid = false
          }
        }

        if(this.filterOptions.bed_type.length){ // bed_type filtration
          if(!this.filterOptions.bed_type.includes(room.getAttribute("data-bed-type"))){
            isRoomValid = false
          }
        }

        room.classList[isRoomValid ? 'remove' : 'add']("no-filtration-access")
        if(isRoomValid && roomsByGroup[0].classList.contains("no-filtration-access")){
          room.parentNode.prepend(room)
        }

        if(!isRoomValid) counterofErros++
      })
      

      // if group dont have filter options we will remove group
      if(roomsByGroup.length == counterofErros){
        group.classList.add("empty-room")
      }else group.classList.remove("empty-room")

      // update show more btn section
      if(moreRoomsBtn){
        let priceArray = []
        let nextCount = 0
        const rooms = group.querySelectorAll(".available-rooms-item-hidesection")
        rooms && rooms.forEach((room, index) => {
          if(index > 0 && !room.classList.contains("no-filtration-access")){
            nextCount++
            priceArray.push(room.getAttribute("data-price"))
          }
        })

        if(nextCount == 0){
          moreRoomsBtn.setAttribute("data-more-text-count", 0)
          moreRoomsBtn.setAttribute("data-more-text-price", 0)
          moreRoomsBtn.classList.add('disabled')
        }else{
          moreRoomsBtn.classList.remove('disabled')
          moreRoomsBtn.setAttribute("data-more-text-count", nextCount)
          moreRoomsBtn.setAttribute("data-more-text-price", Math.min(...priceArray))
        }

        const roomCount = moreRoomsBtn.querySelector(".room")
        const roomPrice = moreRoomsBtn.querySelector(".price") 
        if(roomCount) roomCount.innerHTML = moreRoomsBtn.getAttribute("data-more-text-count")
        if(roomPrice) roomPrice.innerHTML = moreRoomsBtn.getAttribute("data-more-text-price")
      }
    })
  }

  #init() {
    this.availableRoomsFilters.forEach((filter => {
      filter.addEventListener("change", () => {
        this.fullFilterOptions()
        this.filterRooms()
      })
    }))
  }
}