import { scrollbarLock, scrollbarUnlock } from "../../utils/scrollbar-locker"
import { accordions } from "../../../../data/accordions"
import { Accordion } from "../../components/accordions/accordion"
import { accordionOptions } from "../../components/accordions/accordion.options"

export class TripsSidebar {
  constructor() {
    this.sidebar = document.querySelector(".js-trips-sidebar")
    this.triggers = document.querySelectorAll(".js-trips-sidebar__trigger")
    this.closeBtn = document.querySelector(".js-trips-sidebar__close-btn")

    if (!this.sidebar || !this.triggers) return

    this.init()
  }

  init = () => {
    this.triggers.forEach((trigger) => trigger.addEventListener("click", this.openSidebar))
    this.closeBtn.addEventListener("click", this.closeSidebar)
    window.addEventListener("click", (event) => this.handleClickOnBg(event))
  }

  openSidebar = (event) => {
    const target = event.currentTarget;
    this.sidebar.classList.add("active");
    scrollbarLock();
    
    const tripId = target.getAttribute('data-id');
    console.log('tripId', tripId);
    this.loadTripDetails(tripId)
  }

  closeSidebar = () => {
    this.sidebar.classList.remove("active")
    scrollbarUnlock()
  }

  handleClickOnBg = (event) => {
    const { target } = event
    if (target.classList.contains("trips-sidebar")) {
      this.sidebar.classList.remove("active")
      scrollbarUnlock()
    }
  }

  loadTripDetails = (tripId) => {

      const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');

      
      const tripDatailSpan = document.getElementById('trip-datail-id');
      tripDatailSpan.innerHTML = tripId

      const self = this;
          fetch(`${SITEURL}trips/get-trip-details/${tripId}`, {
            method: "GET",
            headers: {
              'X-CSRF-TOKEN': csrfTokenValue
            }
          })
          .then(response => response.text()) // Parse the HTML response
          .then(html => {
            // Insert the received HTML into the target container
            const targetContainer = document.getElementById('trip-datail-content');
            if (targetContainer) {
              targetContainer.innerHTML = html;

              accordions.forEach((accordionItemOptions) => {
                new Accordion({ ...accordionOptions, ...accordionItemOptions })
              })
            }
           })
          .catch(error => console.error('Error:', error)); // Log any errors to the console

  } 

}
