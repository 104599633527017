/**
 * @typedef defaultModalOptions
 * @property { string | HTMLElement } container - Will get tabs container html element from DOM
 * @property { string } openTriggerSelector - Will get open trigger buttons html element from DOM
 * @property { string } closeTriggerSelector - Will get close trigger buttons html element from container
 * @property { string } openModalClass - Will be added to modal container element on open modal
 * @property { string } activeOpenTriggerClass - Will be added to open trigger buttons on open modal
 * @property { function } onOpen - Event will be called after open modal
 * @property { function } onClose - Event will be called after close modal
 * */

/** @type { defaultModalOptions } */
export const defaultModalOptions = {
  /**
   * 
   * @type string | HTMLElement
   */
  container: ".js-modal",

  /**
   * Will get open trigger buttons html element from DOM
   * @type string
   */
  openTriggerSelector: ".js-modal-open-trigger",

  /**
   * Will get close trigger buttons html element from container
   * @type string
   */
  closeTriggerSelector: ".js-modal-close-trigger",

  /**
   * Will be added to modal container element on open modal
   * @type string
   */
  openModalClass: "_show",

  /**
   * Will be added to open trigger buttons on open modal
   * @type string
   */
  activeOpenTriggerClass: "_modal-open",

  /**
   * Event will be called after open modal
   * @type function | null
   */
  onOpen: function(self) {
    console.log("Modal is open");
    console.log('self', self);
    
    //let btn = self.openTriggers[0];
    let btn = self.triggerBtn;
    //console.log('btn', btn);

    if (btn && btn.classList.contains('js-modal-open-trigger')) {
          const button = btn.closest('.load-data-btn');
          if (button) {
              // Отримуємо URL для fetch запиту
              const getUrl = button.getAttribute('data-url');
              console.log(getUrl);
              if (getUrl) {
                const itemId = button.getAttribute("data-id"); // Get the unique ID of the item
                console.log('itemId', itemId);
                const dataFor = button.getAttribute("data-for"); 
                console.log('dataFor', dataFor);
                
                // Perform a fetch request to the server
                fetch(`${getUrl}/${itemId}`)
                    .then(response => response.json()) // Parse the JSON response
                    .then(data => {
                        // Loop through each key-value pair in the response
                        console.log(data);
        
                        if(dataFor=="delete-office"){
                            // console.log(data.office);
                            let modal_del = document.querySelector(`#modal-delete-branch`);
                            modal_del.querySelector('.modal__text span').innerHTML = data.office['office_title'];
                            let modal_alert = document.querySelector(`#modal-delete-branch-cancel`);
                            modal_alert.querySelector('.modal__text span').innerHTML = data.office['office_title'];

                            
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-toast', data.office['office_title']+' office deleted!');
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-id', data.office['id']);
                        }

                        if(dataFor=="delete-policy"){
                            let modal_del = document.querySelector(`#modal-delete-policy`);
                            console.log('modal_del', modal_del);
                            modal_del.querySelector('.modal__text span').innerHTML = data.policy.name;
                            
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-toast', data.policy.name+' policy deleted!');
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-id', data.policy.policy_id);
                        }

                        if(dataFor=="delete-card"){
                            console.log(data.card);
                            let modal_del = document.querySelector(`#modal-delete-payment-method`);
                            modal_del.querySelector('.modal__text span').innerHTML = 'ending in '+data.card.last_four_digit;
                            
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-id', data.card.card_id);
                        }

                      
                        if(dataFor=="delete-department"){
                            
                            let modal_del = document.querySelector(`#modal-delete-department`);
                            console.log('modal_del', modal_del);
                            
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-toast', 'Departament '+data.department.name+' deleted!');
                            modal_del.querySelector('.modal__confirm-btn').setAttribute('data-id', data.department.id);

                        }
        
        
                    })
                    .catch(error => console.error('Error:', error)); // Log any errors to the console
               
              }
          }
      }
  
  },
  /**
   * Event will be called after close modal
   * @type function | null
   */
  onClose: null,
}
