/*
  this.validationState = {
    "name of input field": {
      length: boolean,
      uppercase: boolean,
      number: boolean
    }
  }

  this.state = {
    "name of input field": value:string
  }
*/

import {
  checkValidationStepsForPassword,
  isPasswordMatch,
  isEmptyInputs,
  isPasswordValid
} from "./helpers/stateValidation"

export default class Validation {

  constructor(HTMLFormElement, HTMLInputsArray) {
    this.form = HTMLFormElement
    this.inputs = HTMLInputsArray.map(({ $input }) => $input)
    this.fields = HTMLInputsArray.reduce((obj, field) => {
      obj[field.$input.name] = field.$field
      return obj
    }, {})

    this.errorMessage = this.form.querySelector(".js-password-error")

    this.validationType = this.form.dataset.type
    this.validationCollection = Array.from(
      document.querySelectorAll(
        `.js-validation-item[data-collection=${this.validationType}]`
      )
    )

    // STATE
    this.state = {}
    this.validationState = {}

    // FORMING STATE DATA
    this.#formValidationState()
    this.#formState()
    // END STATE

    this.isFormValid = false
    this.#init()
  }

  // state managment methods
  updateState = (input) => {
    this.state[input.name] = input.value
  }

  // validation helpers
  setIsValid = (name) => {
    this.validationCollection.forEach(item => {
      const { validation } = item.dataset
      const isValid = this.validationState[name][validation]
      if (name === 'password') {
        item.classList[isValid ? "add" : "remove"]("valid")
      }
    })
  }

  checkValid = () => {
    return Object.values(this.validationState)
      .every(value => (Object.values(value))
        .every(isPatternValid => isPatternValid))
  }

  // functions for init

  password = () => {
    this.inputs.forEach(input => {
      input.addEventListener("input", this.handlePasswordInputValidation)
    })
  }

  // end functions for init

  // handlers

  handlePasswordInputValidation = (event) => {
    this.updateState(event.target)
    const { value, name } = event.target
    if (value.length) {
      this.fields[name].classList.remove('empty')
    }
    checkValidationStepsForPassword(name, this, value)
    this.setIsValid(name)
    if(this.validationType == 'password'){
      if (name === "password_confirm") {
        const isValid = !isPasswordMatch(this.state, this) && value.length && this.state.password.length
        this.fields[name].classList[!isValid ? "remove" : "add"]("no-match")
      }
      if (name === "password") {
        const isAddClass = this.state["password_confirm"].length && !isPasswordMatch(this.state, this) && value.length
        this.fields['password_confirm'].classList[isAddClass ? "add" : "remove"]("no-match")
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    console.log(this)

    isEmptyInputs(this.state, this)

    if (!isPasswordValid(this)) {
      this.fields.password.classList.add("empty")
    }

    if (!this.checkValid()) {
      console.warn("Check conditions for strong password")
      return
    }
    if (!this.isFormValid) {
      console.warn("Passwords not mathces")
      return
    }

    if (this.form.hasAttribute('data-href')) {
      const ftpDir = '/b/travlio/'
      setTimeout(() => {
        if(window.location.origin.includes("localhost")){
          window.location.href = `/${this.form.getAttribute('data-href')}`
        }else{
          window.location.href = `${ftpDir}${this.form.getAttribute('data-href')}`
        }
      }, 500)
    } else if (this.form.hasAttribute('action') ) {
        this.form.submit();
    }
  }


  #init() {
    this.form.addEventListener("submit", this.handleSubmit)
    const funcName = this.validationType
    this[funcName]()
  }

  // helpers to forming state data
  #formValidationState = () => {
    this.inputs.forEach(input => {
      const stateObj = this.validationCollection.reduce((obj, item) => {
        obj[item.dataset.validation] = false
        return obj
      }, {})
      this.validationState[input.name] = stateObj
    })
  }

  #formState = () => {
    this.inputs.forEach(input => {
      this.state[input.name] = input.value
    })
  }
}