export class SwitchToggle {
  constructor(switcher){
    this.switcher = switcher
    if(!this.switcher) return;
    
    this.init()
  }

  changeSwitcherHandle = () => {
    console.log("this switcher element: ", this.switcher, ", and this switcher checked: ", this.switcher.checked)
  }

  init(){
    this.switcher.addEventListener("change", this.changeSwitcherHandle)
  }
}