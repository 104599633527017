import UserSearchHandler from "../handle-user-search"
import { onSearchItemClick } from "./handle-search-item-click"

export function addUserFromSearch(inputsArr) {
  inputsArr.forEach((input) => {
    const { inputSelector, listPosition } = input

    new UserSearchHandler(inputSelector, listPosition, onSearchItemClick)
  })
}
