import { setInputData } from "./_setters"
import { formPhoneInputData } from "./_data-creators"
import { resetValidationState } from "./_state-actions"
import { isFormValid } from "./_validation-actions"

const cleanInput = (input, context) => {
  setInputData(input, context)
  input.closest('.system-settings-form__group-field').classList.remove('invalid')
}

const clearTelInput = (input, context) => {
  const current = context.formState[input.name]
  context.formState[input.name] = formPhoneInputData(input)
  input.closest('.system-settings-form__group-field').classList.remove('invalid')
  current.codeSection.classList.remove('filled')
  current.flagContainer.src = current.initialIconSrc
  current.codeContainer.innerText = ''
}

const clearDropdown = (dropdown, context) => {
  const current = context.formState[dropdown.dataset.type]
  current.valueContainer.innerText = 'Select'
  current.value = ''
  current.trigger.classList.remove('filled', 'filled-currency')
  if (current.selectedItemNode) {
    current.selectedItemNode.classList.remove('selected')
    current.selectedItemNode = null
  }
  dropdown.closest('.system-settings-form__group-field').classList.remove('invalid')
}

export const clearFields = (context) => {
  context.form.reset()

  resetValidationState(context)

  context.inputs.length && context.inputs.forEach(input => {
    cleanInput(input, context)
  })

  context.telInputs.length && context.telInputs.forEach(input => {
    clearTelInput(input, context)
  })

  context.dropdowns.length && context.dropdowns.forEach(dropdown => {
    clearDropdown(dropdown, context)
  })

  isFormValid(context)
}