const searchBarSearchFunction = () => {
  let searchField = document.querySelector(".searchbar-wrapper .searchbar-searchfield")
  if(searchField) {
    let searchInput = searchField.querySelector(".searchbar-searchfield__input")
    let searchDropdown = searchField.querySelector(".searchbar-searchfield__dropdown")

    if(searchInput && searchDropdown) {
      // handle functions
      const toggleDistanceFunction = (toggleStatus) => {
        let searchParent = searchField.closest(".searchbar-content-box")
        if(!searchParent) return
        let searchbarDistance = searchParent.querySelector(".searchbar-content__distance")
        if (searchbarDistance && searchInput.classList.contains("searchbar-distance-trigger")) {
          if(!toggleStatus){
            searchbarDistance.classList.remove("active")
            let distanceRadios = searchbarDistance.querySelectorAll('.searchbar-content__distance-radio')
            if(distanceRadios){
              distanceRadios.forEach(radioDistance=>{if(radioDistance.checked) radioDistance.checked = false})
            }
          }else searchbarDistance.classList.add("active")
        }
      }

      const triggerSearchbarDatepicker = () => {
        let searchbarDatepicker = document.querySelector(".searchbar-wrapper .datepicker-input")
        setTimeout(()=>{
          let eventDatepicker = new Event("click")
          if(searchbarDatepicker) searchbarDatepicker.dispatchEvent(eventDatepicker)
        },900)
      }
      // handle functions end

      // trigger active toggle
      searchInput.addEventListener("focus", () => {searchDropdown.classList.add('active')})

      window.addEventListener('click', function (e) {
        if(!e.target.closest('.searchbar-searchfield')) searchDropdown.classList.remove('active')
      })
      // trigger active toggle end

      //search hotels catalog name trigger active toggle
      let searchFieldCatalogName = document.querySelector(".hotels-catalog-sidebar-section__content .hotels-catalog-sidebar-section__content-input")

      if (searchFieldCatalogName) {
        let searchInputCatalogName = searchFieldCatalogName.querySelector(".searchbar-searchfield__input")
        let searchDropdownCatalogName = searchFieldCatalogName.querySelector(".searchbar-searchfield__dropdown")
        console.log(searchInputCatalogName)
        console.log(searchDropdownCatalogName)
        searchInputCatalogName.addEventListener("focus", () => {searchDropdownCatalogName.classList.add('active')})

        window.addEventListener('click', function (e) {
          if(!e.target.closest('.hotels-catalog-sidebar-section__content-input')) searchDropdownCatalogName.classList.remove('active')
        })
      }
      //search hotels catalog name trigger active toggle end

      // post search value
      let searchPost = false
      let toggleStatus = false
      searchInput.addEventListener("input", function () {
        if(searchPost !== false) clearTimeout(searchPost)
  
        searchPost = setTimeout(()=>{
          // fetch to do...
          if(searchInput.value.length > 0){
            if(searchInput.hasAttribute("data-landmark")){
              toggleStatus = true
            } else toggleStatus = false 
            searchDropdown.classList.add('result-search')
          }else {
            searchInput.removeAttribute("data-landmark")
            searchDropdown.classList.remove('result-search')
            toggleStatus = false 
          }
          toggleDistanceFunction(toggleStatus)
        },500)        
      })
      // post search value end
      
      const toggleLandmarkValue = (searchOption, searchInput) => {
        if(searchOption.hasAttribute("data-landmark")){
          searchInput.setAttribute("data-landmark", searchOption.getAttribute("data-landmark"))
        }else searchInput.removeAttribute("data-landmark")
      } 

      // options event
      let searchOptions = searchDropdown.querySelectorAll(".searchbar-searchfield__dropdown-option")
      if(searchOptions) {
        searchOptions.forEach(searchOption => {
          searchOption.onclick = function (e) {
            if(e.target.closest(".searchbar-searchfield__dropdown__remove")){
              e.preventDefault()
              setTimeout(() => searchOption.remove(),100)
            }else{
              if(searchOption.hasAttribute("data-value")) {
                searchInput.value = searchOption.getAttribute("data-value")
                searchDropdown.classList.remove("active")
                toggleLandmarkValue(searchOption, searchInput)
                setTimeout(function () {
                  let eventInput = new Event("input")
                  searchInput.dispatchEvent(eventInput)
                }, 200)
                // triggerSearchbarDatepicker()
              }
            }
          }
        })
      }
      // options event end

      // clear all event
      let clearSerachListBtns = searchField.querySelectorAll(".js-searchbar-search__clear-all-trigger")
      if(clearSerachListBtns){
        clearSerachListBtns.forEach(clearAllBtn => {
          let parentBox = clearAllBtn.closest(".searchbar-searchfield__dropdown-box")
          if(parentBox){
            let optionsToRemove = parentBox.querySelectorAll(".searchbar-searchfield__dropdown-option")
            if(optionsToRemove){
              clearAllBtn.onclick = () => {
                optionsToRemove.forEach(option => {
                  option.remove()
                })
              }
            }
          }
          
        })
      }
      // clear all event end

      // other triggers event
      let searchTriggers = document.querySelectorAll(".js-searchbar-search__trigger")
      if(searchTriggers){
        searchTriggers.forEach(triggerSearchSearchbar => {
          triggerSearchSearchbar.onclick = (e) => {
            e.preventDefault()
            if(triggerSearchSearchbar.hasAttribute("data-value")){
              searchInput.value = triggerSearchSearchbar.getAttribute("data-value")
              toggleLandmarkValue(triggerSearchSearchbar, searchInput)
              let eventInput = new Event("input")
              searchInput.dispatchEvent(eventInput)
              let searchbarWrapper = searchField.closest(".searchbar-wrapper") 
              if(searchbarWrapper) {
                let headerHeight = document.querySelector(".header").offsetHeight 
                if(!searchbarWrapper.closest(".js-sticky-multiply-target")){
                  let offsetTopHeight = window.scrollY + searchbarWrapper.getBoundingClientRect().top - (headerHeight + 30)
                  window.scrollTo({
                    top: offsetTopHeight,
                    behavior: "smooth",
                  })
                }
              }
              // triggerSearchbarDatepicker()
            }
          }
        })
      }
      // other triggers event end
    }
  }
}
export {
  searchBarSearchFunction
}