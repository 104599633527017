function checkValidationStepsForPassword(fieldName, context, passwordValue = false) {
  context.validationState[fieldName].length = passwordValue.length >= 8
  context.validationState[fieldName].uppercase = /[A-Z]/.test(passwordValue)
  context.validationState[fieldName].number = /\d/.test(passwordValue)
}

function isPasswordMatch(state, context) {
  const values = Object.values(state)
  const passwordToMatch = values[0]
  const filtredValues = values.filter(value => passwordToMatch === value)
  const isMatch = values.length === filtredValues.length
  if (isMatch) {
    context.isFormValid = true
  } else {
    context.isFormValid = false
  }
  return isMatch
}

function isEmptyInputs(state, context) {
  const entries =  Object.entries(state)
  entries.forEach(entrie => {
    entrie[1].length ? null : context.fields[entrie[0]].classList.add('empty')
  })
}

function isPasswordValid(context) {
  const { length, uppercase, number } =  context.validationState.password
  return length && uppercase && number
}

export { checkValidationStepsForPassword, isPasswordMatch, isEmptyInputs, isPasswordValid }