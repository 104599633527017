export class GetAddress {
  constructor() {
    const loader = window.mapsLoader

    Array.from(document.getElementsByClassName('address_api')).forEach(
      function (element, index, array) {
        loader.load().then(({ maps }) => {
          const input = element.querySelector(".input_autocomplete_line_first");

          const options = {
            componentRestrictions: { country: "us" },
          };
          const autocomplete = new maps.places.Autocomplete(input, options);


          autocomplete.addListener("place_changed", () => {

            const places = autocomplete.getPlace();
            var city = '';
            var zip = '';
            var state = '';
            var country = '';
            if (places.address_components) {
              places.address_components.forEach((element) => {
                if (element.types.includes('postal_code')) {
                  zip = element.short_name;
                }
                else if (element.types.includes('country')) {
                  country = element.long_name;
                }
                else if (element.types.includes('locality')) {
                  city = element.short_name;
                }
                else if (element.types.includes('administrative_area_level_1')) {
                  state = element.long_name;
                }
              })
              var inputs = element.querySelectorAll('.input_autocomplete input');
              for (var i = 0; i < inputs.length; i++) {
                inputs[i].removeAttribute("disabled");
              }
            }
            element.querySelector('.input_autocomplete_city').value = city;
            element.querySelector('.input_autocomplete_state').value = state;
            element.querySelector('.input_autocomplete_zip').value = zip;
            element.querySelector('.input_autocomplete_country').value = country;

          });
        })
      }
    );
  }
}



