import { setInputData } from "../helpers/_setters"
import { removeError, isFormValid } from "../helpers/_validation-actions"
import { updateValidationState } from "../helpers/_state-actions"
import { formPhoneInputData } from "../helpers/_data-creators"
import {
  handleDialcodeChange,
  handleDropdownTriggerClick,
  handleDropdownListClick,
  handleSearch,
  handleShowPassword,
  handleInputForActivateDropdowns,
  checkValidationPasswordRules
} from "../helpers/_handlers"

export const initInput = (input, context) => {
  input.addEventListener("input", () => {
    setInputData(input, context)
    updateValidationState(context)
    input.dataset.validation && removeError(input)
    isFormValid(context)
    
    // check rules password
    if(input.dataset.type === 'password' && input.closest(".system-settings-form__group-field.js-system-settings-rules-password-field")){
      checkValidationPasswordRules(input, context.form)
    }
  })
  if(input.dataset.type === 'password' || input.dataset.type === 'confirm_password') {
    context.formState[input.name].togglePasswordBtn.addEventListener('click', (event) => handleShowPassword(event, context, input.name))
  }
  if(input.dataset.triggerName) {
    const {triggerName} = input.dataset
    const triggers = document.querySelectorAll(`button[data-trigger-name=${triggerName}]`)
    input.addEventListener('input', (event) => handleInputForActivateDropdowns(event, triggers))
  }
}

export const initTelInput = (input, context) => {
  const { name } = input
  context.formState[name] = formPhoneInputData(input)
  input.addEventListener("input", (event) => {
    context.formState[name].full_value = context.formState[name].codeValue
      ? context.formState[name].codeValue + event.target.value
      : event.target.value
    context.formState[name].value = event.target.value
    updateValidationState(context)
    removeError(input, context)
    isFormValid(context)
  })
  handleDialcodeChange(context.formState[name].codeContainer, name, context)
}

export const initDropdown = (dropdown, context) => {
  if (dropdown.dataset.noinclude === "true") return
  const { type, currency, rating } = dropdown.dataset
  const current = context.formState[type]
  current.trigger.addEventListener("click", () => handleDropdownTriggerClick(type, context))
  current.list.addEventListener("click", (event) => handleDropdownListClick(event, type, currency, rating, context))
  if (current.searchInput) {
    current.searchInput.addEventListener("input", (event) => handleSearch(event, type, context))
  }
}
