import { scrollbarLock, scrollbarUnlock } from '../../utils/scrollbar-locker'
export class HotelReviewsSidebar {
  constructor() {
    this.container = document.querySelector(".js-reviews-sidebar")
    if(!this.container) return

    this.openHotelSidebarTriggers = document.querySelectorAll('.js-trigger-hotel-reviews_sidebar')
    this.closeHotelSidebarTriggers = document.querySelectorAll('.js-hotel-reviews-sidebar__close-btn')

    this.init()
  }

  openHotelReviewsSidebar = () => { // open hotel reviews sidebar
    this.container.classList.add('active')
    scrollbarLock()
    this.openHotelSidebarTriggers && this.openHotelSidebarTriggers.forEach(trigger=>{
      trigger.classList.add("active")
    })
  }

  closeHotelReviewsSidebar = () => { // close hotel reviews sidebar
    this.container.classList.remove('active')
    scrollbarUnlock()
    this.openSettingsTriggers && this.openSettingsTriggers.forEach(trigger=>{
      trigger.classList.remove("active")
    })
  }


  outsideHotelHotelReviewsClick = (event) => { // outside close hotel reviews sidebar
    if(event.target.classList.contains("hotel-reviews-sidebar")) this.closeHotelReviewsSidebar()
  }

  init() {
    this.openHotelSidebarTriggers && this.openHotelSidebarTriggers.forEach((triggerHotelReviews) => {
      triggerHotelReviews.addEventListener('click', this.openHotelReviewsSidebar)
    })
    this.closeHotelSidebarTriggers && this.closeHotelSidebarTriggers.forEach((closeSidebar) => {
      closeSidebar.addEventListener('click', this.closeHotelReviewsSidebar)
    })

    window.addEventListener("click", this.outsideHotelHotelReviewsClick)
  }
}