import { handleItemsCount } from "../../../utils/handle-items-count"

export class SearchRecommendedStaysHandler {
  constructor(form) {
    this.form = form
    this.searchInput = this.form.querySelector(".js-system-settings-recommended-stays-search")
    this.reasonInput = this.form.querySelector(".js-reason-input")
    this.reasonDropdown = this.form.querySelector(".js-reason-dropdown")
    this.searchResultsList = this.form.querySelector(".js-system-settings-search-results")
    this.inputCounter = this.form.querySelector(".input-counter")
    this.addToListBtn = this.form.querySelector(".js-add-item-to-list-btn")
    this.dynamicInputs = this.form.querySelectorAll(".js-system-settings-dynamic-input input")
    this.itemsList = this.form.querySelector(".recommended-stays-list")
    this.itemsCounter = this.form
      .closest(".js-system-settings__content-main")
      .querySelector(".js-system-settings-setup-header-counter")

    this.init()
  }

  init() {
    handleItemsCount(this.itemsList, this.itemsCounter)
    this.updateMaxCharacters()
    this.searchInput.addEventListener("input", this.handleSearchInput.bind(this))
    this.searchInput.addEventListener("focus", this.showSearchResults)
    this.searchResultsList.addEventListener("click", (event) => this.handleSearchListClick(event))
    this.addToListBtn.addEventListener("click", this.addItem)
    this.itemsList.addEventListener("click", (event) => this.deleteItem(event))
    this.handleInvalidDynamicInput()
    document.addEventListener("click", this.handleDocumentClick.bind(this))
  }

  // handle search input
  handleSearchInput(event) {
    const inputValue = event.target.value.trim().toLowerCase()
    const searchResults = document.querySelectorAll(".js-system-settings-search-result")
    const isReasonInputVisible = this.searchInput.value.length

    if (!this.reasonDropdown && isReasonInputVisible) {
      this.reasonInput.closest(".js-system-settings-dynamic-input").classList.remove("_hidden", "invalid")
    } else {
      const event = new KeyboardEvent("keyup")
      this.reasonInput.closest(".js-system-settings-dynamic-input").classList.add("_hidden")
      this.reasonInput.value = ""
      this.reasonInput.dispatchEvent(event)
    }

    searchResults.forEach((result) => {
      const textContent = result.textContent.toLowerCase()
      if (textContent.includes(inputValue)) {
        result.classList.remove("_hidden")
      } else {
        result.classList.add("_hidden")
      }
    })

    this.showSearchResults()
  }

  // show search results
  showSearchResults = (isVisible = true) => {
    this.searchResultsList.classList[isVisible ? "remove" : "add"]("_hidden")
  }

  // handle click on search result item
  handleSearchListClick = (event) => {
    const clickedItem = event.target.closest("li")

    if (!clickedItem) return

    const property = clickedItem.querySelector(".recommended-stays-search-item__property").textContent.trim()
    this.searchInput.value = property
    this.searchInput.closest(".js-system-settings-dynamic-input").classList.remove("invalid")
    this.reasonInput.closest(".js-system-settings-dynamic-input").classList.remove("_hidden", "invalid")
    this.showSearchResults(false)
  }

  // update max characters qty
  updateMaxCharacters = () => {
    const maxLength = this.reasonInput.getAttribute("maxlength")

    this.reasonInput.onkeyup = () => {
      const currentMax = maxLength - this.reasonInput.value.length
      const isInputEmpty = currentMax == maxLength

      this.inputCounter.innerText = isInputEmpty ? `Max. ${maxLength} characters` : `Max. ${currentMax}/${maxLength}`
    }
  }

  // add item to the list
  addItem = () => {
    const dynamicSearchInput = this.searchInput.closest(".js-system-settings-dynamic-input")
    const dynamicReasonInput = this.reasonInput.closest(".js-system-settings-dynamic-input")
    // const dynamicReasonDropdown = this.reasonDropdown.closest(".js-system-settings-dynamic-input")
    // const isReasonDropdownValue = this.reasonDropdown.querySelector(".js-system-settings-dropdown-input").value.length

    // if (this.reasonDropdown && !isReasonDropdownValue) {
    //   dynamicReasonDropdown.classList.add("invalid")
    //   return
    // }

    if (this.searchInput.value.trim() === "") {
      dynamicSearchInput.classList.add("invalid")
      return
    }

    if (!dynamicReasonInput.classList.contains("_hidden") && this.reasonInput.value.trim() === "") {
      dynamicReasonInput.classList.add("invalid")
      return
    }

    this.dynamicInputs.forEach((input) => (input.value = ""))
    dynamicReasonInput.classList.add("_hidden")
    handleItemsCount(this.itemsList, this.itemsCounter)

    const maxLength = this.reasonInput.getAttribute("maxlength")
    this.inputCounter.innerText = `Max. ${maxLength} characters`
  }

  // delete item from the list
  deleteItem = (event) => {
    const clickedItem = event.target.closest(".delete-btn")

    if (!clickedItem) return

    clickedItem.closest("li").remove()
    handleItemsCount(this.itemsList, this.itemsCounter)
  }

  // remove invalid class from input when entering text
  handleInvalidDynamicInput = () => {
    this.dynamicInputs.forEach((input) => {
      input.addEventListener("input", (event) => {
        const input = event.target
        const parent = input.closest(".js-system-settings-dynamic-input")
        if (parent && parent.classList.contains("invalid")) {
          parent.classList.remove("invalid")
        }
      })
    })
  }

  // hide search list on click outside
  handleDocumentClick(event) {
    if (!event.target.closest(".recommended-stays-search-field")) {
      this.showSearchResults(false)
    }
  }
}
