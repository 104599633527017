// import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import { setAspectRatio } from "./_set-aspect-ratio"
import { buildContent,  buildPin } from "./_build-marker"

export class HotelsCatalogMapSetting {
  constructor(config) {
    this.loader = window.mapsLoader
    this.mapNode = document.querySelector(config.idElement)
    this.map = null
    this.initialMapPosition = config.initialMapPosition
    this.markersData = config.markersData
    this.assetsPath = config.assetsPath
    this.styleArray = [
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "blue",
            weight: {
              expression: "interpolate[linear, zoom, 5, 1, 15, 3]",
            },
          },
        ],
      }
    ]

    this.#init()
  }

  #MAP_ID = "15ec34ad6e921ffb"

  #init() {
    if (this.mapNode) {
      if (window.innerWidth <= 768) {
        setAspectRatio()
      }
      window.addEventListener("resize", (event) => {
        if (window.innerWidth <= 768) {
          setAspectRatio()
        }
      })

      this.loader.load().then(({ maps }) => {
        this.map = new maps.Map(this.mapNode, {
          center: this.initialMapPosition,
          zoom: 14,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_TOP,
          },
          mapId: this.#MAP_ID,
          language: "en",
          // styles: this.styleArray,
        })

        this.#addMarkers(maps)
      })
    }
  }

  #addMarkers(maps) {

    const infoWindowDiv = document.createElement('div');
    infoWindowDiv.id = "fixedInfoWindowWrap";
    infoWindowDiv.classList = "hotels-catalog-map__info-window-wrap"
    infoWindowDiv.innerHTML = '<div id="fixedInfoWindow" class="hotels-catalog-map__info-window"></div>';
    infoWindowDiv.style.display = 'none';

    this.map.controls[maps.ControlPosition.BOTTOM_CENTER].push(infoWindowDiv);

    // this.map.addListener('click', function () {
    //   infoWindow.close();
    // })

    this.map.addListener('click', function (event) {
      // console.log(event);
      // // if (!event.latLng) {
      document.querySelectorAll(".hotels-catalog-main-map__pin").forEach(item => item.classList.remove('focus'))
      document.querySelector('#fixedInfoWindow').innerHTML = ''
      // }
    });

    const markers = this.markersData.map((markerItemData, i) => {
      const markerPosition = new maps.LatLng(parseFloat(markerItemData.latitude), parseFloat(markerItemData.longitude));
      const marker = new maps.marker.AdvancedMarkerElement({
        position: markerPosition,
        content: buildPin(markerItemData),
        map: this.map
      })

      marker.addListener("click", (e) => {
        document.querySelectorAll(".hotels-catalog-main-map__pin").forEach(item => item.classList.remove('focus'))
        markers.forEach(m => m.zIndex = 1);
        marker.zIndex = 10;
        e.domEvent.target.classList.add("focus")
        // this.map.setCenter(markerPosition)
        const card = document.querySelector("#fixedInfoWindow")
        card.innerHTML = '';
        card.appendChild(buildContent(markerItemData, this.assetsPath));
        infoWindowDiv.style.display = 'block'
        card.querySelector(".js-map-info-window__close-button").addEventListener("click", (e) => {
          document.querySelectorAll(".hotels-catalog-main-map__pin").forEach(item => item.classList.remove('focus'))
          card.innerHTML = ''
        })
      })
      return marker
    })

    const renderer = { render: ({ count, position }, stats, map) => {
      const color = "#273C4E";
      const hoverColor = "#4D6275";
      const svg = (fillColor) => { return window.btoa(`
        <svg fill="${fillColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <circle cx="100" cy="100" r="70" />
          <circle cx="100" cy="100" opacity=".2" r="100" />
        </svg>`) };

      const marker = new maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg(color)}`,
          scaledSize: new maps.Size(48, 48),
        },
        label: {
          text: String(count),
          color: "rgba(255,255,255,0.9)",
          fontSize: "14px",
          fontWeight: "500"
        },
        zIndex: 1000 + count,
      });

      marker.addListener("mouseover", () => {
        marker.setIcon({
          url: `data:image/svg+xml;base64,${svg(hoverColor)}`,
          scaledSize: new maps.Size(48, 48),
        });
      });

      marker.addListener("mouseout", () => {
        marker.setIcon({
          url: `data:image/svg+xml;base64,${svg(color) }`,
          scaledSize: new maps.Size(48, 48),
        });
      });

      return marker;
    }}

    new MarkerClusterer({ markers, map: this.map, renderer, algorithmOptions: { radius: 100, minPoints: 5 } })
    // new MarkerClusterer({ markers, map: this.map, renderer, algorithmOptions: { radius: 105, minPoints: 10, maxZoom: 15 }})
  }
}

