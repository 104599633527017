export class SelectAllCheckbox {
  constructor() {
    this.container = document.querySelector(".js-select-all-container")
    if (!this.container) return
    this.selectAllCheckbox = this.container.querySelector(".js-select-all-checkbox")
    this.checkboxes = this.container.querySelectorAll(".js-select-all-item")

    this.init()
  }

  init = () => {
    this.selectAllCheckbox.addEventListener("click", () => this.toggleSelectAll())

    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("click", () => this.handleSelectAllCheckboxState())
    })
  }

  toggleSelectAll = () => {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = this.selectAllCheckbox.checked
    })
  }

  handleSelectAllCheckboxState = () => {
    const checkedCheckboxes = this.container.querySelectorAll(".js-select-all-item:checked")
    const isCheckedAll = this.checkboxes.length === checkedCheckboxes.length

    if (isCheckedAll !== this.selectAllCheckbox.checked) {
      this.selectAllCheckbox.checked = isCheckedAll
    }
  }
}
