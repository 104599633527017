export class ShowMoreDetails {
  constructor(wrap) {
    this.wrap = wrap
    this.showMoreBtn = wrap.querySelector('.js-hotel-details-property-details-info-btn')
    this.contentToShow = this.wrap.querySelector('.js-hotel-details-property-details-options-wrap')

    this.#init()
  }

  handleClick = (event) => {
    event.currentTarget.classList.toggle('active')
    this.contentToShow.classList.toggle('active')
  }

  #init() {
    this.showMoreBtn.addEventListener('click', this.handleClick)
  }
}