import { formPhoneInputData, initFormState } from "./_data-creators"
import { setDropdownsData, setCardData, setCheckboxesData } from "./_setters"

export function updateValidationState(context) {
  context.validationState = []
  const keys = Object.keys(context.validationFieldsState)
  keys.forEach((key) => {
    const currentField = context.formState[key]
    let isValid = false
    const cardNumberPattern = /^\d{4}-\d{4}-\d{4}-\d{2,4}$/
    const datePattern = /^\d{2}\s*\/\s*\d{2,}$/

    switch (currentField.type) {
      case "email":
        isValid = Boolean(currentField.value.length) && currentField.value.includes("@")
        break
      case "text":
        isValid = Boolean(currentField.value.length)
        break
      case "tel":
        isValid = Boolean(currentField.value.length) && Boolean(context.formState[key].codeValue.length)
        break
      case "dropdown":
        isValid = Boolean(currentField.input.value.length)
        break
      case "password": {
        if(currentField.parent.classList.contains("js-system-settings-rules-password-field")){
          isValid = currentField.value.length >= 8 && /[A-Z]/.test(currentField.value) && /\d/.test(currentField.value)
        }else isValid = Boolean(currentField.value.length)
        break
      }
      case "confirm_password": {
        const parentPasswordInput = context.form.querySelector(".js-system-settings-parent-password input")
        if(!parentPasswordInput) return
        isValid = parentPasswordInput.value == currentField.input.value && currentField.input.value.length > 0
        break
      }
      case "card":
        isValid = Boolean(currentField.value.match(cardNumberPattern)?.length) || false
        break
      case "date":
        isValid = Boolean(currentField.value.match(datePattern)?.length) || false
        break
      case "checkbox":
        isValid = currentField.value
        break
      case "secure_code":
        isValid = currentField.value.length === 3
        break
      case "select_user":
        isValid = !!currentField.input.dataset.id
        break
    }
    context.validationState.push(isValid)
  })
}

export function resetValidationState(context) {
  context.validationState = []
  const keys = Object.keys(context.formState)
  for (let i = 0; i < keys.length; i++) {
    context.validationState.push(false)
  }
}

export function initValidationFieldsState(context) {
  context.validationFieldsState = Object.keys(context.formState).reduce((filteredState, key) => {
    if (context.formState[key].validation) {
      return { ...filteredState, [key]: context.formState[key] }
    } else {
      return filteredState
    }
  }, {})
}

export function updateFormState(context, ...itemsToDeleteFromState) {
  itemsToDeleteFromState.length &&
    itemsToDeleteFromState.forEach((items) => {
      items.forEach((item) => {
        const fieldName = item.name || item.dataset.type
        delete context.formState[fieldName]
      })
    })
  context.inputs = context.form.querySelectorAll(".js-system-settings-input")
  context.telInputs = context.form.querySelectorAll('input[type="tel"]')
  context.dropdowns = context.form.querySelectorAll(".js-system-settings__dropdown-wrap")
  context.checkboxes = context.form.querySelectorAll(".js-system-settings-form__group__default-checkbox")
  context.telInputs.length &&
    context.telInputs.forEach((input) => {
      context.formState[input.name] = formPhoneInputData(input)
    })
  if (context.dropdowns.length) {
    setDropdownsData(context)
  }
  if (context.checkboxes.length) {
    context.checkboxes.forEach((checkbox) => setCheckboxesData(checkbox, context))
  }
  context.inputs.forEach((input) => {
    if (input.dataset.type && input.dataset.type === "card") {
      setCardData(context.cardInput, context.cardOutput, context)
    }
    initFormState(input, context)
  })
}
