export function onSearchItemClick(user) {
  const name = user.dataset.value
  const inputWrapper = user.closest(".system-settings-form__group-field__input-wrap")
  const input = inputWrapper.querySelector("input")
  const changeEvent = new Event("input")

  input.setAttribute("data-id", user.dataset.id)
  input.value = name
  input.dispatchEvent(changeEvent)
}
