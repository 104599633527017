export class DualTabSwitcher {
  constructor(buttonClass, tabClass) {
    this.buttons = document.querySelectorAll(buttonClass)
    this.tabClass = tabClass
    this.tabs = document.querySelectorAll(tabClass)

    this.#init()
  }

  handleSwitch = (event) => {
    const target = event.currentTarget
    Array.from(this.buttons).forEach(
      (el) => el.classList.remove('active')
    );
    target.classList.add('active')
    Array.from(this.tabs).forEach(
      (el) => el.classList.remove('active')
    );
    const currentTabSelector = this.tabClass + '.' + target.dataset.tab
    const currentTab = document.querySelector(currentTabSelector)
    currentTab.classList.add('active')
  }

  #init() {
    this.buttons.forEach(button => {
      button.addEventListener('click', this.handleSwitch);
    });
  }
} 