import FormValidation from "./form-validation"

import { setCheckboxGroupData, initValidationFieldsState, updateValidationState } from "./helpers/helpers"

class CheckboxFormValidation extends FormValidation {
  constructor(form) {
    super(form)
    this.checkboxesGropus = form.querySelectorAll(".js-system-settings_checkboxes-group")
    this.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, this))
    this.isScrollToError = true
    this.#init()
  }

  handleCheckboxClick = (event) => {
    const { currentTarget: checkbox } = event
    const { parentNode, name } = checkbox
    const { from } = checkbox.dataset
    const keys = Object.keys(this.formState[from])
    keys.forEach((key) =>
      key !== "type" && key !== "rateInput" && key !== name
        ? (this.formState[from][key].checkbox.checked = false)
        : null,
    )
    const inputRate =
      parentNode.classList.contains("with-input") && parentNode.querySelector(".js-system-settings__limit-input")
    this.formState[from][name].value = checkbox.checked
    if (inputRate && checkbox.checked) {
      inputRate.disabled = false
      inputRate.addEventListener("input", this.handleRateInput)
    } else {
      this.formState[from].rateInput.removeEventListener("input", this.handleRateInput)
      this.formState[from].rateInput.disabled = true
    }
  }

  handleRateInput = (event) => {
    const currentInput = event.target
    const { from, name } = currentInput.dataset
    this.formState[from][name].max_rate = currentInput.value
  }

  #init() {
    initValidationFieldsState(this)
    updateValidationState(this)
    this.checkboxesGropus.forEach((checkGroup) => {
      const { group } = checkGroup.dataset
      const keys = Object.keys(this.formState[group])
      keys.forEach(
        (key) =>
          key !== "type" &&
          key !== "rateInput" &&
          this.formState[group][key].checkbox.addEventListener("change", this.handleCheckboxClick),
      )
    })
  }
}

export { CheckboxFormValidation }
