export class SearchAvailabilityFormModal {
  constructor(form) {
    this.form = form
    if(!this.form) return
    this.#init()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.target.closest('.modal').querySelector('.js-modal-close-trigger').click()
  }

  #init() {
    this.form.addEventListener('submit', this.handleSubmit)
  }
}