export class IntegrationForms {
  constructor() {
    // ініціалізація класу
  }

  getInputElement = (key, data_step) => {
    const input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='${key}']`);
    return input;
  }

  setInputElement = (key, value, data_step) => {
    const input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='${key}']`);     
    input.value = value;
    input.setAttribute('value', value);
  }

  insertInputData = (data, data_step) => {
    //console.log(data);
    console.log('data_step', data_step);
    for (const key in data) {
      // Find the form field that matches the key and update its value
      console.log('key ', key);
      const input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='${key}']`); 
      console.log('input ', input);
      if (input) {
        input.value = data[key];
        input.setAttribute('value', data[key]);

        // Clear errors
        let input_div = input.closest('div.invalid');
          if(input_div){
            input_div.classList.remove('invalid');
          }


        // SELECTS
        if (input.classList.contains('js-system-settings-dropdown-input')) {
          console.log('SELECT');
          console.log('input', input);
          console.log('key', key);
          console.log(data[key]);
          var element = input.closest('div').querySelector(`.js-system-settings__dropdown-list-item[data-value="${data[key]}"]`);
          
          if (!element) {
            element = input.closest('div').querySelector(`.js-system-settings__dropdown-list-item[data-id="${data[key]}"]`);
            console.log('element by data-ID', element);
          }
          console.log('element', element);
          
          if (element) {
            element.classList.add('selected');
            let set_select = input.closest('div').querySelector(`span.js-system-settings__dropdown-value`);
            let trigger = input.closest('div').querySelector('.js-system-settings__dropdown-trigger');
            let select_conteiner = input.closest('div');

            //console.log('set_select', set_select)
            if (set_select) {
              //heading
              set_select.innerHTML = element.innerHTML;
              //console.log(select_conteiner.getAttribute("data-currency"));
              if (select_conteiner.getAttribute("data-currency") == 'true') {
                trigger.classList.add('filled-currency');
              } else {
                trigger.classList.add('filled');
              }
              let checkedSpan = set_select.querySelector('span.checked');
              if (checkedSpan) {
                checkedSpan.remove();
              }
              
              // Створення нової події 'input'
              let event = new Event('click', {
                bubbles: true,
                cancelable: true,
              });

              // Відправлення події до елемента
              trigger.dispatchEvent(event);
              element.dispatchEvent(event);
              
            }
          }
        }

        // Створення нової події 'input'
        let event = new Event('input', {
          bubbles: true,
          cancelable: true,
        });

        // Відправлення події до елемента
        input.dispatchEvent(event);
      }

    }
  }

  clearForm = (form) => {
    // Очищення текстових полів
    const inputs = form.querySelectorAll('input[type="text"], input[type="email"], input[type="password"], input[type="tel"]');
    inputs.forEach(input => {
      input.value = '';

      // SELECTS
      if (input.classList.contains('js-system-settings-dropdown-input')) {
        // console.log('SELECT');
        //console.log('input', input);

        let set_select = input.closest('div').querySelector(`span.js-system-settings__dropdown-value`);
        let trigger = input.closest('div').querySelector('.js-system-settings__dropdown-trigger');
        let select_conteiner = input.closest('div');

        //console.log('set_select', set_select)

        let element = input.closest('div').querySelector(`.js-system-settings__dropdown-list-item.selected`);
        let input_div = input.closest('div.invalid');
        if(input_div){
          input_div.classList.remove('invalid');
        }
        
        //console.log('element', element);
        if (element) {
          element.classList.remove('selected');
        }
        if (set_select) {
          set_select.innerHTML = "Select";
          if (select_conteiner.getAttribute("data-currency") == 'true') {
            trigger.classList.remove('filled-currency');
          } else {
            trigger.classList.remove('filled');
          }
        }
      }

      // Створення нової події 'input'
      let event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });

      // Відправлення події до елемента
      input.dispatchEvent(event);
    });

    // Скидання радіокнопок і чекбоксів до стану за замовчуванням (не вибрані)
    const checkboxes = form.querySelectorAll('input[type="checkbox"], input[type="radio"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = checkbox.defaultChecked;
    });

    // Скидання селектів до першої опції
    const selects = form.querySelectorAll('select');
    selects.forEach(select => {
      select.selectedIndex = 0;
    });

    // Очищення полів textarea
    const textareas = form.querySelectorAll('textarea');
    textareas.forEach(textarea => {
      textarea.value = '';
    });

  }


  clearUsersInputs = (inputIds) => { 
    inputIds.forEach(inputId => {
        let input = document.querySelector(inputId);
        if (input) {
            input.value = '';               // Очищення значення поля вводу
            input.removeAttribute('data-id'); // Видалення атрибуту data-id
        }
    });
  }

  setUsersInput = (inputId, approverData) => {
    let input = document.querySelector(inputId);
    if (approverData && approverData.length > 0 && input) {
        let fullName = `${approverData[0].first_name} ${approverData[0].last_name}`;
        input.value = fullName;
        input.setAttribute('data-id', approverData[0].id);
    }
  }


  createOfficeItem = (office, SITEURL) => `
  <div class="system-settings-offices-setup-step-item js-system-settings-filter-item _new ${office.is_default==1 ? '_default' : ''}" 
              data-toast="${office.office_title} set as default office!" 
              data-id="${office.id}" data-url="${SITEURL}offices/set-default"
              id="syslem-offices-list-id_${office.id}"
              >
            <div class="new-label">
              <span>New</span>
            </div>
            <div class="system-settings-offices-setup-step-item__company">
              <h3 class="system-settings-item-title js-system-settings-item-title">${office.office_title}</h3>
              <div class="system-settings-setup-step-item-control">
                <div class="system-settings-setup-step-item-control__line">
                  <span>Default</span>
                  <button class="system-settings-btn system-settings-set-btn primary-link " type="button">
                    Set as default
                  </button>
                </div>
                <button class="load-data-btn system-settings-btn primary-link js-system-settings__step-trigger" 
                      type="button" aria-label="Edit button" data-step="system-settings-office-edit" 
                      data-back-holder='Offices setup' data-id="${office.id}" 
                      data-url="${SITEURL}offices/get-office" data-for="edit-office">
                  Edit
                </button>
              </div>
            </div>
            <div class="system-settings-offices-setup-step-item__buttons-group">
              <div class="system-settings-btn system-settings-item-btn ">
                0 Users registered
              </div>
              <button class="load-data-btn btn-primary system-settings-btn system-settings-invite-btn js-system-settings__step-trigger" 
                type="button" aria-label="Add Quick invite users" data-step='system-settings-quick-invite' 
                data-back-holder='Offices setup' data-id="${office.id}" 
                data-url="${SITEURL}offices/get-office-data-inviting" data-for="user-invite">
                <svg class="icon icon-plus ">
                  <use href="/assets/thames/default/icon/icons/icons.svg#plus" />
                </svg>
                Invite users
              </button>
            </div>
          </div>
`;

  createPolicyItem = (policy, SITEURL) =>
    `
                  <div class="system-settings-policies-setup-step-item 
                  js-system-settings-filter-item _new
                  ">
                    <div class="new-label">
                        <span>New</span>
                    </div>
                    <div class="system-settings-company-setup-step-item__company">
                        <h3 class="system-settings-item-title js-system-settings-item-title">
                          ${policy.name}
                        </h3>

                        <div class="system-settings-setup-step-item-control">
                            <div class="system-settings-setup-step-item-control__line">
                            </div>

                            <button class="load-data-btn system-settings-btn primary-link js-system-settings__step-trigger" 
                                    type="button" aria-label="Edit button" 
                                    data-step="system-settings-policy-form" 
                                    data-back-holder="${GlobalCompanyName} policies"
                                    data-url="${SITEURL}policies/get-policy" 
                                    data-for="edit-policy" data-id="${policy.policy_id}" >
                                Edit
                            </button>
                        </div>
                    </div>

                    <button class="system-settings-policies-setup-step-item__btn js-system-settings__step-trigger" 
                        type="button" 
                        data-step="system-settings-office-policy-preview" 
                        data-back-holder="${GlobalCompanyName} policies">Preview</button>
                </div>
`;


insertPreviewData = (data, data_step) => {
  console.log('data_step', data_step);
  for (const key in data) {
    // Find the form field that matches the key and update its value
    const field = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .field-value-${key}`);
    console.log('field ', field);
    if (field) {
      field.innerHTML  = '';
      field.innerHTML = data[key];
    }
   
  }

}

insertDataId = (data_step, id_value) => {
  const data_id_fields = document.querySelectorAll(`.js-system-settings__content[data-step='${data_step}'] .field-value-data-id`);
  data_id_fields.forEach(data_id_field =>{
    data_id_field.setAttribute('data-id',id_value);
  })
}

insertSwitcherData = (dataStep, key, dataValue) => {

    // Знаходження всіх елементів, які потребують перемикання на основі data-step і мають відповідний клас
    const switcherElements = document.querySelectorAll(`.js-system-settings__content[data-step='${dataStep}'] .field-value-switcher-${key} span[data-value]`);
    //console.log('switcherElements', switcherElements);
    // Прохід по кожному елементу та оновлення відображення в залежності від dataValue
    switcherElements.forEach(element => {
       // console.log('data-value', element.getAttribute('data-value'));
       // console.log('element', element);
       // console.log('dataValue.toString() = ', dataValue.toString());

        if (element.getAttribute('data-value').toString() == dataValue.toString()) {
          console.log('show = ', element);
            element.classList.remove('d-none'); // Показати елемент, якщо його data-value відповідає переданому dataValue
        } else {
            element.classList.add('d-none'); // Сховати інші елементи
        }
    });

}


insertLastEditDate = (data_step, key, last_date) => {
  let last_edit = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] ${key}`);

  const date = new Date(last_date);
  
  // Форматування дати відповідно до вказаного формату
  const options = { weekday: 'short', month: 'short', day: '2-digit', year: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Форматування дня тижня і місяця
  const [weekday, month, day, year] = formattedDate.split(' ');
  const finalFormattedDate = `${weekday} ${month}. ${day.replace(',', '')} ’${year}`;

  // Отримання часу в 12-годинному форматі з PM/AM
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  // Комбінування дати і часу
  last_edit.innerHTML = `${finalFormattedDate} <span class="ch-time">${formattedTime}</span>`;
}




setApproverPreviw = (elemet_id, approverData) => {
  let container = document.querySelector(elemet_id);

  if (!container) {
    console.error("The specified container was not found!");
    return;
  }

  container.innerHTML = '';

  if (approverData && approverData.length > 0 && container) {
      let fullName = `${approverData[0].first_name} ${approverData[0].last_name}`;
      const user_item = this.createUserItem(approverData[0], SITEURL);
      let htmlContent = `
      <a class="primary-link" href="#">${fullName}</a>
      <div class="user-groups-edit-users-item__user-info">
          <div class="quick-invite-tabs-item quick-invite-tabs-item--alt">
              ${user_item}
          </div>
      </div>
      `;
      
      container.innerHTML = htmlContent;
  } else {
    container.innerHTML = '-';
  }

}


createUserItem = (user, SITEURL) => `
<div class="quick-invite-tabs-item-user">
  <div class="quick-invite-tabs-item-user__initials">
    <img class="user-avatar" src="${SITEURL}user/profile-avatar/${user.id}?width=30&height=30">
  </div>
  <div>
    <div>
      <b>${user.first_name} ${user.last_name}</b>
      <span class="quick-invite-tabs-item-user__rank">${user.group_name}</span>
    </div>
    <div class="quick-invite-tabs-item-user__email">${user.email}</div>
  </div>
</div>
<div class="quick-invite-tabs-item-company-info">
  <div>${user.department}</div>
  <div class="quick-invite-tabs-item-company-info__company">
    <img src="/assets/thames/default/svg/flags/us.svg" alt="img" />
    ${user.office_title}
  </div>
</div>
`;



setCardType = (value) => {


  // Отримати всі картки в контейнері
  const cards = document.querySelectorAll('.js-card-holder .card-container');

  // Перебрати кожну картку та приховати/показати її залежно від переданого класу
  cards.forEach(card => {
      // Додати або прибрати клас 'hidden', щоб приховати або показати картку
      if (card.classList.contains(value)) {
          card.style.display = 'block'; // показати
      } else {
          card.style.display = 'none'; // приховати
      }
  });
}


setCheckBoxElement = (key, value, data_step) => {
  const input = document.querySelector(`.js-system-settings__content[data-step='${data_step}'] .system-settings-form-step input[name='${key}']`);     
  if(value=='1'){
    value==true;
    input.setAttribute('checked', true)
  }
  if(value=='0'){
    value==false;
    input.removeAttribute('checked', true)
  }
  input.value = value;
  input.setAttribute('value', value);  

   // Створення нової події 'input'
   let event = new Event('input', {
    bubbles: true,
    cancelable: true,
  });

  // Відправлення події до елемента
  input.dispatchEvent(event);
}


insertUserPreviewData = (element, data) => {
  for (const key in data) {
    // Find the form field that matches the key and update its value
    const field = document.querySelector(`${element} .field-value-${key}`);
   // console.log('field ', field);
    if (field) {
      field.innerHTML  = '';
      field.innerHTML = data[key];
    }
   
  }
}


insertPreviewDataId = (element, id_value) => {
  const data_id_fields = document.querySelectorAll(`${element} .field-value-data-id`);
  data_id_fields.forEach(data_id_field =>{
    data_id_field.setAttribute('data-id',id_value);
  })
}

insertPreviewSwitcherData = (element, key, dataValue) => {

    // Знаходження всіх елементів, які потребують перемикання на основі data-step і мають відповідний клас
    const switcherElements = document.querySelectorAll(`${element} .field-value-switcher-${key} span[data-value]`);
    //console.log('switcherElements', switcherElements);
    // Прохід по кожному елементу та оновлення відображення в залежності від dataValue
    switcherElements.forEach(element => {
       // console.log('data-value', element.getAttribute('data-value'));
       // console.log('element', element);
       // console.log('dataValue.toString() = ', dataValue.toString());

        if (element.getAttribute('data-value').toString() == dataValue.toString()) {
         // console.log('show = ', element);
            element.classList.remove('d-none'); // Показати елемент, якщо його data-value відповідає переданому dataValue
        } else {
            element.classList.add('d-none'); // Сховати інші елементи
        }
    });
}


insertFormInputsData = (data, selector) => {
  //console.log(data);
 // console.log('selector', selector);
  for (const key in data) {
    // Find the form field that matches the key and update its value
   // console.log('key ', key);
    const input = document.querySelector(`${selector} input[name='${key}']`); 
  //  console.log('input ', input);
    if (input) {
      input.value = data[key];
      input.setAttribute('value', data[key]);

      // Clear errors
      let input_div = input.closest('div.invalid');
        if(input_div){
          input_div.classList.remove('invalid');
        }


      // SELECTS
      if (input.classList.contains('js-system-settings-dropdown-input')) {
       // console.log('SELECT');
      //  console.log('input', input);
      //  console.log('key', key);
       // console.log(data[key]);
        var element = input.closest('div').querySelector(`.js-system-settings__dropdown-list-item[data-value="${data[key]}"]`);
        
        if (!element) {
          element = input.closest('div').querySelector(`.js-system-settings__dropdown-list-item[data-id="${data[key]}"]`);
          console.log('element by data-ID', element);
        }
        console.log('element', element);
        
        if (element) {
          element.classList.add('selected');
          let set_select = input.closest('div').querySelector(`span.js-system-settings__dropdown-value`);
          let trigger = input.closest('div').querySelector('.js-system-settings__dropdown-trigger');
          let select_conteiner = input.closest('div');

          //console.log('set_select', set_select)
          if (set_select) {
            //heading
            set_select.innerHTML = element.innerHTML;
            //console.log(select_conteiner.getAttribute("data-currency"));
            if (select_conteiner.getAttribute("data-currency") == 'true') {
              trigger.classList.add('filled-currency');
            } else {
              trigger.classList.add('filled');
            }
            let checkedSpan = set_select.querySelector('span.checked');
            if (checkedSpan) {
              checkedSpan.remove();
            }
            
            // Створення нової події 'input'
            let event = new Event('click', {
              bubbles: true,
              cancelable: true,
            });

            // Відправлення події до елемента
            trigger.dispatchEvent(event);
            element.dispatchEvent(event);
            
          }
        }
      }

      // Створення нової події 'input'
      let event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });

      // Відправлення події до елемента
      input.dispatchEvent(event);
    }

  }
}


createPaymentItem = (card, baseUrl) => `
<div class="system-settings-payment-methods-step-item js-system-settings-filter-item syslem-cards-list-id_${card.card_id} _new" 
     data-id="${card.card_id}" id="syslem-cards-list-id_${card.card_id}">
  <div class="new-label">
    <span>New</span>
  </div>
  <div class="system-settings-payment-methods-step-item__left-col">
    <h3 class="system-settings-item-title js-system-settings-item-title" title=""></h3>
    <div class="system-settings-payment-methods-step-item__card-num">
      <span class="dots-lg">••••••••••••</span>
      <span class="dots-sm">••••</span>
      <span class="card-num">${card.last_four_digit}</span>
    </div>
    <div class="system-settings-payment-methods-step-item__exp-date">
      Exp. ${card.expiration_date_format}
    </div>
    <button
      class="load-data-btn primary-link system-settings-btn js-system-settings__step-trigger"
      type="button" data-step="system-settings-edit-credit-card"
      data-back-holder="Payment methods"
      data-url="${baseUrl}cards/get-card" 
      data-for="edit-card" data-id="${card.card_id}">
      Edit
    </button>
  </div>
  <div class="system-settings-payment-methods-step-item__right-col">
    <p>${card.primary_card_owner}</p>
    ${['visa', 'mastercard', 'american-express', 'jcb'].includes(card.payment_system) ? `
    <picture class="system-settings-payment-methods-step-item__image">
      <source srcset="/assets/thames/default/img/content/credit-cards/${card.payment_system}.webp" type="image/webp" class="system-settings-payment-methods-step-item__img" />
      <img src="/assets/thames/default/img/content/credit-cards/${card.payment_system}.png" alt="img" class="system-settings-payment-methods-step-item__img" width="36" height="22" />
    </picture>` : ''}
    <button class="system-settings-payment-methods-step-item__drag-btn js-sortable-handle" type="button">
      <svg class="icon icon-dots-grid">
        <use href="/assets/thames/default/icon/icons/icons.svg#dots-grid" />
      </svg>
    </button>
  </div>
</div>
`;


createDepertamentItem = (department, isNew=false) => `
    <div class="system-settings-department-list-item__heading ${isNew ? 'new' : ''} ">
      <h3 class="system-settings-item-title js-system-settings-item-title">
        ${department.name}
        <div class="new-label">
          <span>New</span>
        </div>
      </h3>
      <p>${department.description}</p>
      <hr>
      ${department.userCount > 0 ? `<a href="${SITEURL}user-management/get-filtered-users?status=&role=&policy=&approver=&office=&department=${department.id}" class="blue">View ${department.userCount} users</a>` : 'No users'}
    </div>
    <div class="system-settings-department-list-item__buttons">
      <button class="system-settings-btn primary-link system-settings-office-remove js-modal-open-trigger load-data-btn 
        ${department.userCount > 0 ? '' : 'blue'}"
        type="button" data-modal-id="modal-delete-department" aria-label="Delete" 
        data-id="${department.id}" data-url="${SITEURL}offices/get-department" data-for="delete-department"
        ${department.userCount > 0 ? 'disabled' : ''}>
        <svg class="icon icon-trash ">
          <use href="/assets/thames/default/icon/icons/icons.svg#trash"></use>
        </svg>
      </button>
    </div>
  `;
}
