export class CheckAmountOfPersons {
  constructor(wrap) {
    this.wrap = wrap
    this.childNodes = wrap.querySelectorAll('.js-available-rooms-item-sleeps-person')
    this.#init()
  }

  checkLength = () => {
    console.log(this.childNodes.length);
    return this.childNodes.length < 7
  }

  replaceHtml = () => {
    this.wrap.innerHTML = `
      <div class="available-rooms-item-sleeps-person">  
        <svg class="icon icon-person ">
          <use href="./icon/icons/icons.svg#person"></use>
        </svg>
      </div>
      <div class="available-rooms-item-sleeps-multiply">
        x ${this.childNodes.length}
      </div>
    `
  }

  #init() {
    console.log('works');
    console.log(this.childNodes);
    if(!this.checkLength()) {
      this.replaceHtml()
      this.wrap.classList.add('multiply')
    }
  }
}