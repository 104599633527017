import $ from "jquery"
export class ResetFilters {
  constructor(container) {
    this.container = container
    this.resetBtn = this.container.querySelector(".js-reset-filters-btn")
    this.filters = this.container.querySelectorAll(".js-select")

    this.init()
  }

  init = () => {
    this.resetBtn.addEventListener("click", () => this.resetFilters())
  }

  resetFilters = () => {
    this.filters.forEach((filter) => {
      const input = filter.querySelector(".js-select-input")
      const selectValue = filter.querySelector(".js-select-trigger-value")
      const selectPlaceholder = filter.querySelector(".dropdown__trigger").getAttribute("aria-label")
      const activeOption = filter.querySelector(`.js-select-dropdown ._selected`)
      const defaultActiveOption = filter.querySelector(`.js-select-dropdown .js-select-option[data-select-value='${selectPlaceholder}']`)
      const datepickerInput = filter.querySelector(".datepicker-input") 
      
      if(input.classList.contains("datepicker-input")){
        $(datepickerInput).data('dateRangePicker').clear();
      }else{
        input.value = ""
      }
      selectValue.textContent = selectPlaceholder
      filter.classList.remove("_chosen")
      if(activeOption) activeOption.classList.remove("_selected")
      if(defaultActiveOption) {
        defaultActiveOption.parentNode.classList.add("_selected")
        input.value = defaultActiveOption.getAttribute("data-select-value")
      }
      const event = new Event("change")
      input.dispatchEvent(event)
    })
  }
}
