import { systemSettings } from "../../main"
import { Toast } from "../../../../components/toast"

export const redirectBySubmit = (context) => {
  const { form } = context
  if (form.hasAttribute("data-redirect-current")) {
    if (form.hasAttribute("data-redirect-back-holder") && form.hasAttribute("data-redirect-form-return")) {
      systemSettings.redirectTargetAction(form.getAttribute("data-redirect-current"), form.getAttribute("data-redirect-back-holder"), form.getAttribute("data-redirect-form-return"))
    } else {
      systemSettings.redirectTargetAction(form.getAttribute("data-redirect-current"))
    }
  } else if (form.hasAttribute("data-redirect-step")) {
    systemSettings.redirectAction(form.getAttribute("data-redirect-step"), form.getAttribute("data-toast"))
  }

  if (form.dataset.from && form.dataset.from === 'user-settings') {
    const { goto, toastMessage, toastStatus } = form.dataset
    document.querySelectorAll('.js-user-settings-step').forEach(step => {
      const isCurrent = step.dataset.step === goto
      step.classList[isCurrent ? 'add' : 'remove']('active')
      new Toast({ position: ".users-settings-content-box", text: toastMessage, type: toastStatus })
    })
  }
}