export class CheckoutModalForm {
  constructor(form) {
    this.form = form
    this.checkbox = form.querySelector('.js-modal__reservation-form-checkbox')
    this.rectangle = this.checkbox && this.checkbox.closest('.modal__reservation-checkbox-wrap').querySelector('.modal__reservation-checkbox-box')
    this.closeBtn = form.closest('.js-modal').querySelector('.js-modal-close-trigger')
    this.textarea = form.querySelector('.js-modal__reservation-form-textarea')

    this.#init()
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if(this.checkbox && this.checkbox.checked || this.textarea && this.textarea.value.length) {
      this.closeBtn.click()
    } 
    if(this.checkbox && !this.checkbox.checked) {
      this.rectangle.classList.add('error')
    }
    if(this.textarea && !this.textarea.value.length) {
      this.textarea.classList.add('error')
    }
  }

  handleCheck = (event) => {
    if(this.checkbox) {
      const isRemoveError = this.rectangle.classList.contains('error') && event.currentTarget.checked
      isRemoveError && this.rectangle.classList.remove('error') 
    }
  }

  handleInput = (event) => {
    const isRemoveError = event.target.value.length && event.target.classList.contains('error')
    isRemoveError && event.target.classList.remove('error')
  }

  #init() {
    this.form.addEventListener('submit', this.handleSubmit)
    this.checkbox && this.checkbox.addEventListener('change', this.handleCheck)
    this.textarea && this.textarea.addEventListener('input', this.handleInput)
  }
}