export class ReviewRequired {
  constructor(wrap) {
    this.dropdown = this.formDropdownData(wrap.querySelector('.js-review-required-heading-section__dropdown-wrap'))
    this.listToFilter = wrap.querySelectorAll('.js-review-required-list-item')
    this.#init()
  }

  formDropdownData = (wrap) => {
    return {
      wrapper: wrap,
      input: wrap.querySelector('.js-review-required-heading-section__dropdown-input'),
      trigger: wrap.querySelector('.js-review-required-heading-section__dropdown-trigger'),
      valueContainer: wrap.querySelector('.js-review-required-heading-section__dropdown-trigger__value'),
      list: wrap.querySelector('.js-review-required-heading-section__dropdown-list')
    }
  }

  filterItems = (filter) => {
    this.listToFilter.forEach(item => {
      if(filter === 'all_bookings') {
        item.classList.remove('hidden')
      } else {
        const isItemToShow = item.dataset.filter === filter 
        item.classList[isItemToShow ? 'remove' : 'add']('hidden') 
      }
    })
    this.setLastVisibleItem()
  }

  setLastVisibleItem = () => {
    this.listToFilter.forEach(item => item.classList.remove('last-visible'))
    let lastVisibleItem = [...this.listToFilter].reduce((lastItem, item) => {
      return !item.classList.contains('hidden') ? item : lastItem
    }, null)
    lastVisibleItem.classList.add('last-visible')
  }

  handleTriggerClick = (event, list) => {
    event.target.classList.toggle('active')
    list.classList.toggle('active')
  }

  handleListClick = (event, trigger, valueContainer, input, list) => {
    const { value, text } = event.target.dataset
    list.classList.remove('active')
    trigger.classList.remove('active')
    valueContainer.innerText = text
    input.value = value
    const eventChange = new Event("change")
    input.dispatchEvent(eventChange)
    list.querySelectorAll('li').forEach(item => item.classList.remove('selected'))
    event.target.classList.add('selected')
    this.filterItems(value)
  }

  initDropdown = ({ input, trigger, valueContainer, list }) => {
    trigger.addEventListener('click', (event) => this.handleTriggerClick(event, list))
    list.addEventListener('click', (event) => this.handleListClick(event, trigger, valueContainer, input, list))
    window.addEventListener('click', (event) => {
      if(!event.target.closest('.js-review-required-heading-section__dropdown-wrap')) {
        trigger.classList.remove('active')
        list.classList.remove('active')
      }
    })
  }

  changeFilterReviewFunction = () => {
    console.log("review required filter was changed")
  }

  #init() {
    this.dropdown && this.initDropdown(this.dropdown)
    this.dropdown.input.addEventListener("change", this.changeFilterReviewFunction)
  }
}