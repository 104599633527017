export class PriceRangePicker {
  constructor(wrapper, toggler) {
    this.wrapper = wrapper
    this.sectionWrap = this.wrapper.closest('.hotels-catalog-sidebar-section__content')
    this.toggler = toggler
    this.togglerCheckbox = toggler.querySelector("#nightly_rate_set_custom_budget")
    this.minValueInput = wrapper.querySelector(".range-slider__min-value")
    this.maxValueInput = wrapper.querySelector(".range-slider__max-value")
    this.minTooltip = wrapper.querySelector(".tooltip-min")
    this.maxTooltip = wrapper.querySelector(".tooltip-max")
    this.rangeTrack = wrapper.querySelector(".range-slider__track")
    this.minValue = parseInt(this.minValueInput.min)
    this.maxValue = parseInt(this.maxValueInput.max)
    this.sliderWidth = parseFloat(getComputedStyle(this.wrapper).getPropertyValue("width"))
    this.rangeTrackWidth = parseFloat(getComputedStyle(this.rangeTrack).getPropertyValue("width"))
    this.minTooltipWidth = parseFloat(getComputedStyle(this.minTooltip).getPropertyValue("width"))
    this.maxTooltipWidth = parseFloat(getComputedStyle(this.maxTooltip).getPropertyValue("width"))
    this.minGap = 0
    this.statsWrap = wrapper.querySelector(".range-slider__bg-stats")
    this.stats = null
    this.statsArray = this.#splitIntoArray(33)
    this.maxStat = this.statsArray.toSorted((a, b) => b - a)[0]

    this.#init()
  }

  handleMinInput = () => {
    let gap = parseInt(this.maxValueInput.value - this.minValueInput.value)
    if (gap <= this.minGap) {
      this.minValueInput.value = parseInt(this.maxValueInput.value) - this.minGap
      return
    }
    this.minTooltip.innerHTML = `$${this.minValueInput.value}`

    this.setArea()
  }
  handleMaxInput = () => {
    let gap = parseInt(this.maxValueInput.value - this.minValueInput.value)
    if (gap <= this.minGap) {
      this.maxValueInput.value = parseInt(this.minValueInput.value) + this.minGap
      return
    }
    this.maxTooltip.innerHTML = `$${this.maxValueInput.value}`

    this.setArea()
  }

  setArea = () => {
    const leftPos =
      ((this.minValueInput.value - this.minValue) / (this.maxValue - this.minValue)) * (this.sliderWidth - 7.5 - 7.5) +
      7.5
    const leftMaxInputPos =
      ((this.maxValueInput.value - this.minValue) / (this.maxValue - this.minValue)) * (this.sliderWidth - 7.5 - 7.5) +
      7.5
    const rightMaxPos = this.sliderWidth
    const rightPos = rightMaxPos - leftMaxInputPos

    const minTooltipRight = leftPos + this.minTooltipWidth
    const maxTooltipLeft = leftMaxInputPos
    if (minTooltipRight >= maxTooltipLeft) {
      this.minTooltip.classList.add("connect")
      this.maxTooltip.classList.add("connect")
    } else {
      this.minTooltip.classList.remove("connect")
      this.maxTooltip.classList.remove("connect")
    }

    this.rangeTrack.style.left = `${leftPos}px`
    this.minTooltip.style.left = `${leftPos - this.minTooltipWidth / 2}px`
    this.rangeTrack.style.right = `${rightPos}px`
    this.maxTooltip.style.right = `${rightPos - this.maxTooltipWidth / 2}px`

    this.stats.forEach(this.isStatBetweenRange)
  }

  isStatBetweenRange = (stat) => {
    const styles = stat.getBoundingClientRect()
    const minTooltipStyles = this.minTooltip.getBoundingClientRect()
    const maxTooltipStyles = this.maxTooltip.getBoundingClientRect()
    if (
      minTooltipStyles.right - minTooltipStyles.width / 2 <= styles.left &&
      maxTooltipStyles.left + maxTooltipStyles.width / 2 >= styles.right
    ) {
      stat.classList.add("in-range")
    } else {
      stat.classList.remove("in-range")
    }
  }

  scale = (num, in_min, in_max, out_min, out_max) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  }

  setMinGap = () => {
    const widthPerPoint = this.sliderWidth / this.maxValue
    this.minGap = Math.floor(this.maxTooltipWidth / widthPerPoint)
  }

  #init() {
    this.togglerCheckbox.addEventListener("change", (event) => {
      const { checked } = event.target
      this.wrapper.classList[checked ? 'add' : 'remove']('active')
    })

    this.statsArray.forEach((item) => {
      const statItem = document.createElement("div")
      statItem.classList.add("stat-col")
      const itemHeight = (Math.abs(item - this.maxStat) / this.maxStat) * 100
      statItem.style.height = `${itemHeight}%`
      this.statsWrap.append(statItem)
    })

    this.stats = this.wrapper.querySelectorAll(".stat-col")

    this.setMinGap()
    this.handleMinInput(null)
    this.handleMaxInput(null)
    this.minValueInput.addEventListener("input", this.handleMinInput)
    this.maxValueInput.addEventListener("input", this.handleMaxInput)
  }

  #splitIntoArray = (arrLength) => {
    let array = Array(arrLength).fill(0)

    // Generate random numbers and distribute them in the array
    for (let i = 0; i < arrLength; i++) {
      if (i === arrLength - 1) {
        // Ensure the last element of the array does not exceed the remaining sum
        array[i] = Math.max(1, 100 - array.reduce((acc, cur) => acc + cur, 0))
      } else {
        // Generate a random number between 1 and 20
        let randomNum = Math.floor(Math.random() * 20) + 1
        // Adjust the random number if it would exceed the remaining sum
        let remainingSum = 100 - array.reduce((acc, cur) => acc + cur, 0)
        if (remainingSum < 20 && remainingSum > 1) {
          randomNum = Math.min(randomNum, remainingSum)
        }
        array[i] = randomNum
      }
    }

    return array
  }
}
