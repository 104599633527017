const DEFAULT_OPTIONS = {
  autoClose: 3000,
  position: "body",
  icon: null,
  isRemoveBtn: false,
}

export class Toast {
  #toastElem
  #autoCloseTimeout
  #toastType
  #toastClass
  #iconClass
  #isRemoveBtn
  #removeBtn

  constructor(options) {
    this.checkAndRemoveExistingToast()
    this.#isRemoveBtn = typeof options.isRemoveBtn === "boolean" && options.isRemoveBtn
    this.#toastClass = options.class ? options.class : ""
    this.#toastType = options.type ? options.type : "common"
    this.#iconClass = options.icon
    this.#toastElem = document.createElement("div")
    this.#toastElem.classList.add("toast", this.#toastType)

    if (this.#toastClass) this.#toastElem.classList.add(this.#toastClass)
    this.#iconClass && this.#toastElem.classList.add(this.#iconClass)
    requestAnimationFrame(() => {
      this.#toastElem.classList.add("_show")
    })

    Object.entries({ ...DEFAULT_OPTIONS, ...options }).forEach(([key, value]) => {
      this[key] = value
    })
  }


  set autoClose(value) {
    if (value === false) return
    if (this.#autoCloseTimeout != null) clearTimeout(this.#autoCloseTimeout)

    this.#autoCloseTimeout = setTimeout(() => this.remove(), value)
  }

  set position(value) {
    const container = document.querySelector(value)
    container.append(this.#toastElem)
  }

  set text(value) {
    this.#toastElem.textContent = value
    this.addRemoveBtn()
  }

  remove() {
    this.#toastElem.classList.remove("_show")
    this.#toastElem.addEventListener("transitionend", () => {
      this.#toastElem.remove()
    })
  }

  checkAndRemoveExistingToast() {
    const existingToast = document.querySelector(".toast")
    if (existingToast) {
      existingToast.remove()
    }
  }

  addRemoveBtn() {
    if (this.#isRemoveBtn) {
      this.#removeBtn = document.createElement("button")
      this.#removeBtn.classList.add("toast-remove-btn")

      this.#removeBtn.addEventListener("click", () => {
        this.remove()
      })

      this.#toastElem.append(this.#removeBtn)
    } else {
      return
    }
  }
}
