export class HotelsDropdown {
  constructor(wrapper) {
    this.wrapper = wrapper
    this.trigger = wrapper.querySelector('.js-single-dropdown-trigger')
    this.triggerText = this.trigger.querySelector('.trigger-text')
    this.list = wrapper.querySelector('.js-single-dropdown-list')
    this.input = wrapper.querySelector('.js-single-dropdown-input')
    this.currentSelected = this.list.querySelector('.hotels-catalog-sidebar-section__dropdown-item.active') || null

    this.#init()
  }

  handleTriggerClick = (event) => {
    event.currentTarget.classList.toggle('active')
  }

  handleBackdropClick = (event) => {
    if (!event.target.closest('.js-single-dropdown-box')) {
      this.trigger.classList.remove('active')
    }
  }

  handleItemClick = (event) => {
    const timelineLI = event.target.querySelector(".timeline-li")
    if (event.target.nodeName !== "LI" && !timelineLI) return

    const eventInput = new Event("input")
    let currentLi
    if(timelineLI){
      currentLi = timelineLI
    }else currentLi = event.target

    const { value } = currentLi.dataset
    this.trigger.dataset.selected = value
    this.triggerText.innerText = value
    this.input.value = value
    this.currentSelected && this.currentSelected.classList.remove('active')
    this.currentSelected = currentLi
    currentLi.classList.add('active')
    this.trigger.classList.remove('active')
    this.input.dispatchEvent(eventInput)
  }

  #init() {
    this.trigger.addEventListener('click', this.handleTriggerClick)
    this.list.addEventListener('click', this.handleItemClick)
    window.addEventListener('click', this.handleBackdropClick)
  }
}