export const changeCheckoutNameHandle = (e) => {
  if(!e) return
  const valueField = e.target
  if(!valueField) return

  if(valueField.innerHTML.length < 1){
    valueField.innerHTML = ''
  }

  console.log(valueField.innerHTML)
  return valueField.innerHTML
}

export const submitCheckoutHandle = () => {
  const checkoutContentForm = document.querySelector(".checkout-page .checkout");
  if (!checkoutContentForm) return;

  let validState = true;
  
  // Users data
  let roomsUserData = [];
  const checkoutRooms = checkoutContentForm.querySelectorAll('.checkout-room-wrap');
  checkoutRooms && checkoutRooms.forEach(checkoutRoom => {
    const userWrapWithError = checkoutRoom.querySelector('.checkout-room-guests__wrap._invalid');
    if (userWrapWithError) validState = false;

    let userRoomData = [];
    const userListByRoom = checkoutRoom.querySelectorAll(".checkout-room-guests__box-item");

    userListByRoom && userListByRoom.forEach(user => {
      userRoomData.push(user.getAttribute("data-user-id"));
    });
    roomsUserData.push(userRoomData);
  });

  if (validState) {
    let dataForm = new FormData();

    // Append the guests_detail array as a JSON string to the FormData object
    dataForm.append('guests_detail', JSON.stringify(roomsUserData));

    // Perform the fetch request
    fetch('https://jsonplaceholder.typicode.com/posts', {
      method: 'POST',
      body: dataForm
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(function(data) {
      console.log(data);
    })
    .catch(function(error) {
      console.warn(error);
    });
  }
}