import { formInputData } from "./helpers"

export default class TelInput {
  constructor(inputs, shouldInit = true) {
    this.data = Array.from(inputs).map(formInputData)
    this.baseFlagSrc = '/assets/thames/default/svg/flags/'
    this.defaultPhoneIconSrc = '/assets/thames/default/svg/phone.svg'
    if (shouldInit) {
      this.#init()
    }
    this.isInputsValid = false
  }

  updatePhoneNumber = (data) => {
    data.fullPhone = data.codeValue + data.value
  }

  handleTriggerClick = (event, dropdown, dropdownTrigger) => {
    this.data.forEach(({ dropdown: dropdownList, dropdownTrigger: dropdownTriggerList }) => {
      if (dropdownList !== dropdown) {
        dropdownList.classList.remove("active")
      }
      if (dropdownTrigger !== dropdownTriggerList) {
        dropdownTriggerList.classList.remove("active")
      }
    })
    dropdown.classList.toggle("active")
    dropdownTrigger.classList.toggle("active")

    if (dropdown.classList.contains("active")) {
      window.addEventListener("click", (event) => this.handleBackdropClick(event, dropdown, dropdownTrigger))
    } else {
      window.removeEventListener("click", this.handleBackdropClick)
    }
  }

  handleBackdropClick = (event, dropdown, dropdownTrigger) => {
    if (
      event.target.closest(".tel-input-dropdown") ||
      event.target === dropdown ||
      event.target.closest(".code-section") ||
      event.target === dropdownTrigger
    ) {
      return
    } else {
      dropdown.classList.remove("active")
      dropdownTrigger.classList.remove("active")
    }
  }

  handleDropdownItemClick = (event, dropdown, dropdownTrigger, flag, code, data) => {
    const { nodeName } = event.target
    const isLiClicked = nodeName === "LI"
    const { country_code, dial_code } = isLiClicked ? event.target.dataset : event.target.closest("li").dataset

    const input = dropdownTrigger.nextElementSibling
    const changeEvent = new Event("input")

    if (event.target.closest("li")) {
      const activeOption = dropdown.querySelector(".tel-input-dropdown__list-item.active")
      if (activeOption) activeOption.classList.remove("active")
      event.target.closest("li").classList.add("active")
    }

    dropdown.classList.remove("active")
    dropdownTrigger.classList.remove("active")
    dropdownTrigger.classList.add("filled")
    flag.src = `${this.baseFlagSrc}${country_code}.svg`
    code.innerText = dial_code
    data.codeValue = dial_code
    this.updatePhoneNumber(data)
    this.checkIsInputsValid()

    input.dispatchEvent(changeEvent)
  }

  handleInput = (event, data) => {
    data.value = event.target.value
    event.target.value = event.target.value.replace(/[^0-9,]/g,'');
    this.updatePhoneNumber(data)
    this.checkIsInputsValid()
  }

  checkIsInputsValid = (data) => {
    /*
    this.isInputsValid = this.data.every(({ value, codeValue }) => {
      const test = /^\d{10,},?\d*$/;

      return test.test(value);
    })

    console.log(this.data);
     */
  }


  #init() {
    this.data.forEach(({ input, dropdownTrigger, dropdown, countriesList, dialCode, flag }, idx, data) => {
      data[idx].codeValue = dialCode.innerText

      dropdownTrigger.addEventListener("click", (event) => this.handleTriggerClick(event, dropdown, dropdownTrigger))
      countriesList.addEventListener("click", (event) =>
        this.handleDropdownItemClick(event, dropdown, dropdownTrigger, flag, dialCode, data[idx]),
      )
      input.addEventListener("input", (event) => this.handleInput(event, data[idx], dialCode))
    })
  }
}

export const setTelInputData = (inputElementPath, dialCode = "", dialCountry = "", telNumber = "") => {
  const inputTel = document.querySelector(inputElementPath)
  if (!inputTel) return
  const parentTel = inputTel.parentNode
  const dialCodeSection = parentTel.querySelector(".code-section")
  const telDropdown = parentTel.querySelector(".tel-input-dropdown")
  if (dialCode && dialCountry) {
    if (!dialCodeSection) return
    const dialCodeImg = dialCodeSection.querySelector(".flag-container")
    const dialCodeNumber = dialCodeSection.querySelector(".dial-code")
    dialCodeSection.classList.add("filled")
    if (dialCodeNumber) dialCodeNumber.innerHTML = dialCode
    if (dialCodeImg) dialCodeImg.src = `/assets/thames/default/svg/flags/${dialCountry}.svg`

    if (!telDropdown) return
    const dialCodeOption = telDropdown.querySelector(
      `.tel-input-dropdown__list-item[data-country_code='${dialCountry}']`,
    )
    if (dialCodeOption) dialCodeOption.classList.add("active")
  }

  if (telNumber) inputTel.value = telNumber

  const eventInput = new Event("input")
  inputTel.dispatchEvent(eventInput)
}

// setTelInputData("#input_id", 'dial_code', 'code_country', 'tel_number') // description
// setTelInputData("#company_phone_number", '+1', 'ca', '7111111117') // example for using


export const getTelInputData = (inputElementPath) => {
  const inputTel = document.querySelector(inputElementPath)
  if (!inputTel) return {}
  const parentTel = inputTel.parentNode
  const dialCodeOption = parentTel.querySelector('.tel-input-dropdown__list-item.active,.tel-input-dropdown__list-item.prefer')

  let codeValue = ''
  let countryValue = ''
  let numberValue = ''

  if (dialCodeOption) {
    if (dialCodeOption.hasAttribute("data-dial_code")) codeValue = dialCodeOption.getAttribute("data-dial_code")
    if (dialCodeOption.hasAttribute("data-country_code")) countryValue = dialCodeOption.getAttribute("data-country_code")
  }
  numberValue = inputTel.value

  return {
    codeValue,
    countryValue,
    numberValue
  }
}
// getTelInputData("#tel_country_2") // example for using


