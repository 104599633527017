import TelInput from "../../../../components/tel-input/tel-input"
import { Toast } from "../../../../components/toast"
import { ExtendedFormValidation } from "../../form-validation/extended-form-validation"
import { updateFormState } from "../../form-validation/helpers/_state-actions"
import UserSearchHandler from "../../handle-user-search"
import { onSearchItemClick } from "../handle-search-item-click"

export class GuestUsersFormsHandler extends ExtendedFormValidation {
  constructor(form) {
    super(form)

    this.form = form
    this.requiredInputs = this.form.querySelectorAll(".js-required-field input")
    this.submitButton = this.form.querySelector(".system-settings-form__submit")
    this.formsMainWrapper = this.form.closest(".system-settings-form__wrap")
    this.searchFieldInput = this.form.querySelector(".guest-users-search__mask")
    this.formWrapper = this.form.querySelector(".system-settings-form__group-wrap")
    this.formGroupClone = this.form.querySelector(".system-settings-form__group").outerHTML
    this.additionalInviteBtn = this.form.querySelector(".system-settings-guest-users-step-form__additional-invite-btn")

    this.init()
  }

  init = () => {
    if (this.searchFieldInput) {
      new UserSearchHandler("#personal_guest_inviter", "full-width", onSearchItemClick, this)
    }

    this.additionalInviteBtn.addEventListener("click", () => this.addInviteFormGroup())
    this.validateFormOnInput()
    this.formsMainWrapper.addEventListener("click", (event) => this.deleteInviteFormGroup(event))

    this.form.addEventListener("submit", this.handleGuestFormSubmit)
  }

  /* ---> handle submit <--- */
  handleGuestFormSubmit = () => {
    const systemSettings = document.querySelector(".js-system-settings")
    const step = this.form.closest(".js-system-settings__content")
    const { toast } = this.form.dataset

    systemSettings.classList.remove("active")
    step.classList.remove("active")
    setTimeout(() => {
      new Toast(
        {
          position: ".header .header__content",
          text: toast,
          type: "success",
        },
        300,
      )
    })
  }

  /* ---> handle all forms state <--- */
  handleFormState = () => {
    let isAllFieldsFilled = true

    this.requiredInputs.forEach((input) => {
      const prevEl = input.previousElementSibling
      if (prevEl && !prevEl.classList.contains("filled")) {
        isAllFieldsFilled = false
        return
      }

      if (input.value.trim() === "") {
        isAllFieldsFilled = false
        return
      }
    })

    this.submitButton.disabled = !isAllFieldsFilled
  }

  /* ---> handle Personal Guests form state <--- */
  // handlePersonalGuestsFormState = (searchInput) => {
  //   const inputValue = searchInput.value.trim()
  //   const isInputEmpty = inputValue === ""
  //   let isAllFieldsFilled = true

  //   this.form.classList.toggle("_disabled", isInputEmpty)

  //   this.requiredInputs.forEach((input) => {
  //     if (input.value.trim() === "") {
  //       isAllFieldsFilled = false
  //       return
  //     }
  //   })

  //   this.submitButton.disabled = isInputEmpty || !isAllFieldsFilled
  // }

  /* ---> add Additional Invite form group <--- */
  addInviteFormGroup = () => {
    const parser = new DOMParser()
    const formToInsert = parser.parseFromString(this.formGroupClone, "text/html").body.firstChild
    this.formWrapper.appendChild(formToInsert)

    this.changeFieldsAttributes(formToInsert)
    const telInputs = formToInsert.querySelectorAll('input[type="tel"]')
    telInputs && new TelInput(telInputs)
    this.revalidateForm()
    this.handleButtonsState()
  }

  /* ---> delete Additional Invite form group <--- */
  deleteInviteFormGroup = (event) => {
    const deleteBtn = event.target.closest(".js-form-group-delete-btn")

    if (deleteBtn) {
      const formGroup = deleteBtn.closest(".system-settings-form__group")

      formGroup.remove()
      this.removeAddedFormFields(formGroup)
      this.revalidateForm()
      this.handleButtonsState()
    }
  }

  /* ---> remove added form fields for submitted data <--- */
  removeAddedFormFields = (formGroup) => {
    const inputs = formGroup.querySelectorAll(".js-system-settings-input")
    const dropdowns = formGroup.querySelectorAll(".js-system-settings__dropdown-wrap")
    updateFormState(this, inputs, dropdowns)
  }

  /* ---> disable/enable buttons and change their value if needed <--- */
  handleButtonsState = () => {
    const formWrappers = this.form.querySelectorAll(".system-settings-form__group")
    const deleteGroupButtons = this.form.querySelectorAll(".js-form-group-delete-btn")
    const isFormGroupsMaxLength = formWrappers.length > 9

    this.additionalInviteBtn.disabled = isFormGroupsMaxLength
    this.submitButton.innerHTML = `Invite ${formWrappers.length} ${formWrappers.length > 1 ? "users" : "user"}`
    deleteGroupButtons.forEach((button) => (button.disabled = formWrappers.length === 1))
  }

  /* ---> handle Form State while input data <--- */
  validateFormOnInput = () => {
    this.requiredInputs.forEach((input) => {
      input.addEventListener("input", this.handleFormState)
    })
  }

  /* ---> revalidate Form <--- */
  revalidateForm = () => {
    this.requiredInputs = this.form.querySelectorAll(".js-required-field input")
    this.validateFormOnInput()
    this.handleFormState()
  }
}
