import FormValidation from "./form-validation"
import {
  handleDisabledDropdown,
  handleDropdownListClick,
  handleDropdownTriggerClick,
  handleOfficeDropdownInputValueChange,
  handleSearch,
  initFormState,
  initValidationFieldsState,
  isFormValid,
  removeError,
  setDropdownsData,
  setInputData,
  updateFormState,
  updateValidationState,
} from "./helpers/helpers"

export class ExtendedFormValidation extends FormValidation {
  constructor(form) {
    super(form)

    this.form = form
    // form copy
    this.formGroupInnerHTML = this.form.querySelectorAll(".quick-invite-form-group-initial")[1]?.outerHTML
    // buttons
    this.deleteBtns = this.form.querySelectorAll(".js-system-quick-invite-delete")
    this.additionalUserBtn = this.form.querySelector(".system-settings-quick-invite-step__additional-invite-btn")
    // validation node
    this.validationInfoNode = form.querySelector(".user-groups-add-users")
    // office dropdowns
    this.officeDropdown = form.querySelector(".office-dropdown")
    this.dropdownOfficesList = form.querySelector(".dropdown-office-list")
    this.selectedOfficesQty = form.querySelector(".dropdown-office-selected-offices span")
    // counter
    this.counter = 1

    this.#init()
  }

  #init() {
    const addInviteUsersFormBtn = this.form.querySelector(".system-settings-quick-invite-step__additional-invite-btn")

    if (addInviteUsersFormBtn) {
      addInviteUsersFormBtn.addEventListener("click", this.addInviteUserForm)

      // quick invite step dropdowns
      handleDisabledDropdown(
        "quick_invite_office_dropdown",
        "quick_invite_department_dropdown",
        this.enableQuickInviteDisabledInputs,
      )
      // new group name step dropdowns
      handleDisabledDropdown("new_group_office_dropdown", "new_group_department_dropdown")
      handleDisabledDropdown("new_group_edit_office_dropdown", "new_group_edit_department_dropdown")

      this.handleDeleteInviteUserForm()
    }

    if (this.officeDropdown) {
      this.updateSelectedOffices()
      this.handleOfficeDropdownVisibility()
      this.handleClickOnDeleteOfficeDropdownItem()
    }
  }

  // handle click on "Invite additional user" button
  addInviteUserForm = () => {
    const formContainer = this.form.querySelectorAll(".system-settings-form__group-wrap")[1]

    // form element to insert in DOM
    const parser = new DOMParser()
    const formToInsert = parser.parseFromString(this.formGroupInnerHTML, "text/html").body.firstChild
    formToInsert.classList.add("system-settings-quick-invite-step__additional-form")
    formContainer.appendChild(formToInsert)

    // check if inputs should be enabled
    const secondDropdown = this.form.querySelectorAll(".system-settings__dropdown-field")[1]
    const secondDropdownTrigger = secondDropdown.querySelector(".dropdown-trigger")
    if (secondDropdownTrigger.classList.contains("filled")) {
      this.enableQuickInviteDisabledInputs()
    }

    this.changeFieldsAttributes(formToInsert)
    updateFormState(this)
    initValidationFieldsState(this)
    updateValidationState(this)
    isFormValid(this)
    this.handleDeleteButtonVisibility()
    this.handleDeleteInviteUserForm()
  }

  changeFieldsAttributes = (formGroup) => {
    this.counter++

    const inputs = formGroup.querySelectorAll(".js-system-settings-input")
    const telDropdown = formGroup.querySelector(".tel-wrap")
    const dropdown = formGroup.querySelector(".js-system-settings__dropdown-wrap")

    if (telDropdown) {
      const telLabel = telDropdown.closest(".input-tel-field").querySelector("label")
      const telInput = telDropdown.querySelector(".system-settings-form__group-field__input")
      const telId = telInput.id
      const newTelId = `${telId}_${this.counter}`

      telLabel.setAttribute("for", newTelId)
      telInput.setAttribute("name", newTelId)
      telInput.setAttribute("id", newTelId)
    }

    if (dropdown) {
      const dropdownNewType = `${dropdown.dataset.type}_${this.counter}`
      dropdown.setAttribute("data-type", dropdownNewType)

      const { type, currency } = dropdown.dataset
      const dropDownLabel = dropdown.previousElementSibling.querySelector("label")
      const dropDownInput = dropdown.querySelector(".js-system-settings-dropdown-input")

      dropDownLabel.setAttribute("for", type)
      dropDownInput.setAttribute("name", type)
      dropDownInput.setAttribute("id", type)

      this.dropdowns = this.form.querySelectorAll(".js-system-settings__dropdown-wrap")
      setDropdownsData(this)
      const current = this.formState[type]
      current.trigger.addEventListener("click", () => handleDropdownTriggerClick(type, this))
      current.list.addEventListener("click", (event) => handleDropdownListClick(event, type, currency, null, this))

      current.searchInput && current.searchInput.addEventListener("input", (event) => handleSearch(event, type, this))
    }

    inputs.forEach((input) => {
      const inputId = input.id
      const inputLabel = input.closest(".system-settings-form__group-field").querySelector("label")

      inputLabel.setAttribute("for", `${inputId}_${this.counter}`)
      input.setAttribute("name", `${inputId}_${this.counter}`)
      input.setAttribute("id", `${inputId}_${this.counter}`)

      initFormState(input, this)
    })

    inputs.forEach((input) => {
      input.addEventListener("input", () => {
        setInputData(input, this)
        removeError(input)
        updateValidationState(this)
        isFormValid(this)
      })
    })
  }

  enableQuickInviteDisabledInputs = () => {
    this.inputs = this.form.querySelectorAll("input")

    if (!this.inputs) return

    this.inputs.forEach((input) => {
      const inputPrevSibling = input.previousElementSibling

      input.disabled = false

      if (inputPrevSibling?.tagName === "BUTTON") {
        inputPrevSibling.disabled = false
      }
    })
  }

  handleDeleteButtonVisibility = () => {
    this.deleteBtns = this.form.querySelectorAll(".js-system-quick-invite-delete")
    const formWrappers = this.form.querySelectorAll(".system-settings-form__group")
    const formsQty = formWrappers.length - 1

    this.deleteBtns.forEach((btn) => btn.classList[formsQty > 1 ? "add" : "remove"]("_visible"))

    this.additionalUserBtn.disabled = formsQty === 10
  }

  handleDeleteInviteUserForm = () => {
    this.deleteBtns.forEach((button) => {
      button.addEventListener("click", () => {
        const form = button.closest(".quick-invite-form-group-initial")
        const inputs = form.querySelectorAll(".js-system-settings-input")
        const dropdowns = form.querySelectorAll(".js-system-settings__dropdown-wrap")
        form.remove()
        this.handleDeleteButtonVisibility()

        updateFormState(this, inputs, dropdowns)
        initValidationFieldsState(this)
        updateValidationState(this)
        isFormValid(this)
      })
    })
  }

  // handle DELETE office dropdown item
  handleClickOnDeleteOfficeDropdownItem = () => {
    const optionsList = this.officeDropdown.querySelector(".input-dropdown__list")
    const input = this.officeDropdown.querySelector(".js-system-settings-dropdown-input")

    this.dropdownOfficesList.addEventListener("click", (event) => {
      const currentLi = event.target

      if (currentLi.closest(".dropdown-office-list__remove-btn")) {
        const item = currentLi.closest(".dropdown-office-list__item")
        item.remove()
        this.updateSelectedOffices()

        let dataValue
        let correspondingOption
        if (item.hasAttribute("data-id")) {
          dataValue = item.getAttribute("data-id")
          correspondingOption = optionsList.querySelector(`[data-id="${dataValue}"]`)
        } else {
          dataValue = item.getAttribute("data-value")
          correspondingOption = optionsList.querySelector(`[data-value="${dataValue}"]`)
        }

        handleOfficeDropdownInputValueChange(input, dataValue)

        if (correspondingOption) {
          correspondingOption.classList.remove("selected")
        }
      }
    })
  }

  // update SELECTED offices
  updateSelectedOffices = () => {
    const selectedOfficesQty = this.dropdownOfficesList.querySelectorAll("li").length
    const isSingleOffice = selectedOfficesQty == 1
    const isListEmpty = selectedOfficesQty < 1

    this.dropdownOfficesList.classList[isListEmpty ? "remove" : "add"]("_visible")
    this.selectedOfficesQty.innerHTML = isSingleOffice
      ? `${selectedOfficesQty} office`
      : `${selectedOfficesQty} offices`

    if (isListEmpty) {
      const input = this.officeDropdown.querySelector(".js-system-settings-dropdown-input")
      input.value = ""
    }
  }

  // handle dropdown VISIBILITY
  handleOfficeDropdownVisibility = () => {
    const button = this.form.querySelector(".js-office-related button")
    const officeDropdown = this.form.querySelector(".office-dropdown-wrapper")
    const dropdownToValidate = officeDropdown.querySelector(".office-dropdown")

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "subtree") {
          const currentText = button.textContent.trim()

          if (currentText === "Office-specific bookings") {
            officeDropdown.classList.add("_visible")
            dropdownToValidate.setAttribute("data-validation", "true")
          } else {
            officeDropdown.classList.remove("_visible")
            dropdownToValidate.removeAttribute("data-validation")
            officeDropdown.classList.remove("invalid")
            this.dropdownOfficesList.innerHTML = ""
            this.updateSelectedOffices()

            const items = this.form.querySelectorAll(".js-office-dropdown-list-item")
            items.forEach((item) => {
              item.classList.remove("selected")
            })
          }

          this.updateValidationFormState()
        }
      })
    })

    observer.observe(button, { childList: true, subtree: true })
  }

  updateValidationFormState = () => {
    updateFormState(this)
    initValidationFieldsState(this)
    // updateValidationState(this)
    isFormValid(this)
  }
}
