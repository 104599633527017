export class SearchFilter {
  constructor(container, isItemClickHandler = true) {
    this.isSelect = container.classList.contains("js-select")
    this.isItemClickHandler = this.isSelect ? false : isItemClickHandler
    this.input = container.querySelector(".js-search-filter-input")
    this.resultsList = container.querySelector(".js-search-filter-list")
    this.clearBtn = container.querySelector(".js-search-filter-clear")
    this.resultsItems = this.resultsList.querySelectorAll(".js-search-filter-item")
    this.hiddenClass = "_hidden"
    this.searchUserButton = document.getElementById('user-list-search')
    this.searchTripsButton = document.getElementById('trips-list-search')

    this.init()
  }

  init = () => {
    this.input.addEventListener("input", this.filterResults)
    this.isItemClickHandler && this.resultsList.addEventListener("click", this.handleItemClick)
    this.clearBtn && this.clearBtn.addEventListener("click", this.clearInput)
    
    if (this.searchUserButton) {
      this.searchUserButton.addEventListener('click', () => {
        this.UserBackEndSearch()
        });
    }

    if (this.searchTripsButton) {
      this.searchTripsButton.addEventListener('click', () => {
        this.TripsBackEndSearch();
      });
    }

  }

  filterResults = () => {
    const inputValue = this.input.value.toLowerCase();
    let hasMatchingItems = false;

    console.log(inputValue);

    // Check for a minimum number of characters (2)
    if (inputValue.length >= 2 && this.searchUserButton) {
        // Load user data based on the input value
        this.loadUserData(inputValue);
        hasMatchingItems = true;
    }

    if (inputValue.length >= 2 && this.searchTripsButton) {
      this.loadTripsData(inputValue);
      hasMatchingItems = true;
    }

    // Toggle visibility of the results list based on the input value and matching items
    this.resultsList.classList.toggle(this.hiddenClass, !inputValue || !hasMatchingItems);

    // Add click event listener to results list if handler exists
    if (this.isItemClickHandler) {
        this.resultsList.addEventListener("click", this.handleItemClick);
    }
  }

  handleItemClick = (event) => {
    const clickedItem = event.target.closest(".js-search-filter-item")
    if (!clickedItem) return

    const itemValue = clickedItem.querySelector(".js-search-filter-value").textContent.trim()
    this.input.value = itemValue

    if (!this.isSelect) {
      this.resultsList.classList.add(this.hiddenClass)
    }
  }

  clearInput = () => {
    this.input.value = "";
    // Check the URL
    const currentURL = window.location.href;
    const searchUserPattern = /\/user-management\/search\?searchValue=/;
    const searchTripPattern = /\/trips\/search\?searchValue=/;

    if (searchUserPattern.test(currentURL) && this.searchUserButton ) {
        // Redirect to the plain URL without search
        window.location.href = `${SITEURL}user-management/list`;
    } 
    else if(searchTripPattern.test(currentURL) && this.searchTripsButton ){
        window.location.href = `${SITEURL}trips/list`;
    } 
    else {
        // Perform filtering results without search
        this.filterResults();
    }
  }

  loadUserData = (searchValue) => {

    fetch(`${SITEURL}account/get-user-serch?searchValue=${searchValue}`)
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        // Loop through each key-value pair in the response
        console.log(data);

        this.addUserItemsToList(data.data.users);

        
      })
      .catch(error => console.error('Error:', error)); // Log any errors to the console

  }


  createUserItem = (user) => `
    <button type="button">
      <div class="quick-invite-tabs-item quick-invite-tabs-item--alt ">
        <div class="quick-invite-tabs-item-user">
          <div class="quick-invite-tabs-item-user__initials">
            <img class="user-avatar" src="${SITEURL}user/profile-avatar/${user.id}?width=48&height=48">
          </div>
          <div>
            <div>
              <b class="js-search-filter-value"> ${user.first_name} ${user.last_name}</b>
              <span class="quick-invite-tabs-item-user__rank">${user.group_name}</span>
            </div>

            <div class="quick-invite-tabs-item-user__email">
              ${user.email}
            </div>
          </div>
        </div>

        <div class="quick-invite-tabs-item-company-info">
          <div>
            ${user.department}
          </div>
          <div class="quick-invite-tabs-item-company-info__company">
            <img src="/assets/thames/default/svg/flags/us.svg" alt="img" />
            ${user.office_title}
          </div>
        </div>
      </div>
    </button>
                                
  `;

  addUserItemsToList = (users) => {
    let list = document.querySelector('.user-management-search__results-list');
    // Clear
    list.innerHTML = '';

    users.forEach(user => {
      console.log(user);
      let listItem = document.createElement('li');
      listItem.setAttribute('data-id', user.id);
      listItem.setAttribute('class', 'user-management-search__results-item js-search-filter-item'); // 
      listItem.innerHTML = this.createUserItem(user);
      list.appendChild(listItem);
    });

  }

  UserBackEndSearch = () => {
    // Get the value from the input field
    const searchInput = document.getElementById('user_search_input').value;
    // Form the URL for the GET request
    const searchURL = `${SITEURL}user-management/search?searchValue=${encodeURIComponent(searchInput)}`;
    // Redirect to the new URL
    window.location.href = searchURL;
 }


  TripsBackEndSearch = () => {
    // Get the value from the input field
    const searchInput = document.getElementById('trips_search').value;
    // Form the URL for the GET request
    const searchURL = `${SITEURL}trips/search?searchValue=${encodeURIComponent(searchInput)}`;
    // Redirect to the new URL
    window.location.href = searchURL;
  }



 loadTripsData = (searchValue) => {
    fetch(`${SITEURL}trips/get-trips-serch?searchValue=${searchValue}`)
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        // Loop through each key-value pair in the response
        console.log(data);

        this.addTripItemsToList(data.data.trips);
      })
      .catch(error => console.error('Error:', error)); // Log any errors to the console
  }

  createTripItem = (trip) => {
    let iconType = trip.result_type === 'trip' ? 'suitcase' : 'marker-pin-02';
    let itemType = trip.result_type === 'trip' ? 'Trip' : 'City/Area';
    // TODO Add Area

    return `
        <li class="trips-search__results-item js-search-filter-item">
            <button class="trips-search__results-button" type="button">
                <div class="trips-search__results-col">
                    <svg class="icon icon-${iconType} ">
                        <use href="/assets/thames/default/icon/icons/icons.svg#${iconType}" />
                    </svg>
                    <span class="js-search-filter-value">${trip.result_type === 'trip' ? trip.trip_name : trip.hotel_name}</span>
                </div>
                <span>${itemType}</span>
            </button>
        </li>
    `;
  };

  addTripItemsToList = (trips) => {
    let list = document.querySelector('.trips-search__results-list');
    // Clear
    list.innerHTML = '';

    trips.forEach(trip => {
        console.log(trip);
        let listItem = document.createElement('li');
        listItem.setAttribute('data-id', trip.id);
        listItem.setAttribute('class', 'trips-search__results-item js-search-filter-item');
        listItem.innerHTML = this.createTripItem(trip);
        list.appendChild(listItem);
    });
  }

}
