export class UserDataForQI {
  createUserItem = (user, SITEURL) => `
      <div class="quick-invite-tabs-item-user">
        <div class="quick-invite-tabs-item-user__initials">
          <img class="user-avatar" src="${SITEURL}user/profile-avatar/${user.id}?width=30&height=30">
        </div>
        <div>
          <div>
            <b>${user.first_name} ${user.last_name}</b>
            <span class="quick-invite-tabs-item-user__rank">${user.group_name}</span>
          </div>
          <div class="quick-invite-tabs-item-user__email">${user.email}</div>
        </div>
      </div>
      <div class="quick-invite-tabs-item-company-info">
        <div>${user.department}</div>
        <div class="quick-invite-tabs-item-company-info__company">
          <img src="/assets/thames/default/svg/flags/us.svg" alt="img" />
          ${user.office_title}
        </div>
      </div>
  `;

  addUserItemsToList = (users, listSelector, SITEURL) => {
    const list = document.querySelector(listSelector);
    // Clear
    list.innerHTML = '';
    users.forEach(user => {
      let listItem = document.createElement('div');
      listItem.className = 'quick-invite-tabs-item';
      listItem.setAttribute('data-id', user.id);
      listItem.innerHTML = this.createUserItem(user, SITEURL);
      list.appendChild(listItem);
    });
  }

  loadUserDataForQI = (officeID = 0) => {
    // List quick-invite-users-tab-registered
    // cnt = quick-invite-registered-users

    // quick-invite-invited-users
    // quick-invite-users-tab-invited
    fetch(`${SITEURL}account/get-user-list-for-office/${officeID}`)
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
        // Loop through each key-value pair in the response
        console.log(data);

        this.addUserItemsToList(data.data.UsersListRegisterd, `#quick-invite-users-tab-registered`, SITEURL);
        document.querySelector(`#quick-invite-registered-users`).textContent = data.data.UsersListRegisterd.length;

        this.addUserItemsToList(data.data.UsersListInvited, `#quick-invite-users-tab-invited`, SITEURL);
        document.querySelector(`#quick-invite-invited-users`).textContent = data.data.UsersListInvited.length;
  
      })
      .catch(error => console.error('Error:', error)); // Log any errors to the console

  }

}
