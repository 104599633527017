function formSelectData(selectsNodeList) {
  const data = {}
  selectsNodeList.forEach(node => {
    const { title } = node.dataset
    const input = node.querySelector('.js-select-input')
    data[title] = {
      input,
      value: input.value
    }
  })
  return data
}

function formInputData(inputField) {
  const data = {}
  const input = inputField.querySelector('.js-field-input')

  data[input.name] = {
    field: inputField,
    input,
    value: input.value
  }
  return data
}

function formTelInputData(telInput) {
  const data = {}
  const {name} = telInput
  const field = telInput.closest('.tel-wrap')
  data[name] = {
    input: telInput,
    field,
    dialCode: field.querySelector('.dial-code').innerText,
    value: telInput.value,
    countryList: field.querySelector('.tel-input-dropdown__list'),
    flagContainer: field.querySelector('.code-section'),
  }

  return data
}

function formEntireFormData(selectsNodeList, inputField, telInputs) {

  return {
    selects: {
      ...formSelectData(selectsNodeList)
    },
    inputs: {
      ...formInputData(inputField)
    },
    telInputs: {
      ...Array.from(telInputs).reduce((obj, input) => {
        obj = {...obj, ...formTelInputData(input)}
        return obj
      }, {})
    }
  }
}

export { formSelectData, formInputData, formTelInputData, formEntireFormData }