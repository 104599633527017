export class SystemSettingsDynamicText {
  constructor(dynamicInput) {
    this.dynamicTextInput = dynamicInput
    if(!this.dynamicTextInput) return
    this.dynamicTextParent = this.dynamicTextInput.closest('.system-settings__content-main')
    if(!this.dynamicTextParent) return
    this.textToChange = this.dynamicTextParent.querySelector(".js-system-settings-dynamic-text")    

    this.init()
  }

  changeDynamicText = (changeValue) => {
    if(!this.textToChange) return
    if(changeValue.length < 1){
      if(this.textToChange.hasAttribute("data-default")) this.textToChange.innerHTML = this.textToChange.getAttribute("data-default")
    }else this.textToChange.innerHTML = changeValue
  }

  init() {
    this.changeDynamicText(this.dynamicTextInput.value)
    this.dynamicTextInput.addEventListener("input", (event) => {this.changeDynamicText(event.target.value)})
  }
}