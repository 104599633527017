import { Toast } from "../../../components/toast"
import { scrollbarLock, scrollbarUnlock } from "../../../utils/scrollbar-locker"
import { IntegrationForms } from "./../../system-settings/integration-forms"
import { setTelInputData } from  "../../../components/tel-input/tel-input"
import { handleSearch } from "./../../system-settings/form-validation/helpers/_handlers"
import { setInputValue } from "../../../modules/setInputValue"

export class UserSettings {
  constructor(wrap) {
    this.wrapper = wrap
    this.steps = [...wrap.querySelectorAll(".js-user-settings-step")]
    this.btns = wrap.querySelectorAll(".js-user-setting-btn")
    this.openTriggers = document.querySelectorAll(".js-user-settings-open-trigger")
    this.isOpen = this.setOpen()
    this.IntegrationForms = new IntegrationForms();
    this.#init()
  }

  setOpen = () => {
    this.isOpen = this.wrapper.classList.contains("active")
  }

  handleBtnCLick = (event) => {
    const { goto, toastMessage, toastStatus } = event.currentTarget.dataset
    if (goto === "close") {
      this.steps.forEach((step) => step.classList.remove("active"))
      this.wrapper.classList.remove("active")
      scrollbarUnlock()
      if (toastMessage && toastStatus) {
        setTimeout(() => {
          new Toast({ position: ".header__content", text: toastMessage, type: toastStatus })
        }, 300)
      }
      this.setOpen()
    } else {
      this.steps.forEach((step) => step.classList[step.dataset.step === goto ? "add" : "remove"]("active"))
    }

    const elem = event.target.closest('button.load-data-btn');
    console.log('elem', elem);
    this.handleAddRequest(elem);
  }

  handleOpenTriggerClick = (event) => {
    if (event.target.closest(".hotels-catalog-sidebar-section__content-checkbox")) {
      return
    }
    if (this.isOpen) return
    this.wrapper.classList.add("active")
    scrollbarLock()
    this.steps.forEach((step) => {
      if (step.dataset.step === "preview") {
        step.classList.add("active")
      }
    })
    this.setOpen()

    //console.log(event);
    const elem = event.target.closest('div.load-data-btn');
    console.log('elem', elem);
    this.handleAddRequest(elem);
    
  }

  handleBackdropClick = (event) => {
    const isBackdrop = event.target === this.wrapper
    if (isBackdrop) {
      this.wrapper.classList.remove("active")
      this.steps.forEach((step) => step.classList.remove("active"))
      scrollbarUnlock()
    }
    this.setOpen()
  }

  #init() {
    this.btns.forEach((btn) => btn.addEventListener("click", this.handleBtnCLick))
    this.openTriggers.forEach((trigger) => trigger.addEventListener("click", this.handleOpenTriggerClick))
    window.addEventListener("click", this.handleBackdropClick)
  }


  handleAddRequest = (element) => {
    if (element.hasAttribute("data-url")) {

      const postUrl = element.getAttribute("data-url"); // Get the url
      console.log('postUrl', postUrl);
      const itemId = element.getAttribute("data-id"); // Get the unique ID of the item
      console.log('itemId', itemId);
      const dataFor = element.getAttribute("data-for"); 
      console.log('dataFor', dataFor);
      
      const formData = new FormData();
      formData.append('id', itemId);

      const csrfTokenInput = document.querySelector('input[name="csrf_token"]');
      const csrfTokenMeta = document.querySelector('meta[name="csrf_token"]');
      const csrfTokenValue = csrfTokenInput ? csrfTokenInput.value : (csrfTokenMeta ? csrfTokenMeta.content : '');

      const self = this;
          fetch(postUrl, {
            method: "POST",
            headers: {
              'X-CSRF-TOKEN': csrfTokenValue
            },
            body: formData
          })
          .then(response => response.json()) // Parse the JSON response
          .then(data => {
              // Loop through each key-value pair in the response
              console.log(data);

              if(data.success==false){
                if(data.msg){
                  this.errorMsg = data.msg;
                } else {
                  this.errorMsg = 'Request error'
                }
                new Toast({ position: ".header__content", text: this.errorMsg, type: 'error' })
                console.log(this.errorMsg);
              }
              if(dataFor=="user-personal-information"){
                if(data.success){ 
                  this.IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user);
                  this.IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.user_single);
                  this.IntegrationForms.insertUserPreviewData('.user-settings-preview-step', data.travel_settings);
                  const preview_user_avatar = document.querySelector('.user-settings-preview-step #preview_user_avatar');
                  preview_user_avatar.setAttribute('src', '/user/profile-avatar/'+data.user.id+'?width=52&height=52');
                  this.IntegrationForms.insertPreviewDataId('.user-settings-preview-step', data.user.id)
                  this.IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_booking_reminders', data.user.email_booking_reminders)
                  this.IntegrationForms.insertPreviewSwitcherData('.user-settings-preview-step', 'email_system_updates', data.user.email_system_updates)
                }
              }

              if(dataFor=="edit-user-info"){
                if(data.success){
                  const edit_user_id = document.querySelector(`#edit_user_id`);
                  edit_user_id.value = data.user.id;
                  edit_user_id.setAttribute('value', data.user.id);

                  const form = document.querySelector(`.user-settings-edit-personal-info-step[data-step='edit-personal-info'] form`);
                  // Clear all form
                  this.IntegrationForms.clearForm(form);
                  // Insert data to form
                  this.IntegrationForms.insertFormInputsData(data.user, `.user-settings-edit-personal-info-step[data-step='edit-personal-info']`);

                  setTelInputData("#user_edit_phone_number", data.user.phone_number_code, data.user.phone_number_country, data.user.phone_number)
                  setTelInputData("#user_edit_mobile_number", data.user.mobile_number_code, data.user.mobile_number_country, data.user.mobile_number)

                  const preview_user_avatar = document.querySelector('#edit_user_avatar_pic');
                  preview_user_avatar.setAttribute('src', '/user/profile-avatar/'+data.user.id+'?width=96&height=96');

                  let offices_list = document.querySelector(`#user_edit_offices`); 
                  
                  // Add offices
                  data.offices.forEach(office => {
                    // Створення нового елементу li
                    let listItem = document.createElement('li');
                    listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                    listItem.setAttribute('data-value', office.office_title);
                    listItem.setAttribute('data-id', office.id);
                  
                    // Додавання вмісту до li
                    listItem.innerHTML = `
                                  <span class="input-dropdown__list-item__value js-list-item__value-text">
                                  ${office.office_title}
                                  </span>
                                  <span class="checked">
                                    <svg class="icon icon-checkbox ">
                                      <use href="/assets/thames/default/icon/icons/icons.svg#checkbox" />
                                    </svg>
                                  </span>
                    `;
                  
                    // Додавання створеного елементу до списку
                    offices_list.appendChild(listItem);
                  });
  
                  // RE-init search in Office dropdown
                  let searchInput = offices_list.closest('div').querySelector(".js-system-settings__search-input")
                  let officeDropdown = offices_list.closest('div.js-system-settings__dropdown-wrap')
                  let { type, currency, rating } = officeDropdown.dataset
                  this.formState = {}
                  this.formState[type] = {};
                  this.formState[type].items = offices_list.querySelectorAll(".js-system-settings__dropdown-list-item")
                  searchInput.addEventListener("input", (event) => handleSearch(event, type, this))

                  if(data.user.office_id>0){
                    const to_set = offices_list.querySelector(`.js-system-settings__dropdown-list-item[data-id="${data.user.office_id}"]`);
                    console.log('to_set', to_set);
                    to_set.click();
                  }
                  
                  let departments_list = document.querySelector(`#user_edit_departament`);
                  data.departments.forEach(department => {
                    // Створення нового елементу li
                    let listItem = document.createElement('li');
                    listItem.className = 'input-dropdown__list-item js-system-settings__dropdown-list-item';
                    listItem.setAttribute('data-value', department.name);
                    listItem.setAttribute('data-id', department.id);
                  
                    // Додавання вмісту до li
                    listItem.innerHTML = `
                                  <span class="input-dropdown__list-item__value">
                                    ${department.name}
                                  </span>
                                  <span class="input-dropdown__list-item__value js-list-item__value-text">
                                  ${department.description}
                                  </span>
                    `;
                  
                    // Додавання створеного елементу до списку
                    departments_list.appendChild(listItem);
                 });

                 if(data.user.department_id>0){
                  const dep_to_set = departments_list.querySelector(`.js-system-settings__dropdown-list-item[data-id="${data.user.department_id}"]`);
                  console.log('dep_to_set', dep_to_set);
                  dep_to_set.click();
                 }

              }
            }

            
            if(dataFor=="edit-user-preferences"){ 

              const edit_user_id = document.querySelector(`#edit_user_id`);
              edit_user_id.value = data.user.id;
              edit_user_id.setAttribute('value', data.user.id);

              
              setInputValue("#preferences_periodic_password_changes_lenght", false, data.user.require_periodic_password_changes) 

              setInputValue("#preferences_require_periodic_password_changes_status", false, data.user.require_periodic_password_changes_status) 

              setInputValue("#preferences_email_booking_reminders", false, data.user.email_booking_reminders) // checbox or radio to true value 
              setInputValue("#edit_preferences_system_related_updates", false, data.user.email_system_updates) // checbox or radio to true value 
              
            }
              
          })
          .catch(error => console.error('Error:', error)); // Log any errors to the console
     }
  }
}
