export function adjustElementPositionInView() {
  const hoverTriggers = document.querySelectorAll(".js-adjust-position-trigger")
  if (!hoverTriggers.length) return

  hoverTriggers.forEach((trigger) => {
    const elementToAdjust = trigger.nextElementSibling
    if (!elementToAdjust) console.warn("There is no element for adjusting its position", trigger)

    trigger.addEventListener("mouseover", () => elementInViewport(elementToAdjust))
  })

  function elementInViewport(el) {
    const bounding = el.getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    const isOverflowingBottom = bounding.bottom > viewportHeight
    const isOverflowingTop = bounding.top < 0

    if (isOverflowingBottom) {
      el.style.top = "unset"
      el.style.bottom = "calc(100% + 10px)"
    } else if (isOverflowingTop) {
      el.style.bottom = "unset"
      el.style.top = "calc(100% + 10px)"
    } else {
      return
    }
  }
}
