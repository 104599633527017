export function buildContent(itemData, assetsPath) {
  const content = document.createElement('div')

  const ratingFilledElem = `<svg class="icon icon-star filled"><use href="${assetsPath}icon/icons/icons.svg#star"></use></svg>`
  const ratingUnfilledElem = `<svg class="icon icon-star"><use href="${assetsPath}icon/icons/icons.svg#star"></use></svg>`
  const ratingLine = ratingFilledElem.repeat(parseInt(itemData.rating_star)) + ratingUnfilledElem.repeat(5 - parseInt(itemData.rating_star))

  const amenitiesLine = itemData.amenities.slice(0, 2).map(value => `
    <div class="map-info-window__body-amenities__list-item">
      <svg class="icon icon-checkbox">
        <use href="${assetsPath}icon/icons/icons.svg#checkbox"></use>
      </svg>
      ${value}
    </div>
  `).join('');

  const out_of_policy_block = `
    <div class="map-info-window__body-footer__out-of-policy">
      <svg class="icon icon-info ">
        <use href="${assetsPath}icon/icons/icons.svg#info"></use>
      </svg>
      <button type="button" data-modal-id="modal-hotel-travel-policy" class="map-info-window__body-footer__out-of-policy__text js-modal-open-trigger">
        Out of policy
      </button>
    </div>`


  content.classList.add('map-info-window') 

  const inner = `
    <div class="map-info-window__img-wrap">
      <div class="map-info-window__img-promo">Save <span
          class="js-map-info-window__img-promo__value">${itemData.selling_price_perc}</span>%</div>
      <picture class="map-info-window__image">
        <source srcset="${itemData.hotel_photo}" type="image/webp"
          class="map-info-window__img">
        <img src="${itemData.hotel_photo}" alt="${itemData.hotel_name}_img" class="map-info-window__img" width="230" height="230">
      </picture>
    </div>

    <div class="map-info-window__body-box">
      <div class="map-info-window__body">

        <div class="map-info-window__body-header-box">
          <div class="map-info-window__body-header">${itemData.hotel_name}</div>
          <!-- <div class="map-info-window__body-header-pick">Company pick</div> -->
        </div>

        <div class="map-info-window__body-subheader">
          <div class="map-info-window__body-subheader__stars">
            ${ratingLine}
          </div>
          <div class="map-info-window__body-subheader__title">${itemData.neighborhoods}</div>
        </div>

        <div class="map-info-window__body-amenities__list">
            ${amenitiesLine}
        </div>

        <div class="map-info-window__body-footer">
          <div class="map-info-window__body-footer__rating-box">
            <div class="map-info-window__body-footer__rating-num">${itemData.ean_rating}</div>
            <button type="button"
              class="map-info-window__body-footer__rating-description js-trigger-hotel-reviews_sidebar">${itemData.ean_rating_word}</button>
            <div class="map-info-window__body-footer__rating-reviews">${itemData.ean_number_of_reviews} reviews</div>
          </div>

          ${out_of_policy_block}
        </div>

      </div>
    </div>

    <div class="map-info-window__footer-box">

      <div class="map-info-window__footer">
        <div class="map-info-window__footer-close-button-block">
          <button class="map-info-window__footer-close-button js-map-info-window__close-button" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16196 0.894323C9.43767 1.17396 9.43767 1.62733 9.16196 1.90697L6.16665 4.9449L9.16196 7.98283C9.43767 8.26246 9.43767 8.71584 9.16196 8.99547C8.88625 9.2751 8.43923 9.2751 8.16352 8.99547L5.16821 5.95754L2.17291 8.99547C1.89719 9.2751 1.45018 9.2751 1.17447 8.99547C0.898759 8.71584 0.898759 8.26246 1.17447 7.98283L4.16978 4.9449L1.17447 1.90697C0.898759 1.62733 0.898759 1.17396 1.17447 0.894323C1.45018 0.614689 1.89719 0.614689 2.17291 0.894323L5.16821 3.93225L8.16352 0.894323C8.43923 0.614689 8.88625 0.614689 9.16196 0.894323Z"/>
            </svg>
          </button>
        </div>

        <div class="map-info-window__footer__prices">
          <div class="map-info-window__footer__prices-save">Save $${itemData.saving_price_user_currency}</div>
          <div class="map-info-window__footer__prices-total">
            <div class="full">$${itemData.public_price_user_currency}</div>
            <div class="with-discount">$${itemData.selling_price_user_currency}</div>
          </div>
          <div class="map-info-window__footer__prices-text">Nightly with taxes</div>
        </div>

        <a type="button" href="/travlio_frontend/build/hotels-catalog.html" class="map-info-window__footer__select-btn" aria-label="Select room" target="_blank">Select room</a>
      </div>
    </div>
  `

  content.innerHTML = inner;
  return content
}

export function buildPin(itemData) {
  const priceTag = document.createElement("div");

  priceTag.className = "hotels-catalog-main-map__pin";
  priceTag.textContent = '$' + itemData.selling_price_user_currency;

  return priceTag
}